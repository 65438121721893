var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return 1
    ? _c("div", { staticClass: "container teacher-login-container" }, [
        _vm._m(0),
        _c("div", { staticClass: "container container-max-width" }, [
          _c(
            "div",
            { staticClass: "row buttons enter-button mt-3 button-container" },
            _vm._l(_vm.languages, function (language) {
              return _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c(
                    "app-button-fit",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.selectLanguage(language)
                        },
                      },
                    },
                    [_vm._v(_vm._s(language.label))]
                  ),
                ],
                1
              )
            }),
            0
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login-box" }, [
      _c("div", { staticClass: "row logo-container" }, [
        _c("img", {
          staticClass: "logo",
          attrs: { src: require("@img/misc/logo.png") },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }