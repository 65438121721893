<template>
	<div class="container">
		<div
			v-for="(fontSizeName, i) in fontSizeNames"
			:class="'container-' + fontSizeName"
			class="container-app-font-size"
		>
			<div>
				{{ fontSizeName }} ({{ fontSizes[fontSizeName] }})
			</div>
			<div class="em-container-wrapper">
				<div :class="emFontSizes[fontSizeName]" class="em-container">
					{{ fontSizeName }}-em ({{ emFontSizes[fontSizeName] || 'default' }})
				</div>
			</div>
			<select v-model="emFontSizes[fontSizeName]" class="form-control">
				<option value="">Select one</option>
				<option value="font-size-xs">font-size-xs (0.6em)</option>
				<option value="font-size-sm">font-size-sm (0.8em)</option>
				<option value="font-size-md">font-size-md (1.0em)</option>
				<option value="font-size-lg">font-size-lg (1.2em)</option>
				<option value="font-size-xl">font-size-xl (1.4em)</option>
				<option value="font-size-xxl">font-size-xxl (2em)</option>
				<option value="font-size-xxxl">font-size-xxxl (2.5em)</option>
				<option value="font-size-xxxxl">font-size-xxxxl (4em)</option>
			</select>
		</div>
	</div>
</template>
<script>
export default {
	name: 'font-tester',
	data() {
		let fontSizes = {
			'app-font-size-counter-label': '12pt',
			'app-font-size-expression': '12pt',
			'app-font-size-button': '12pt',
			'app-font-size-win-point': '14pt',
			'app-font-size-point': '14pt',
			'app-font-size-base': '17pt',
			'app-font-size-h1': '17pt',
			'app-font-size-loading-indicator': '18pt',
			'app-font-size-error': '18pt',
			'app-font-size-counter-numbers': '26pt',
			'app-font-size-result-bar-footer': '29pt',
			'app-font-size-debug-panel-expand': '30pt',
			'app-font-size-result-percentage': '30pt',
			'app-font-size-emoji-xl': '150pt',

			'app-font-size-not-found-title': '10em',

			'app-font-size-thing-to-html-thing': '12px',
			'app-font-size-debug-panel-player-slots': '12px',
			'app-font-size-breadcrumb': '14px',
			'app-font-size-send-chat-message': '16px',
			'app-font-size-chat-message': '17px',
		}

		let fontSizeNames = []
		let emFontSizes = {}

		for (let fontSize in fontSizes) {
			fontSizeNames.push(fontSize)
			emFontSizes[fontSize] = ''
		}

		return {
			fontSizeNames: fontSizeNames,
			fontSizes: fontSizes,
			emFontSizes: emFontSizes,
		}
	},
}
</script>
<style lang="less" scoped>
@import "~@less/font-sizes.less";

.container-app-font-size {
	background: #333333;
	color: white;
	padding: 10px;
	margin: 10px;
}

.em-container-wrapper {
	font-size: @font-size-base !important;

	.em-container {
		&.font-size-xs {
			font-size: @font-size-xs !important;
		}

		&.font-size-sm {
			font-size: @font-size-sm !important;
		}

		&.font-size-md {
			font-size: @font-size-md !important;
		}

		&.font-size-lg {
			font-size: @font-size-lg !important;
		}

		&.font-size-xl {
			font-size: @font-size-xl !important;
		}

		&.font-size-xxl {
			font-size: @font-size-xxl !important;
		}

		&.font-size-xxxl {
			font-size: @font-size-xxxl !important;
		}

		&.font-size-xxxxl {
			font-size: @font-size-xxxxl !important;
		}
	}
}

.container-app-font-size-counter-label {
	font-size: @app-font-size-counter-label;
}

.container-app-font-size-expression {
	font-size: @app-font-size-expression;
}

.container-app-font-size-button {
	font-size: @app-font-size-button;
}

.container-app-font-size-win-point {
	font-size: @app-font-size-win-point;
}

.container-app-font-size-point {
	font-size: @app-font-size-point;
}

.container-app-font-size-base {
	font-size: @app-font-size-base;
}

.container-app-font-size-h1 {
	font-size: @app-font-size-h1;
}

.container-app-font-size-loading-indicator {
	font-size: @app-font-size-loading-indicator;
}

.container-app-font-size-error {
	font-size: @app-font-size-error;
}

.container-app-font-size-counter-numbers {
	font-size: @app-font-size-counter-numbers;
}

.container-app-font-size-result-bar-footer {
	font-size: @app-font-size-result-bar-footer;
}

.container-app-font-size-debug-panel-expand {
	font-size: @app-font-size-debug-panel-expand;
}

.container-app-font-size-result-percentage {
	font-size: @app-font-size-result-percentage;
}

.container-app-font-size-emoji-xl {
	font-size: @app-font-size-emoji-xl;
}

.container-app-font-size-not-found-title {
	font-size: @app-font-size-not-found-title;
}

.container-app-font-size-thing-to-html-thing {
	font-size: @app-font-size-thing-to-html-thing;
}

.container-app-font-size-debug-panel-player-slots {
	font-size: @app-font-size-debug-panel-player-slots;
}

.container-app-font-size-breadcrumb {
	font-size: @app-font-size-breadcrumb;
}

.container-app-font-size-send-chat-message {
	font-size: @app-font-size-send-chat-message;
}

.container-app-font-size-chat-message {
	font-size: @app-font-size-chat-message;
}
</style>
