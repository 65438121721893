var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "button",
        { staticClass: "btn btn-primary", on: { click: _vm.logout } },
        [_vm._v("Log Out")]
      ),
      _c("h1", [_vm._v("Overview")]),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("h3", { staticClass: "mb-2" }, [_vm._v("Themes")]),
          _c("add-button", {
            staticClass: "ml-3",
            on: {
              click: function ($event) {
                return _vm.addTheme()
              },
            },
          }),
        ],
        1
      ),
      _c("data-table", {
        scopedSlots: _vm._u([
          {
            key: "head",
            fn: function () {
              return [
                _c("tr", [
                  _c("th", [_vm._v("Title")]),
                  _c("th", { staticClass: "w-25" }, [_vm._v("Language")]),
                  _c("th", { staticClass: "w-25" }, [_vm._v("Actions")]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return _vm._l(_vm.themes, function (theme) {
                return _c("tr", { key: theme.id }, [
                  _c("td", [_vm._v(_vm._s(theme.title))]),
                  _c("td", [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: theme.language,
                            expression: "theme.language",
                          },
                        ],
                        staticClass: "form-control language-selector",
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                theme,
                                "language",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              return _vm.updateTheme(theme)
                            },
                          ],
                        },
                      },
                      _vm._l(_vm.languages, function (key, value) {
                        return _c(
                          "option",
                          { key: key, domProps: { value: key } },
                          [_vm._v(_vm._s(value))]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c(
                    "td",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-info",
                          attrs: { to: "/admin/theme/" + theme.id + "/edit" },
                        },
                        [_vm._v("Edit")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger",
                          on: {
                            click: function ($event) {
                              return _vm.deleteTheme(theme.id)
                            },
                          },
                        },
                        [_vm._v("Delete")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          on: {
                            click: function ($event) {
                              return _vm.copyTheme(theme.id)
                            },
                          },
                        },
                        [_vm._v("Copy")]
                      ),
                    ],
                    1
                  ),
                ])
              })
            },
            proxy: true,
          },
        ]),
      }),
      _c("br"),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("h3", { staticClass: "mb-2" }, [_vm._v("Media")]),
          _c("image-uploader", {
            staticClass: "ml-3",
            on: { upload: _vm.onImageUploaded },
          }),
        ],
        1
      ),
      _c("data-table", {
        scopedSlots: _vm._u([
          {
            key: "head",
            fn: function () {
              return [
                _c("tr", [
                  _c("th", [_vm._v("Name")]),
                  _c("th", { staticClass: "w-25" }, [_vm._v("Image")]),
                  _c("th", { staticClass: "w-25" }, [_vm._v("Actions")]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return _vm._l(_vm.images, function (image, n) {
                return _c("tr", { key: image.id }, [
                  _c("td", [_vm._v(_vm._s(image.name))]),
                  _c("td", [
                    _c("img", {
                      staticClass: "media-img",
                      attrs: { src: _vm.getImageSrc(image.path) },
                    }),
                  ]),
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        on: {
                          click: function ($event) {
                            return _vm.deleteImage(n)
                          },
                        },
                      },
                      [_vm._v("Delete")]
                    ),
                  ]),
                ])
              })
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }