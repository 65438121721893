<template>
    <div>
        <add-button v-on:click="openFileDialog()"/>
        <input ref="fileInput" accept=".png, .jpg, .jpeg, .gif" class="d-none" type="file"
               v-on:change="onFileSelected($event)"/>
    </div>
</template>

<script>
import FormRow from '@/components/form-row'
import AddButton from '@/components/add-button'

export default {
    name: 'image-uploader',
    components: {
        FormRow,
        AddButton,
    },
    data () {
        return {
            image: null,
        }
    },
    methods: {
        onFileSelected (event) {
            this.image = event.target.files[0]

            if (this.image != null) {
                this.$apiClient.postImage(this.image, (res) => {
                    event.target.value = ''
                    this.image = null
                    this.$emit('upload', res)
                }, () => {
                })
            }
        },
        openFileDialog () {
            this.$refs.fileInput.click()
        },
    },
}
</script>
