var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "case-progress col mb-3" }, [
    _c("div", { staticClass: "case-progress-point-line" }),
    _c(
      "div",
      { staticClass: "case-progress-point-wrapper row" },
      _vm._l(_vm.bubbleCount, function (n) {
        return _vm.choiceCount
          ? _c("div", { staticClass: "case-progress-point-container col" }, [
              _c(
                "div",
                {
                  staticClass: "case-progress-point",
                  class:
                    n <= _vm.highlightIndex ? "case-progress-point-active" : "",
                },
                [
                  n > _vm.highlightIndex
                    ? _c("span", [_vm._v(_vm._s(n))])
                    : _c("span", [_vm._v("XXX")]),
                ]
              ),
            ])
          : _vm._e()
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }