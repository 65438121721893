<template>
	<div class="container">
		<transition mode="out-in" name="fade">
			<div v-if="currentCasePage">
				<div class="case-progress">
					<div :style="{ width: caseProgressBarWidthPercent + '%' }" class="case-progress-filled"/>
				</div>
				<page-component-container
					:breadcrumbUrl="breadcrumbUrl"
					:currentPage="currentCasePage"
					:description="description"
					:leftComponent="leftComponent"
					:leftComponentOptions="leftComponentOptions"
					:rightComponent="rightComponent"
					:rightComponentOptions="rightComponentOptions"
					:title="title"
					:useTransitions="useTransitions"
				/>
				<!--<div class="timer-container" v-if="timerTimeTotal && showTimer">
				  <timer :time-remaining="timerTimeRemaining" :time-total="timerTimeTotal" />
				</div>-->
				<div v-if="timerTimeTotal && showTimer" class="timer-solid-container">
					<TimerSolid :time-remaining="timerTimeRemaining" :time-total="timerTimeTotal"></TimerSolid>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import GamePhase from '@/enum/GamePhase'
import PageComponentContainer from '@/components/page-components/page-component-container'
import Timer from '@/components/timer'
import TimerSolid from '@/components/timer-solid'
import {mapGetters} from 'vuex'
import * as MutationTypes from '@/store/mutation-types'
import {getTeacherUrl} from '@/utils/UrlUtil'
import PagePhase from '@/enum/PagePhase'
import {$eBus} from '@/event-bus'
import GameClientEvent from '@/enum/GameClientEvent'

export default {
	name: 'page-controller',
	components: {
		PageComponentContainer,
		Timer,
		TimerSolid,
	},
	props: {
		redirectToLobby: {
			type: Boolean,
			required: false,
			default: true,
		},
		useTransitions: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
	data() {
		return {
			showTimer: false,
			timerTimeTotal: 0,
			timerTimeRemaining: 0,

			caseProgressBarWidthPercent: 0,
		}
	},
	mounted() {
		if (this.redirectToLobby && (!this.currentCase || !this.$gameClient.isPhase([GamePhase.Pages]))) {
			console.log('Current case missing or $gameClient is not in the Pages phase - going back to lobby')

			// The game is not at the right point right now.. let's revert the user to the lobby screen
			this.$router.push(getTeacherUrl('lobby'))
		} else {
			this.$store.commit(MutationTypes.SET_NAVIGATION_GUARD_ENABLED, true)
		}

		$eBus.$on(GameClientEvent.RoomMessage, this.onRoomMessage)

		this.checkCurrentPageTimer()

		this.recalculateProgress()
	},
	destroyed() {
		$eBus.$off(GameClientEvent.RoomMessage, this.onRoomMessage)

		this.stopTimer()
	},
	watch: {
		currentCasePage() {
			this.checkCurrentPageTimer()

			this.recalculateProgress()
		},
		currentPagePhase(phase) {
			console.log('currentCaseComponent', this.leftComponent);

			if (phase === PagePhase.Result) {
				this.stopTimer()
			} else {
				this.checkCurrentPageTimer()
			}

			this.recalculateProgress()
		},
	},
	computed: {
		...mapGetters({
			currentCase: 'getCurrentCase',
			currentPagePhase: 'getCurrentPagePhase',
			currentCasePage: 'getCurrentPage',
		}),
		breadcrumbUrl() {
			return getTeacherUrl('lobby')
		},
		title() {
			if (!this.currentCasePage) {
				return null
			}

			return this.currentCasePage.title || ''
		},
		description() {
			if (!this.currentCasePage) {
				return null
			}

			return this.currentCasePage.description || ''
		},
		left() {
			if (!this.currentCasePage) {
				return null
			}

			return this.currentCasePage.components.hasOwnProperty(
				'left') ? this.currentCasePage.components.left : null
		},
		leftComponent() {
			return this.left ? this.left.component : null
		},
		leftComponentOptions() {
			console.log('LEFT OPTIONS', this.left)
			if (!this.left) {
				return null
			}

			if (this.left.options instanceof Array) {
				return null
			}

			return this.left.options
		},
		right() {
			if (!this.currentCasePage) {
				return null
			}

			return this.currentCasePage.components.hasOwnProperty(
				'right') ? this.currentCasePage.components.right : null
		},
		rightComponent() {
			return this.right ? this.right.component : null
		},
		rightComponentOptions() {
			console.log('RIGHT OPTIONS', this.right, this)
			if (!this.right) {
				return null
			}

			if (this.right.options instanceof Array) {
				return null
			}

			return this.right.options
		},
	},
	methods: {
		onTimerStart(timeTotal) {
			this.timerTimeTotal = timeTotal

			this.$store.commit(MutationTypes.SET_TIMER_TIME_TOTAL, timeTotal);
			this.$store.commit(MutationTypes.SET_TIMER_TIME_REMAINING, timeTotal);

			this.showTimer = true
		},
		onTimerTick(timeTotal, timeElapsed) {
			let timeRemaining = timeTotal - timeElapsed

			console.log('TIME REMAINING', timeRemaining, timeTotal, timeElapsed);

			this.$store.commit(MutationTypes.SET_TIMER_TIME_TOTAL, timeTotal);
			this.$store.commit(MutationTypes.SET_TIMER_TIME_REMAINING, timeRemaining);

			this.timerTimeTotal = timeTotal
			this.timerTimeRemaining = timeRemaining
		},
        onTimerComplete(timeTotal) {
		    this.doStopTimer();
        },
		onTimerStop(timeTotal) {
            this.doStopTimer();
		},
		onTimerCancel() {
		    this.doStopTimer();
		},
        doStopTimer() {
            this.timerTimeTotal = 0
            this.timerTimeRemaining = 0
            this.showTimer = false;

            this.$store.commit(MutationTypes.SET_TIMER_TIME_TOTAL, 0);
            this.$store.commit(MutationTypes.SET_TIMER_TIME_REMAINING, 0);
        },
		onRoomMessage(room, message) {
			let regexMatch

            //console.log('message', message);

			regexMatch = message.match(/^TimerEvent\.Start\:(\d+)$/)

			if (regexMatch) {
				this.onTimerStart(parseInt(regexMatch[1], 10))

				return
			}

			regexMatch = message.match(/^TimerEvent\.Tick\:(\d+):(\d+)$/)

			if (regexMatch) {
				this.onTimerTick(parseInt(regexMatch[1], 10), parseInt(regexMatch[2], 10))

				return
			}

			regexMatch = message.match(/^TimerEvent\.Stop\:(\d+)$/)

			if (regexMatch) {
				this.onTimerStop(parseInt(regexMatch[1], 10))

				return
			}

            regexMatch = message.match(/^TimerEvent\.Complete\:(\d+)$/)

            if (regexMatch) {
                this.onTimerComplete(parseInt(regexMatch[1], 10))

                return
            }

			regexMatch = message.match(/^TimerEvent\.Cancel$/)

			if (regexMatch) {
				this.onTimerCancel()

			}
		},
		stopTimer() {
			try {
				this.$gameClient.send({'_stopTimer': true})
			} catch (e) {
				// Could not send, we're probably not connected any longer due to an error
				// If we just catch this, then the normal logic should send us back
			}
		},
		startTimer(time) {
			try {
				this.$gameClient.send({'_startTimer': time})
			} catch (e) {
				// Could not send, we're probably not connected any longer due to an error
				// If we just catch this, then the normal logic should send us back
			}
		},
		checkCurrentPageTimer() {
			if (this.currentCasePage) {
				let timer;

				if (this.leftComponent === 'Choices') {
					let momentDilemmaTime = this.leftComponentOptions.momentDilemmaTime;
					let momentDilemmaVoteTime = this.leftComponentOptions.momentDilemmaVoteTime;
					let weightMapTokenTime = this.leftComponentOptions.weightMapTokenTime;

					let pagePhases = {
						Normal: 1,
						Voting: 2,
						Result: 3,
						WeightMapInput1: 4,
						WeightMapOutput1: 5,
						WeightMapInput2: 6,
						WeightMapOutput2: 7
					};

					let currentPagePhaseText = pagePhases[this.currentPagePhase];

					console.group('Timer stuff')
					console.log('currentPhase', currentPagePhaseText);
					console.log('currentPhase', this.currentPagePhase);
					console.log('momentDilemmaTime', momentDilemmaTime);
					console.log('momentDilemmaVoteTime', momentDilemmaVoteTime);
					console.log('weightMapTokenTime', weightMapTokenTime);
					console.groupEnd();

					if (this.currentPagePhase === PagePhase.Normal) {
						timer = momentDilemmaTime;
					} else if (this.currentPagePhase === PagePhase.Voting) {
					    timer = momentDilemmaVoteTime;
                    } else if (this.currentPagePhase === PagePhase.WeightMapInput1 || this.currentPagePhase === PagePhase.WeightMapInput2) {
					    timer = weightMapTokenTime;
                    }
				} else if (this.currentCasePage.timer > 0) {
					timer = this.currentCasePage.timer;
				}

				if (timer) {
                    this.startTimer(timer);
                } else {
                    this.stopTimer()
                }
			} else {
				this.stopTimer()
			}
		},
		recalculateProgress() {
			let currentCasePages = this.currentCase.pages

			const currentCasePageIndex = this.currentCasePage.index

			let currentCasePageSegments = []
			let completedCount = 0

			let pushSegment = (currentPageIndex, segmentIndex, completed) => {
				const currentCasePageSegment = {
					pageIndex: currentPageIndex,
					segmentIndex: segmentIndex,
					completed: completed,
				}

				currentCasePageSegments.push(currentCasePageSegment)

				if (currentCasePageSegment.completed) {
					completedCount++
				}
			}

			for (let currentCasePage of currentCasePages) {
				const leftComponent = currentCasePage.components.left
				const leftComponentName = leftComponent.component
				const leftComponentOptions = leftComponent.options

				pushSegment(currentCasePage.index, 0, currentCasePage.index <= currentCasePageIndex)
			}

			this.caseProgressBarWidthPercent = (completedCount / currentCasePageSegments.length) * 100
		},
	},
}
</script>

<style lang="less" scoped>
.timer-container {
	position: fixed;
	left: 170px;
	bottom: 40px;
	z-index: 1000;
}

.timer-solid-container {
	bottom: -27px;
	position: fixed;
	left: 78px;
	z-index: 1000;
	transform: scale(0.5);
}

.case-progress {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 10px;

	.case-progress-filled {
		background: #002D74;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;

		transition-property: width;
		transition-timing-function: ease-in-out;
		transition-duration: 250ms;
	}
}
</style>
