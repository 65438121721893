<template>
	<div class="container teacher-login-container" v-if="0">
		<div class="login-box">
			<div class="row logo-container">
				<img class="logo" src="~@img/misc/logo.png"/>
			</div>

			<div class="row mt-3">
				<div v-if="error" class="mb-3 error-container">
					<strong>{{ $t('login.error') }}:</strong> {{ error }}
				</div>

				<input v-model="password" class="form-control form-control-lg" type="password"
					   @keypress.enter="doLogin"/>
			</div>

			<div class="row buttons enter-button mt-3">
				<app-button-fit :enabled="!!password" v-on:click.native="doLogin">{{ $t('login.login') }}</app-button-fit>
			</div>
		</div>
        <router-link :to="studentConnectLink" class="student-connect-link"></router-link>
	</div>
</template>

<script>
import LoadingIndicator from '@/components/loading-indicator/index'
import AppButton from '@/components/app-button'
import AppButtonFit from '@/components/app-button-fit'

import * as MutationTypes from '@/store/mutation-types'
import {mapGetters} from 'vuex'
import { getStudentUrl } from '../../../../utils/UrlUtil'

export default {
	components: {
		LoadingIndicator,
		AppButton,
		AppButtonFit,
	},
	data() {
		return {
			error: null,
			password: '',
            studentConnectLink: getStudentUrl('/student/connect'),
		}
	},
	computed: {
		...mapGetters({
			teacherPassword: 'getTeacherPassword',
		}),
	},
	beforeMount() {
		if (this.teacherPassword) {
			this.error = null

			this.password = this.teacherPassword;

			this.doLogin();
		}
	},
	mounted() {
		if (!!process.env.VUE_APP_IS_DEV && !this.password) {
			this.error = null

			this.password = process.env.VUE_APP_TEACHER_PASSWORD
		}
	},
	methods: {
		doLogin() {
			if (!this.password) {
				this.error = 'Password incorrect'

				return
			}

			this.error = null

			if (this.password === process.env.VUE_APP_TEACHER_PASSWORD) {
				this.$store.commit(MutationTypes.SET_TEACHER_PASSWORD, this.password);

				this.$emit('loggedIn')
			} else {
				this.error = 'Password incorrect'
			}
		},
	},
}
</script>

<style lang="less">
.login-box {
	margin: 0 auto;
	width: 500px;

	img {
		max-width: 100%;
	}
}

.error-container {
	color: #aa0000;
}

.student-connect-link {
    cursor: pointer;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 10px;
    height: 10px;
    display: block;

}
</style>
