<template>
	<div class="container">
		<transition mode="out-in" name="fade">
			<div v-if="connecting" key="connecting" class="transition-root">
				<div class="row">
					<loading-indicator>{{ $t('room.connecting') }}..</loading-indicator>
				</div>
			</div>
			<div v-else-if="loading" key="loading" class="transition-root">
				<div class="row">
					<loading-indicator>{{ $t('room.loading') }}..</loading-indicator>
				</div>
			</div>
			<div v-else-if="loadingClassInfo" key="loadingClassInfo" class="transition-root">
				<div class="row">
					<loading-indicator>{{ $t('room.loading') }}..</loading-indicator>
				</div>
			</div>

			<div v-if="!connecting && !loading" key="notConnecting" class="content-container transition-root">
				<img class="logo" src="~@img/misc/logo-mini.png"/>
				<div class="top-bar">
					<h1 class="room-code-title">{{ $t('room.participate') }}</h1>
					<p class="room-code-text">{{ $t('room.enterDescription') }}</p>

					<emoji-code-current :activeEmojiIndex="activeEmojiIndex"
										:show-triangle="true"
										:value="roomCode"
										v-on:activeEmojiIndexChange="activeEmojiIndexChange"/>

				</div>

				<div class="bottom-bar">
					<div class="emoji-container">
						<emoji-code-picker
							v-model="roomCode"
							type="tiny"
							v-bind:activeEmojiIndex="activeEmojiIndex"
							v-on:activeEmojiIndexChange="activeEmojiIndexChange"
							v-on:fillOutStatusChanged="onFilloutStatusChanged"
						/>
					</div>

					<div class="buttons enter-button mt-1">
						<app-button-fit :enabled="canJoin" v-on:click.native="onClickJoin">{{
								$t('room.enter')
							}}
						</app-button-fit>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import {$eBus} from '@/event-bus'

import PlayerType from '@/enum/PlayerType'
import GameClientEvent from '@/enum/GameClientEvent'
import LoadingIndicator from '@/components/loading-indicator/index'
import * as MutationTypes from '@/store/mutation-types'
import EmojiCodePicker from '@/components/emoji-code/picker'
import EmojiCodeCurrent from '@/components/emoji-code/current-code'
import AppButton from '@/components/app-button'
import AppButtonFit from '@/components/app-button-fit'
import {CurrentUrlInfo} from '@/current-url-info'
import AppConfig from '@/app-config'
import {mapGetters} from 'vuex'
import {getStudentUrl} from '@/utils/UrlUtil'
import LanguageSelector from '@/components/language-selector'
import RoomJoinerMixin from '@/components/mixins/RoomJoinerMixin'

export default {
	mixins: [
		RoomJoinerMixin,
	],
	components: {
		LoadingIndicator,
		EmojiCodePicker,
		EmojiCodeCurrent,
		AppButton,
		AppButtonFit,
		LanguageSelector,
	},
	data() {
		let currentUrlInfo = CurrentUrlInfo.instance()

		let host = AppConfig.colyseusHost || currentUrlInfo.getCurrentHost()
		let port = AppConfig.colyseusPort
		let wsProtocol = AppConfig.colyseusSecure ? 'wss' : 'ws'

		return {
			host: host,
			port: port,
			wsProtocol: wsProtocol,

			canJoin: false,
			connecting: false,
			reconnecting: false,
			loading: false,
			roomCode: '',
			activeEmojiIndex: 0,

			loadingClassInfo: false,
		}
	},
	computed: {
		...mapGetters({
			lastEmojiCode: 'getLastEmojiCode',
			features: 'getFeatures',
			userClasses: 'getUserClasses',
			userInfo: 'getUserInfo',
			userToken: 'getUserToken',
			userSelectedClass: 'getUserSelectedClass',
			userClassSession: 'getUserClassSession',
		}),
	},
	beforeDestroy() {
		$eBus.$off(GameClientEvent.Error, this.onClientError)
		$eBus.$off(GameClientEvent.Open, this.onClientCreated)
		$eBus.$off(GameClientEvent.RoomJoin, this.onRoomJoin)
	},
	beforeMount() {
		this.resetRoomCode()
	},
	mounted() {
		console.log('FLOW STUDENT 0-CONNECT MOUNTED')
		$eBus.$on(GameClientEvent.Error, this.onClientError)
		$eBus.$on(GameClientEvent.Open, this.onClientCreated)
		$eBus.$on(GameClientEvent.RoomJoin, this.onRoomJoin)

		if (this.$gameClient && this.$gameClient.inRoom()) {
			console.log('11111 LEAVING ROOM BECAUSE WE WERE ALREADY IN A ROOM')
			this.$gameClient.leaveRoom()
		} else {
			console.log('11111 WE WERE NOT IN A ROOM OR THE GAME CLIENT WASNT DEFINED')
		}

		if (this.features.autoJoin) {
			this.tryConnect()
		} else {
			if (this.lastEmojiCode) {
				console.log('lastEmojiCode', this.lastEmojiCode)

				this.tryReconnect()
				/*return;
				this.$apiClient.isRoomJoinable(this.lastEmojiCode, () => {
				}, () => {
				  this.$store.commit(MutationTypes.SET_LAST_EMOJI_CODE, null)
				})*/
			}
		}

		this.$store.dispatch({type: 'fullReset'})
	},
	methods: {
		resetRoomCode() {
			let roomCode = ''

			for (let i = 0; i < this.features.roomIdLength; i++) {
				roomCode += '0'
			}

			this.roomCode = roomCode
		},
		goHome() {
			this.$router.push('/')
		},
		tryReconnect() {
			if (this.reconnecting) {
				return
			}

			this.reconnecting = true
			this.roomCode = this.lastEmojiCode
			this.activeEmojiIndex = this.roomCode.length - 1
			this.canJoin = true
			this.onClickJoin()
		},
		activeEmojiIndexChange(activeEmojiIndex) {
			console.log('activeEmojiIndex', activeEmojiIndex)

			this.activeEmojiIndex = activeEmojiIndex
		},
		onFilloutStatusChanged(canJoin) {
			this.canJoin = canJoin
		},
		onClickJoin() {
			if (!this.canJoin) {
				return
			}

			window.connectedBefore = false

			this.tryConnect()
		},
		onClientError(client) {
			console.log('ABC', client)

			if (client) {
				client.close()
			}

			this.$store.commit(MutationTypes.SET_ERROR_ICON, 'connectionError')
			this.$store.commit(MutationTypes.SET_ERROR_MESSAGE, this.$t('room.connectionError'))
		},
		onRoomJoin() {
			$eBus.$off(GameClientEvent.Error, this.onClientError)
			$eBus.$off(GameClientEvent.Open, this.onClientCreated)

			this.reconnecting = false
			this.connecting = false

			this.$gameClient.send({
				playerType: PlayerType.Student,
			})

			this.$store.commit(MutationTypes.SET_LAST_EMOJI_CODE, this.roomCode)

			this.$router.push(getStudentUrl('lobby'))
		},
		onRoomError(e) {
			console.log('ON ROOM ERROR!!!', e)
		},
		tryConnect() {
			if (this.$gameClient) {
				this.connect()
			} else {
				console.log('Game client not yet found.. waiting till next tick and trying again!')

				this.$nextTick(() => {
					this.tryConnect()
				})
			}
		},
		connect() {
			if (window.connectedBefore) {
				console.log('NOT JOINING AGAIN')
				return
			}

			window.connectedBefore = true

			console.log(this.features.autoJoin)

			if (this.features.autoJoin) {
				this.$gameClient.connect(this.host, this.port, this.wsProtocol)
			} else {
				$eBus.$on(GameClientEvent.Error, this.onClientError)
				$eBus.$on(GameClientEvent.Open, this.onClientCreated)
				$eBus.$on(GameClientEvent.RoomJoin, this.onRoomJoin)
				$eBus.$on(GameClientEvent.RoomError, this.onRoomError)

				let wsProtocol = AppConfig.colyseusSecure ? 'wss' : 'ws'

				this.$gameClient.connect(this.host, this.port, this.wsProtocol)

				this.connecting = true

				console.log('Connecting to room at room code', this.roomCode)

				this.connectStudentToRoomCode(this.roomCode).then(() => {
					this.loading = false
				}).catch(() => {
					this.loading = false
					this.connecting = false

					if (!this.reconnecting) { //Dont show errors on reconnect as it was forced by the app
						this.$store.commit(MutationTypes.SET_ERROR_ICON, 'emojiCode')
						this.$store.commit(MutationTypes.SET_ERROR_MESSAGE, this.$t('room.codeError'))
					} else {
						// Forget last emoji code
						localStorage.removeItem('lastEmojiCode')
						localStorage.removeItem('lastEmojiCodeTime')

						this.lastEmojiCode = null
						this.reconnecting = false
						this.activeEmojiIndex = 0
						this.canJoin = false

						this.resetRoomCode()
					}
				})

				/*

				this.$apiClient.isRoomJoinable(this.roomCode, () => {
				  this.$gameClient.connect(this.host, this.port, this.wsProtocol)

				  this.loading = false
				}, (message) => {
				  this.roomCode = '0000';
				  this.activeEmojiIndex = 0;

				  if (!this.reconnecting) { //Dont show errors on reconnect as it was forced by the app
					let status = this.$apiClient.lastResponse ? this.$apiClient.lastResponse.status : null;

					if (status === 404) {
					  this.$store.commit(MutationTypes.SET_ERROR_ICON, 'emojiCode')
					  this.$store.commit(MutationTypes.SET_ERROR_MESSAGE, this.$t('room.codeError'))
					} else {
					  this.$store.commit(MutationTypes.SET_ERROR_ICON, 'connectionError')
					  this.$store.commit(MutationTypes.SET_ERROR_MESSAGE, this.$t('room.serverError'))
					}
				  }

				  this.reconnecting = false
				  this.connecting = false
				})*/
			}
		},
		/*connect() {
		},*/
		onClientCreated() {
			$eBus.$off(GameClientEvent.Error, this.onClientError)
			$eBus.$off(GameClientEvent.Open, this.onClientCreated)
			$eBus.$off(GameClientEvent.RoomError, this.onRoomError)

			this.connected = true

			// Look up case info
			if (this.features.autoJoin) {
				if (this.features.dilemma && this.features.roleplay) {
					window.connectedBefore = false

					throw new Error('You cannot run auto join rooms with both dilemma and roleplay')
				}

				this.connectStudentToClassSession(this.userClassSession.uid).then((roomCode) => {
					console.log('success!', 'roomCode', roomCode)

					this.roomCode = roomCode
				}).catch((e) => {
					if (e.message.indexOf('no rooms found with provided criteria') >= 0 ||
						e.message.indexOf('onAuth failed') >= 0) {
						this.$store.commit(MutationTypes.SET_ERROR_ICON, 'connectionError')
						this.$store.commit(MutationTypes.SET_ERROR_MESSAGE,
							'Læreren har ikke startet spørgeskemaet endnu. Vent venligst.')
						this.$store.commit(MutationTypes.SET_ERROR_BUTTON, null)
						this.$store.commit(MutationTypes.SET_ERROR_BUTTON_ACTION, () => {
							window.connectedBefore = false

							this.$router.push('/?userToken=' + this.userToken)
						})
					} else {
						this.$store.commit(MutationTypes.SET_ERROR_ICON, 'connectionError')
						this.$store.commit(MutationTypes.SET_ERROR_MESSAGE,
							'Kunne ikke forbinde til rum: ' + e.message)
						this.$store.commit(MutationTypes.SET_ERROR_BUTTON, null)
						this.$store.commit(MutationTypes.SET_ERROR_BUTTON_ACTION, () => {
							window.connectedBefore = false

							this.$router.push('/?userToken=' + this.userToken)
						})
					}
				})
			} else {
				//this.$gameClient.joinRoom(this.roomCode)
			}
		},
	},
}
</script>

<style lang="less" scoped>
@import "~@less/variables.less";

.content-container {
	padding-top: 55px;
	//margin-bottom: 30px;
	//transform: translateY(-25px);
}

.logo {
	width: 50px;
	height: auto;
	margin-bottom: 20px;
	position: absolute;
	right: 30px;
	top: 25px;
}

.logo:hover {
	cursor: pointer;
}

.room-code-title {
	margin-bottom: 10px;
	color: @color-black;
}

.room-code-text {
	text-align: center !important;
	font-weight: 500 !important;
	color: @color-black;
	opacity: 0.8;
	margin-bottom: 20px;
	width: 85%;
	margin-right: auto;
	margin-left: auto;
}

.buttons {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	margin: auto;
}

.top-bar {
}

.bottom-bar {
	color: @white;
}

.emoji-container {
	background: rgba(2, 48, 71, 0.1);
	// width: fit-content;
	width: 325px;
	padding: 10px;
	border-radius: 3px;
	/*right: 50%;
	position: absolute;
	transform: translate(-50%, 0);*/
	margin-left: auto;
	margin-right: auto;
}

.enter-button {
	margin-top: 20px !important;
	margin-bottom: 30px !important;
	width: 325px !important;
}

</style>
