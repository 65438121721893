var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    _vm._l(_vm.fontSizeNames, function (fontSizeName, i) {
      return _c(
        "div",
        {
          staticClass: "container-app-font-size",
          class: "container-" + fontSizeName,
        },
        [
          _c("div", [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(fontSizeName) +
                " (" +
                _vm._s(_vm.fontSizes[fontSizeName]) +
                ")\n\t\t"
            ),
          ]),
          _c("div", { staticClass: "em-container-wrapper" }, [
            _c(
              "div",
              {
                staticClass: "em-container",
                class: _vm.emFontSizes[fontSizeName],
              },
              [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(fontSizeName) +
                    "-em (" +
                    _vm._s(_vm.emFontSizes[fontSizeName] || "default") +
                    ")\n\t\t\t"
                ),
              ]
            ),
          ]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.emFontSizes[fontSizeName],
                  expression: "emFontSizes[fontSizeName]",
                },
              ],
              staticClass: "form-control",
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.emFontSizes,
                    fontSizeName,
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { attrs: { value: "" } }, [_vm._v("Select one")]),
              _c("option", { attrs: { value: "font-size-xs" } }, [
                _vm._v("font-size-xs (0.6em)"),
              ]),
              _c("option", { attrs: { value: "font-size-sm" } }, [
                _vm._v("font-size-sm (0.8em)"),
              ]),
              _c("option", { attrs: { value: "font-size-md" } }, [
                _vm._v("font-size-md (1.0em)"),
              ]),
              _c("option", { attrs: { value: "font-size-lg" } }, [
                _vm._v("font-size-lg (1.2em)"),
              ]),
              _c("option", { attrs: { value: "font-size-xl" } }, [
                _vm._v("font-size-xl (1.4em)"),
              ]),
              _c("option", { attrs: { value: "font-size-xxl" } }, [
                _vm._v("font-size-xxl (2em)"),
              ]),
              _c("option", { attrs: { value: "font-size-xxxl" } }, [
                _vm._v("font-size-xxxl (2.5em)"),
              ]),
              _c("option", { attrs: { value: "font-size-xxxxl" } }, [
                _vm._v("font-size-xxxxl (4em)"),
              ]),
            ]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }