<script>
import Vue from 'vue'
import * as Colyseus from 'colyseus.js' // not necessary if included via <script> tag.
import { $eBus } from '@/event-bus/index'
import GameClientEvent from '@/enum/GameClientEvent'
import { ConsoleLogger } from '@/console-logger'
import AppConfig from '@/app-config'
import { WebsocketCloseCodeUtil } from './WebsocketCloseCodeUtil.js'
import { v4 as uuidv4 } from 'uuid';

export default {
    name: 'game-client',
    data () {
        return {
            internalIsConnected: false,
            client: null,
            room: null,
            roomId: null,
            logMessages: [],
            playerSessionId: false, // set in this.room.onJoin
            expanded: false,
            colyseusEndPoint: null,
            logger: new ConsoleLogger('GameClient', 'background: #00aa00; color: white', null,
                !AppConfig.debug.gameClient),

            currentCasePage: null,
        }
    },
    mounted () {
        Object.defineProperty(Vue.prototype, '$gameClient', {
            value: this,
            configurable: true,
        })

        console.log('GAMECLIENT', this)

        this.logMessage(`Assigned to Vue.$gameClient`)
    },
    watch: {
        phase () {
            let newPhase = this.phase

            if (newPhase) {
                this.logMessage(`Phase changed! New phase: ${newPhase}`)

                $eBus.$emit(GameClientEvent.PhaseChanged, newPhase)
            }
        },
        roomId () {
            $eBus.$emit(GameClientEvent.CustomRoomIdChanged, this.roomId)
        },
    },
    computed: {
        phase () {
            try {
                return this.room.state.phase
            }
            catch (error) {
                return null
            }
        },
    },
    methods: {
        generateUid(playerType) {
            // Add player type to separate teachers/students and give them two separate keys
            // even though it runs on the same computer
            return playerType + '-' + uuidv4();
        },
        getUid(playerType) {
            let key = 'gameClientUid-' + playerType;

            let uid = localStorage.getItem(key)

            if (!uid) {
                uid = this.generateUid(playerType);

                localStorage.setItem(key, uid);
            }

            return uid;
        },
        isPhase (phase) {
            if (Array.isArray(phase)) {
                return phase.includes(this.phase)
            }
            else {
                return phase === this.phase
            }
        },

        isMe (playerId) {
            return playerId === this.playerSessionId
        },

        // Logger
        clearLog () {
            console.log('Clearing log!')

            this.logMessages = []
        },
        logMessage (message) {
            this.logger.logMessage(message)

            this.logMessages.push(message)
        },

        // Game client control
        async connect (host, port, wsProtocol) {
            let colyseusHostWithPort = `${host}:${port}`

            let colyseusUrl = (wsProtocol || 'ws') + '://' + colyseusHostWithPort

            this.colyseusEndPoint = colyseusUrl

            $eBus.$emit(GameClientEvent.EndPointChanged, colyseusHostWithPort)

            this.logMessage(`Connecting to ${colyseusUrl}`)

            try {
                this.client = new Colyseus.Client(colyseusUrl)

                $eBus.$emit(GameClientEvent.Open, this.client)

                this.internalIsConnected = true
            }
            catch (e) {
                $eBus.$emit(GameClientEvent.Error, this.client, e)
            }

            /*
                this.client.onError.add((error) => {
                this.logMessage(GameClientEvent.Error + ': ' + error)

                $eBus.$emit(GameClientEvent.Error, this.client, error)

                this.internalIsConnected = false
                })

                this.client.onClose.add(() => {
                this.logMessage(GameClientEvent.Close)

                $eBus.$emit(GameClientEvent.Close, this.client)

                this.internalIsConnected = false
                })

                this.client.onOpen.add(() => {
                this.logMessage(GameClientEvent.Open)

                $eBus.$emit(GameClientEvent.Open, this.client)

                this.internalIsConnected = true
                })*/
        },
        getPageFromInstance (instance) {
            return {
                caseId: instance.caseId,

                components: {
                    left: instance.component_left ? JSON.parse(JSON.stringify(instance.component_left)) : null,
                    right: instance.component_right ? JSON.parse(JSON.stringify(instance.component_right)) : null,
                },
                component_left: instance.component_left || null,
                component_right: instance.component_right || null,

                id: instance.id,
                index: instance.index || 0,
                lastPage: instance.lastPage || false,
                showBackButton: instance.showBackButton || false,
                hidePlayAgainButton: instance.hidePlayAgainButton || false,
                timer: instance.timer > 0 ? instance.timer : null,
                title: instance.title || null,
                type: instance.type || null,
            }
        },
        onRoomStateCaseInfoChanged (changes, caseInfo = null) {
            if (!caseInfo) {
                caseInfo = this.room.state.case_info
            }

            $eBus.$emit(GameClientEvent.CaseInfoChanged, caseInfo, changes)
        },
        onRoomStateCurrentPageChanged (changes, roomState = null) {
            if (!roomState) {
                roomState = this.room.state
            }

            let currentCasePage, page;

            if (roomState.current_case_page && roomState.current_case_page.id) {
                currentCasePage = roomState.current_case_page
            }
            /*else if (roomState.current_case_page_json) {
                currentCasePage = JSON.parse(roomState.current_case_page_json)
            }*/

            if (currentCasePage) {
                page = this.getPageFromInstance(currentCasePage)
            }

            if (page) {
                // If current page isn't already set or the page has changed
                if (!this.currentCasePage || this.currentCasePage.id !== page.id) {
                    // New page
                    console.log('onRoomStateCurrentPageChanged', page)

                    $eBus.$emit(GameClientEvent.CaseCurrentPageChanged, page, changes)

                    this.currentCasePage = page
                }
                else {
                    //console.log('XXXXX', this.currentCasePage.id, currentCasePage.id, page.id);

                    //console.log('PAGE NOT UPDATED (no current case page)', roomState, this.currentCasePage, currentCasePage, page);
                    //console.log(' - this.currentCasePage', JSON.stringify(this.currentCasePage, null, ' '));
                    //console.log(' - currentCasePage', JSON.stringify(currentCasePage, null, ' '));
                    //console.log(' - page', JSON.stringify(page, null, ' '));
                }
            }
            else {
                //console.log('PAGE NOT UPDATED (no page)', roomState, this.currentCasePage, currentCasePage, page);
                //console.log(' - this.currentCasePage', JSON.stringify(this.currentCasePage, null, ' '));
                //console.log(' - currentCasePage', JSON.stringify(currentCasePage, null, ' '));
                //console.log(' - page', JSON.stringify(page, null, ' '));
            }
        },
        onRoomJoined (room, onJoin = null) {
            let previousState = {}

            room.onStateChange((state) => {
                this.logMessage(GameClientEvent.RoomStateChange)
                this.logMessage(JSON.stringify(state, null, 2))

                if (state.customId && this.roomId !== state.customId) {
                    this.roomId = state.customId
                }

                this.onRoomStateCurrentPageChanged()
                /*
                let currentState = JSON.parse(JSON.stringify(state));

                let previousHasPageObj = !!(previousState && previousState.current_case_page && previousState.current_case_page.id);
                let previousHasPageJson = !!(previousState && previousState.current_case_page_json);

                let currentHasPageObj = !!(currentState && currentState.current_case_page && currentState.current_case_page.id);
                let currentHasPageJson = !!(currentState && currentState.current_case_page_json);

                let previousHasPage = previousHasPageObj || previousHasPageJson;
                let currentHasPage = currentHasPageObj || currentHasPageJson;

                console.log('currentState', currentState);
                console.log('previousState', previousState);
                console.log('previousHasPageObj', previousHasPageObj);
                console.log('previousHasPageJson', previousHasPageJson);
                console.log('currentHasPageObj', currentHasPageObj);
                console.log('currentHasPageJson', currentHasPageJson);
                console.log('previousHasPage', previousHasPage);
                console.log('currentHasPage', currentHasPage);

                if (!previousHasPage && currentHasPage) {
                  console.log('Did not have a previous page but had a current page');

                  this.onRoomStateCurrentPageChanged(state);
                }*/

                $eBus.$emit(GameClientEvent.RoomStateChange, room, state)

                //previousState = clone(currentState);
            })

            room.state.listen('case_info', (previousValue, currentValue) => {
                console.log('case_info', previousValue, currentValue)

                this.onRoomStateCaseInfoChanged({}, currentValue)
            })

            room.state.listen('current_case_page', (previousValue, currentValue) => {
                console.log('current_case_page', previousValue, currentValue)

                this.onRoomStateCurrentPageChanged({}, currentValue)
            })

            room.state.case_info.onChange = this.onRoomStateCaseInfoChanged
            room.state.case_info.onAdd = this.onRoomStateCaseInfoChanged
            room.state.case_info.onRemove = this.onRoomStateCaseInfoChanged
            room.state.current_case_page.onChange = this.onRoomStateCurrentPageChanged
            room.state.current_case_page.onAdd = this.onRoomStateCurrentPageChanged
            room.state.current_case_page.onRemove = this.onRoomStateCurrentPageChanged

            if (room.state.case_info && room.state.case_info.id) {
                this.onRoomStateCaseInfoChanged({})
            }

            if (room.state.current_case_page && room.state.current_case_page.id) {
                this.onRoomStateCurrentPageChanged({})
            }


            /*else if (room.state.current_case_page_json) {
                this.onRoomStateCurrentPageChanged({})
            }*/

            room.onMessage('*', (message) => {
                this.logMessage(GameClientEvent.RoomMessage + ': ' + message)

                $eBus.$emit(GameClientEvent.RoomMessage, room, message)
            })

            room.onLeave((code) => {
                // https://github.com/Luka967/websocket-close-codes#websocket-close-codes
                let codeString = WebsocketCloseCodeUtil.getErrorCodeString(code)

                this.logMessage(GameClientEvent.RoomLeave + ': ' + code + ' (' + codeString + ')')

                if (codeString) {
                    // If we have a code string it must be a real websocket close error, let's disconnect if we can
                    this.disconnect(code, codeString)
                }
                else {
                    $eBus.$emit(GameClientEvent.RoomLeave, room, code)
                }
            })

            room.onError((code, error) => {
                this.logMessage(GameClientEvent.RoomError + ': ' + error)

                $eBus.$emit(GameClientEvent.RoomError, room, error)
            })

            this.playerSessionId = room.sessionId

            this.logMessage(GameClientEvent.RoomJoin)

            $eBus.$emit(GameClientEvent.RoomJoin)

            if (onJoin) {
                onJoin(this.room)
            }

            $eBus.$emit(GameClientEvent.RoomCreated, room)
        },
        async joinOrCreateRoom (roomId, playerType, onJoin = null, onError = null) {
            this.roomId = roomId

            let colyseusOptions = {
                customId: roomId,
                uid: this.getUid(playerType),
                device: navigator.userAgent,
                playerType: playerType,
            }

            try {
                console.log('joinOrCreate.game', colyseusOptions)
                this.room = await this.client.joinOrCreate('game', colyseusOptions)

                console.log('joined successfully', this.room)

                this.onRoomJoined(this.room, onJoin)
            }
            catch (e) {
                console.error('join error', e)

                if (onError) {
                    onError(e)
                }
            }
        },
        async joinRoom (roomId, playerType, onJoin = null, onError = null) {
            this.roomId = roomId

            let colyseusOptions = {
                customId: roomId,
                uid: this.getUid(playerType),
                device: navigator.userAgent,
                playerType: playerType,
            }

            try {
                console.log('join.game', colyseusOptions)
                this.room = await this.client.join('game', colyseusOptions)

                console.log('joined successfully', this.room)

                this.onRoomJoined(this.room, onJoin)
            }
            catch (e) {
                console.error('join error', e)

                if (onError) {
                    onError(e)
                }
            }
        },
        leaveRoom () {
            if (this.room) {
                this.logMessage('Leaving room..')

                this.room.leave()

                this.room = null
            }

            this.currentCasePage = null;
        },
        inRoom () {
            return !!this.room
        },
        isConnected () {
            return this.internalIsConnected
        },
        isClientConnected () {
            return this.isConnected() || this.inRoom()
        },
        send (data) {
            if (!this.room) {
                throw new Error('Cannot send message as we are no longer in room!')
            }

            this.logMessage('Sending data')
            this.logMessage(JSON.stringify(data, null, 2))

            this.room.send('data', data)
        },
        disconnect (code = null, codeString = null) {
            this.logMessage('Disconnecting..')

            this.leaveRoom()

            this.internalIsConnected = false
            this.currentCasePage = null;
            //this.closeConnection()

            this.logMessage('Disconnected!')

            $eBus.$emit(GameClientEvent.Disconnected, code, codeString)
            $eBus.$emit(GameClientEvent.Close, code, codeString)
        },
        closeConnection () {
            if (this.client) {
                this.logMessage('Closing client connection..')

                this.client.close()

                this.client = null
            }

            console.log('CLOSING CONNECTION')
            this.currentCasePage = null;
            this.internalIsConnected = false
        },
    },
    render: function (h) {
        return h() // avoid warning message
    },
}
</script>
