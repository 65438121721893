var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-container" }, [
    _c("h2", { staticClass: "input-title" }, [
      _vm._v("\n\t\t" + _vm._s(_vm.title) + "\n\t"),
    ]),
    _vm.inputType === "checkbox"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.localValue,
              expression: "localValue",
            },
          ],
          staticClass: "input-field",
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.localValue)
              ? _vm._i(_vm.localValue, null) > -1
              : _vm.localValue,
          },
          on: {
            input: function ($event) {
              return _vm.$emit("update:value", _vm.localValue)
            },
            change: function ($event) {
              var $$a = _vm.localValue,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.localValue = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.localValue = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.localValue = $$c
              }
            },
          },
        })
      : _vm.inputType === "radio"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.localValue,
              expression: "localValue",
            },
          ],
          staticClass: "input-field",
          attrs: { type: "radio" },
          domProps: { checked: _vm._q(_vm.localValue, null) },
          on: {
            input: function ($event) {
              return _vm.$emit("update:value", _vm.localValue)
            },
            change: function ($event) {
              _vm.localValue = null
            },
          },
        })
      : _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.localValue,
              expression: "localValue",
            },
          ],
          staticClass: "input-field",
          attrs: { type: _vm.inputType },
          domProps: { value: _vm.localValue },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.localValue = $event.target.value
              },
              function ($event) {
                return _vm.$emit("update:value", _vm.localValue)
              },
            ],
          },
        }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }