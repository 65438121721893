<template>
	<div class="container">
		<div class="text-container">
			<NotchHeader :color="'blue'"/>
			<p class="case-info">
				{{ $t('game.stayTuned') }} <br><br>
				{{ $t('game.studentWaiting') }}
			</p>
		</div>
		<div class="case-image-container">

			<div :style="caseImageStyle" class="case-image">
			</div>
		</div>

	</div>
</template>

<script>
import {$eBus} from '@/event-bus'
import GameClientEvent from '@/enum/GameClientEvent'
import GamePhase from '@/enum/GamePhase'
import {getStudentUrl} from '@/utils/UrlUtil'
import * as MutationTypes from '@/store/mutation-types'
import AppButton from '@/components/app-button'
import MediaManager from '@/media-manager'
import ContentCard from '@/components/content-card/index'
import NotchHeader from '@/components/notch-header'

export default {
	components: {
		ContentCard,
		AppButton,
		NotchHeader,
	},
	data() {
		return {
			dataLoaded: false,
			pageType: '',
			case: null,
			theme: null,
			pages: {
				dilemma: [],
			},
		}
	},
	mounted() {
		if (this.$gameClient == null || !this.$gameClient.isConnected || !this.$gameClient.inRoom()) {
			this.$router.push(getStudentUrl('connect'))
			return
		}

		const ongoingPhases = [GamePhase.Pages, GamePhase.End];

		if (ongoingPhases.includes(this.$gameClient.room.state.phase)) {
            this.$router.push(getStudentUrl('game'))

            return;
        }

		$eBus.$on(GameClientEvent.RoomStateChange, this.onRoomStateChanged)
		$eBus.$on(GameClientEvent.PhaseChanged, this.onPhaseChanged)

		this.$store.commit(MutationTypes.SET_GAME_CLIENT_CLOSE_REDIRECT_URL, getStudentUrl('connect'))

		if (this.$gameClient.room.state.selectedCaseId) {
			this.loadCase(this.$gameClient.room.state.selectedCaseId)
		}

		if (this.$gameClient.room.state.pageType) {
			this.setPageType(this.$gameClient.room.state.pageType)
		}
	},
	beforeDestroy() {
		$eBus.$off(GameClientEvent.RoomStateChange, this.onRoomStateChanged)
		$eBus.$off(GameClientEvent.PhaseChanged, this.onPhaseChanged)
	},
	computed: {
		caseImage() {
			if (!this.case || !this.case.image) {
				return
			}

			return MediaManager.getMediaUrl(this.case.image)
		},
		caseImageStyle() {
			let caseImage = this.caseImage

			if (!caseImage) {
				return
			}

			return {
				'background-image': `url("${caseImage}")`,
			}
		},
	},
	methods: {
		onRoomStateChanged(room, roomState) {
			if (!this.dataLoaded && roomState.selectedCaseId) {
				this.loadCase(roomState.selectedThemeId, roomState.selectedCaseId)
			}

			if (roomState.pageType && this.pageType !== roomState.pageType) {
				this.setPageType(roomState.pageType)
			}
		},
		onPhaseChanged(newPhase) {
			if (this.dataLoaded && newPhase === GamePhase.Pages) {
				if (this.pageType === 'dilemma') {
					this.$router.push(getStudentUrl('game'))
				}
			}
		},
		loadCase(themeId, caseId) {
			this.dataLoaded = false

			//this.$store.commit(MutationTypes.SET_CURRENT_THEME, theme)

			this.loadPages(themeId, caseId, () => {
				this.dataLoaded = true
				if (this.pageType) {
					this.case.pages = this.pages[this.pageType]
					this.$store.commit(MutationTypes.SET_CURRENT_CASE, this.case)
					this.onPhaseChanged(this.$gameClient.room.state.phase)
				}
			})
		},
		loadPages(themeId, caseId, callback) {
			this.dataLoaded = false

			this.$apiClient.getTheme(themeId, (_theme) => {
				this.theme = _theme

				console.log('Theme:', _theme)

                if (this.theme.language) {
                    const languageCode = this.theme.language;

                    console.log('Changing locale/language to "' + languageCode + '"')

                    this.$i18n.locale = languageCode
                    this.$root.$i18n.locale = languageCode

                    this.$store.commit(MutationTypes.SET_LANGUAGE, languageCode)
                }

				this.$store.commit(MutationTypes.SET_CURRENT_THEME, _theme)

				this.$apiClient.getCase(caseId, (_case) => {
					this.case = _case
					this.$apiClient.getCasePages(caseId, 'dilemma', (pages) => {
						this.pages.dilemma = pages
						callback()
					})
				})
			})
		},
		setPageType(pageType) {
			this.pageType = pageType
			if (this.dataLoaded) {
				this.case.pages = this.pages[pageType]
			}
			this.$store.commit(MutationTypes.SET_CURRENT_CASE, this.case)
		},
		leaveRoom() {
			this.$store.commit(MutationTypes.SET_LAST_EMOJI_CODE, null)
			this.$gameClient.leaveRoom()
		},
	},
}
</script>

<style lang="less" scoped>
@import "~@less/variables.less";

.container {
	position: relative;
}

.text-container {
	background-color: rgba(0, 18, 51, 0.8);
	position: fixed;
	bottom: 0;
	width: 100%;
	height: calc(54vh);
	z-index: 2;

	.info {
		margin: 0px auto 50px auto;
		z-index: 2;
	}
}


.case-image {
	width: auto;
	margin-bottom: 10px;
	background-size: cover;
	background-position: 10% center;
	background-color: #000000;
	height: 100%;
}

.case-image-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
}


p.case-info {
	font-size: @font-size-xxl;
	text-align: center;
	width: 90%;
	color: white;
	padding: 0 50px;
	font-weight: 500;
	// margin-top: 40px;
	bottom: 50%;
	right: 50%;
	position: absolute;
	transform: translate(50%, 40%);

}

.logo {
	width: 150px;
	height: auto;
}
</style>
