<template>
	<table class="table table-bordered table-striped">
		<thead class="thead-dark">
		<slot name="head"></slot>
		</thead>
		<tbody>
		<slot name="body"></slot>
		</tbody>
		<tfoot>
		<slot name="foot"></slot>
		</tfoot>
	</table>
</template>

<script>
export default {
	name: 'data-table',
}
</script>

<style lang="less" scoped>
.table {
	background-color: white;
	font-size: 18px;
}

.btn {
	font-size: 12px;
	height: 30px;
	min-width: 60px;
}
</style>
