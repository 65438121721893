var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container student-container" },
    [
      _c("header", {
        staticClass:
          "top-bar d-flex justify-content-between align-items-center",
      }),
      _c(
        "transition",
        { attrs: { name: _vm.componentTransition } },
        [
          _c(_vm.currentPage, {
            tag: "component",
            staticClass: "student-page",
          }),
        ],
        1
      ),
      _vm.showDisconnectButton
        ? _c(
            "button",
            {
              staticClass: "btn btn-success btn-sm",
              attrs: { id: "btn-disconnect" },
              on: { click: _vm.disconnect },
            },
            [_vm._v("Disconnect")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }