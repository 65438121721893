<template>
    <select
        v-model="selected"
        :class="{'case-draft': selected == 'draft', 'case-published': selected == 'published', 'case-archived': selected == 'archived'}"
        class="form-control case-state-selector" v-on:change="onSelect()">
        <option value="draft">Draft</option>
        <option value="published">Published</option>
        <option value="archived">Archived</option>
    </select>
</template>

<script>

export default {
    name: 'case-state-selector',
    props: {
        state: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            selected: this.state,
        }
    },
    methods: {
        onSelect () {
            this.$emit('change', {
                state: this.selected,
            })
        },
    },
}
</script>

<style lang="less" scoped>
.case-state-selector {
    height: 30px;
    font-size: 14px;
    border: 2px solid;
}

.case-draft {
    border-color: gold;
}

.case-published {
    border-color: limegreen;
}

.case-archived {
    border-color: tomato;
}
</style>