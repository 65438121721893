var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("transition", { attrs: { mode: "out-in", name: "fade" } }, [
        _vm.currentCasePage
          ? _c(
              "div",
              [
                _c("div", { staticClass: "case-progress" }, [
                  _c("div", {
                    staticClass: "case-progress-filled",
                    style: { width: _vm.caseProgressBarWidthPercent + "%" },
                  }),
                ]),
                _c("page-component-container", {
                  attrs: {
                    breadcrumbUrl: _vm.breadcrumbUrl,
                    currentPage: _vm.currentCasePage,
                    description: _vm.description,
                    leftComponent: _vm.leftComponent,
                    leftComponentOptions: _vm.leftComponentOptions,
                    rightComponent: _vm.rightComponent,
                    rightComponentOptions: _vm.rightComponentOptions,
                    title: _vm.title,
                    useTransitions: _vm.useTransitions,
                  },
                }),
                _vm.timerTimeTotal && _vm.showTimer
                  ? _c(
                      "div",
                      { staticClass: "timer-solid-container" },
                      [
                        _c("TimerSolid", {
                          attrs: {
                            "time-remaining": _vm.timerTimeRemaining,
                            "time-total": _vm.timerTimeTotal,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }