<template>
	<div class="emoji-code-container">
		<single-emoji-code
			v-for="(singleCode, i) in codeParts"
			v-bind:key="i"
			v-bind:code="singleCode"
			v-bind:type="type"
		></single-emoji-code>
	</div>
</template>

<script>
import SingleEmojiCode from './single-emoji-code'
import {$eBus} from '@/event-bus'

export default {
	name: 'emoji-code',
	components: {
		SingleEmojiCode,
	},
	props: {
		type: {
			type: String,
			required: false,
			default: 'default',
		},
	},
	data() {
		return {
			codeParts: [],
		}
	},
	mounted() {
		$eBus.$on('gameClient:customRoomIdChanged', this.onCustomRoomIdChanged)

		if (this.$gameClient && this.$gameClient.roomId) {
			this.onCustomRoomIdChanged(this.$gameClient.roomId)
		}
	},
	beforeDestroy() {
		$eBus.$off('gameClient:customRoomIdChanged', this.onCustomRoomIdChanged)
	},
	methods: {
		onCustomRoomIdChanged(roomId) {
			this.codeParts = roomId ? roomId.split('') : []
		},
	},
}
</script>
<style lang="less" scoped>
.emoji-code-container {
	text-align: center;

	white-space: nowrap;
}
</style>
