<template>
	<div class="container teacher-container">
        <header
            class="top-bar fixed-top px-5 py-3 d-flex justify-content-between align-items-center">
            <!--<div class="d-flex">
              <breadcrumb :to="breadcrumbTo" :useOffset="false" class="mr-5"
                    id="header-breadcrumb" v-if="breadcrumbTo && currentPage !== 'pages'"/>-
              <div class="d-flex align-items-center" v-if="currentPage == 'pages'">
                <emoji-code class="room-code" type="tiny-transparent"/>
              </div>
            </div>-->
            <!--<div class="theme-name" v-if="currentPage!=='lobby'" :class = "(currentPage === 'lobby')?'theme-name-big':''">{{ currentCaseTitle }}</div>-->
            <img v-if="currentPage === 'lobby' || currentPage === 'intro'" class="logo"
                 src="~@img/misc/logo-small.png"/>
            <div v-if="currentPage === 'lobby'" class="logo-secret-button-admin" v-on:click="clickSecretButtonAdmin"/>
            <div v-if="currentPage === 'lobby'" class="logo-secret-button-student"
                 v-on:click="clickSecretButtonStudent"/>
            <!--<fullscreen-toggle-button id="fullscreen-toggle-button"/>-->
        </header>
        <transition :name="componentTransition">
            <component :is="currentPage" class="teacher-page" @loggedIn="isLoggedIn = true"></component>
        </transition>

        <div v-if="showBigQrCode" class="large-qr-wrapper" @click.prevent.stop="closeBigQrCode">
            <div class="large-qr-wrapper-popup" @click.prevent.stop="noop">
                <div class="close-btn " @click="closeBigQrCode"><i class="fas fa-times"></i></div>
                <div class="qr-code-container">
                    <qr-code v-if="studentUrlSimple" :scale="15" :value="studentUrlSimple" class="qr-code-large"/>
                </div>
                <div v-if="studentUrlSimple" class="student-url-large">{{ studentUrlSimple }}</div>
                <div class="popup-emoji-code-container">
                    <emoji-code :active-emoji-index="-1" :auto-width="true" :show-triangle="false" type="large" />
                </div>
            </div>
        </div>

        <div v-if="showHelp" class="large-help-wrapper" @click.prevent.stop="closeHelp">
            <div class="large-help-wrapper-popup" @click.prevent.stop="noop">
                <div class="close-btn " @click="closeHelp"><i class="fas fa-times"></i></div>
                <div class="help-container">
                    <no-drag-image class="toggle-tick-image" :src="helpImage"/>
                </div>
            </div>
        </div>

        <div v-if="popupText" class="popup-wrapper-container" @click.prevent.stop="closePopup">
            <div class="popup-wrapper" @click.prevent.stop="noop">
                <div class="close-btn " @click="closePopup"><i class="fas fa-times"></i></div>
                <div class="popup-contents">
                    <h1 v-if="popupHeadline" class="text-center">{{ popupHeadline }}</h1>
                    <p>{{ popupText }}</p>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import * as extend from 'extend'

import SelectLanguagePage from './flow/0-select-language'
import LoginPage from './flow/0-login'
import ConnectPage from './flow/0-connect'
import IntroPage from './flow/1-intro'
import SelectThemePage from './flow/2-select-theme'
import SelectCasePage from './flow/3-select-case'
import LobbyPage from './flow/4-lobby'
import PageController from './flow/5-page-controller'

import FullscreenToggleButton from '@/components/fullscreen-toggle-button'
import EmojiCode from '@/components/emoji-code'
import PlayerType from '@/enum/PlayerType'
import GameClientEvent from '@/enum/GameClientEvent'
import GamePhase from '@/enum/GamePhase'
import GameType from '@/enum/GameType'
import GameClientType from '@/enum/GameClientType'
import {ConsoleLogger} from '@/console-logger'
import {$eBus} from '@/event-bus'
import AppConfig from '@/app-config'
import {mapGetters} from 'vuex'

import * as MutationTypes from '@/store/mutation-types'
import {getTeacherUrl} from '@/utils/UrlUtil'
import Breadcrumb from '@/components/breadcrumb'
import QrCode from '@/components/qr-code/index'
import RoomJoinerMixin from '@/components/mixins/RoomJoinerMixin'
import { getStudentUrl } from '../../utils/UrlUtil'
import AppButton from '@/components/app-button'

const pageComponents = {
    'select-language': SelectLanguagePage,
	'login': LoginPage,
	'connect': ConnectPage,
	'intro': IntroPage,
	'select-theme': SelectThemePage,
	'select-case': SelectCasePage,
	'lobby': LobbyPage,
	'pages': PageController,
}

const components = {
	Breadcrumb,
	EmojiCode,
	FullscreenToggleButton,
	QrCode,
    AppButton,
}

const helpImagesLocalized = {
    cn: require('@img/localized-assets/cn/help.png'),
    cs: require('@img/localized-assets/cs/help.png'),
    da: require('@img/localized-assets/da/help.png'),
    de: require('@img/localized-assets/de/help.png'),
    en: require('@img/localized-assets/en/help.png'),
    es: require('@img/localized-assets/es/help.png'),
    fr: require('@img/localized-assets/fr/help.png'),
    pt: require('@img/localized-assets/pt/help.png'),
};

export default {
	mixins: [
		RoomJoinerMixin,
	],
	components: extend(components, pageComponents),
	data() {
		return {
			componentTransition: AppConfig.componentTransition,
			actualCurrentPage: 'connect',
			isLoggedIn: false,
			logger: new ConsoleLogger('TeacherController', 'background: #00aaaa; color: white', null,
				!AppConfig.debug.teacherController),
		}
	},
	watch: {
		'$route'(to, from) {
			this.reloadCurrentPage(to)
		},
	},
	computed: {
		...mapGetters({
            language: 'getLanguage',
			breadcrumbTo: 'getBreadcrumbTo',
			currentTheme: 'getCurrentTheme',
			currentCase: 'getCurrentCase',
			showBigQrCode: 'getShowBigQrCode',
            showHelp: 'getShowHelp',
			popupText: 'getPopupText',
			popupHeadline: 'getPopupHeadline',
		}),
        helpImage() {
		    if (this.language) {
		        return helpImagesLocalized[this.language];
            } else {
		        return helpImagesLocalized.en;
            }
        },
        showLanguageSelector() {
            const useLanguageSelector = process.env.hasOwnProperty('VUE_APP_USE_LANGUAGE_SELECTOR') ?
                parseInt(process.env.VUE_APP_USE_LANGUAGE_SELECTOR, 10) > 0 : false;

		    return useLanguageSelector && !this.language;
        },
		currentPage() {
		    if (!this.language) {
		        return 'select-language';
            } else if (this.isLoggedIn) {
				return this.actualCurrentPage
			} else {
				return 'login'
			}
		},
		currentCaseTitle() {
			if (!this.currentCase) {
				return
			}

			return this.currentCase.title
		},
		studentUrl() {
			return AppConfig.studentUrl
		},
		studentUrlSimple() {
			return AppConfig.studentUrlSimple
		},
	},
	methods: {
		onRoomStateChanged(room) {
			let roomState = room && room.state ? room.state : {}

			console.log('onRoomStateChanged', roomState)

			if (roomState.hasOwnProperty('currentPageIndex') && roomState.currentPageIndex >= 0) {
				this.$store.commit(MutationTypes.SET_CURRENT_PAGE_INDEX, roomState.currentPageIndex)
			}

			if (roomState.hasOwnProperty('pagePhase')) {
				this.$store.commit(MutationTypes.SET_CURRENT_PAGE_PHASE, roomState.pagePhase)
			}

			if (roomState.hasOwnProperty('playerVotes')) {
				roomState.playerVotes.forEach((value, key) => {
					let votes = []

					value.votes.forEach((_value, _key) => {
						votes.push(_value)
					})

					console.log('playerVotesEach', key, votes)
				})
			}
		},
		onPhaseChanged(newPhase) {

			console.log('newPhase and all that', newPhase, GamePhase.End)
			this.$store.commit(MutationTypes.SET_CURRENT_GAME_PHASE, newPhase)

			if (newPhase === GamePhase.Lobby) {
				this.logger.logMessage(`Going to Lobby as the GameClient said that's the current phase`)
				this.$store.dispatch({type: 'reset'})
				this.$router.push(getTeacherUrl('lobby'))
			} else if (newPhase === GamePhase.Pages) {
				this.postSession()
				this.logger.logMessage(`Going to Pages as the GameClient said that's the current phase`)
				this.$router.push(getTeacherUrl('pages'))
			} else if (newPhase === GamePhase.End) {
				this.$store.dispatch({type: 'reset'})

				this.connectTeacherToNewRoom().then(() => {
					this.$router.push(getTeacherUrl('select-theme'))
				})
			}
		},
		postSession() {
			const caseId = this.$store.state.currentCase.id
			const language = this.$store.state.language
			const data = {}
			const session = {
				caseId: caseId,
				language: language,
				data: data,
			}
			this.$apiClient.postSession(session, (data) => {
				this.$store.commit(MutationTypes.SET_SESSION_ID, data.id)
			}, () => {
			})
		},
		reloadCurrentPage(route) {
			let currentPage = route.params.page

			console.log('reloadCurrentPage', currentPage)

			if (route.params.caseId) {
				let caseId = parseInt(route.params.caseId, 10)

				if (caseId) {
					//this.$store.commit(MutationTypes.SET_CLASS_CASE_ID, caseId);
				}
			}

			if (!currentPage || !components.hasOwnProperty(currentPage)) {
				this.logger.logMessage(`Unknown TeacherPage "${currentPage}"`)

				this.$router.push(getTeacherUrl('connect'))

				return
			}

			this.logger.logMessage(`TeacherPage changed to "${currentPage}"`)

			console.log('currentpage', currentPage)

			this.actualCurrentPage = currentPage
		},
		clickSecretButtonAdmin() {
			this.$store.commit(MutationTypes.SET_NAVIGATION_GUARD_ENABLED, false)

			this.$nextTick(() => {
				this.$router.push('/admin/index')
			})
		},
		clickSecretButtonStudent() {
			this.$store.commit(MutationTypes.SET_NAVIGATION_GUARD_ENABLED, false)

			this.$nextTick(() => {
				this.$router.push(getStudentUrl('index'));
			})
		},
		noop() {
			// do nothing
		},
        closeHelp() {
            this.$store.commit(MutationTypes.SET_SHOW_HELP, false)
        },
        closeBigQrCode() {
            this.$store.commit(MutationTypes.SET_SHOW_BIG_QR_CODE, false)
        },
		closePopup() {
			this.$store.commit(MutationTypes.SET_POPUP_TEXT, null)
			this.$store.commit(MutationTypes.SET_POPUP_TEXT, null)
		}
	},
	mounted() {
		this.$store.commit(MutationTypes.SET_PLAYER_TYPE, PlayerType.Teacher)
		this.$store.commit(MutationTypes.SET_GAME_CLIENT_TYPE, GameClientType.Online)
		this.$store.commit(MutationTypes.SET_GAME_TYPE, GameType.ClassRoom)

		$eBus.$on('gameClient:onDisconnected', () => {
			console.log('GAME CLIENT ON DISCONNECTED!!11!!')
		})

		$eBus.$on(GameClientEvent.RoomStateChange, this.onRoomStateChanged)
		$eBus.$on(GameClientEvent.PhaseChanged, this.onPhaseChanged)

		//document.addEventListener('scroll', this.onScroll)

		this.reloadCurrentPage(this.$router.currentRoute)
	},
	beforeDestroy() {
		$eBus.$off(GameClientEvent.RoomStateChange, this.onRoomStateChanged)
		$eBus.$off(GameClientEvent.PhaseChanged, this.onPhaseChanged)
		//document.removeEventListener('scroll', this.onScroll)
	},
}
</script>
<style lang="less" scoped>
@import "~@less/variables.less";

.teacher-container {
	position: relative;
}

.top-bar {
	transition: background-color 0.3s ease-out;

	&.scrolling {
		background-color: rgba(0, 0, 0, 0.9);
	}

	& > * {
		// max-height: 50px;
		top: 10px;
		right: 10px;
	}

	.logo {
		position: absolute;
	}
}

.theme-name {
	font-weight: 700;
}

.theme-name-big {
	color: @white;
	font-size: @font-size-xxxxl;
	width: 57%;
}

.teacher-page {
	position: absolute;
	top: 50px;
	left: 0;
	width: 100%;
	padding-top: 0px;
}

.logo-secret-button-admin {
	display: inline-block;
	width: 10px;
	height: 10px;
	position: fixed;
	top: 15px;
	right: 100px;
	background: rgba(255, 255, 255, 0.01);
	border-radius: 5px;
	cursor: pointer;
}

.logo-secret-button-student {
	display: inline-block;
	width: 15px;
	height: 15px;
	position: fixed;
	top: 44px;
	right: 70px;
	background: rgba(255, 255, 255, 0.01);
	border-radius: 10px;
	cursor: pointer;
}

.large-help-wrapper {
    padding: 30px;
}

.large-qr-wrapper, .popup-wrapper-container, .large-help-wrapper {
	z-index: 1000;
	position: fixed;
	background: rgba(0, 0, 0, 50%);
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;

	.large-qr-wrapper-popup {
		height: 600px;
		width: 600px;
	}

    .large-help-wrapper-popup {
        height: 100%;
        width: 75%;
        max-height: 100% !important;
        max-width: 100% !important;
        padding: 0 !important;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .help-container {
        width: 100%;
        height: 100%;
    }

	.popup-contents {
		height: auto;
		width: 800px;
	}

    .hlp-container {
        width: 100%;
        height: 100%;
    }

	.large-qr-wrapper-popup, .popup-contents, .large-help-wrapper-popup {
		position: absolute;
		left: 50%;
		top: 50%;
		max-height: 70%;
		max-width: 90%;
		transform: translate(-50%, -50%);
		background-color: white;
		text-align: center;
		padding: 35px;
	}

	.qr-code-container {
		width: 60%;
		height: 60%;
		margin: 30px auto 0 auto;
	}

	.close-btn {
		cursor: pointer;
		font-size: @font-size-xxl;
		position: absolute;
		right: 25px;
		top: 10px;
        z-index: 10000;

		:hover {
			transform: scale(1.1);
		}
	}
}

.popup-emoji-code-container {
	position: absolute;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
}

.background {
    .gradient {
        background-color: @white;
        //background-color: #20bf55;
        //background-image: linear-gradient(230deg, #20bf55 0%, #01baef 74%);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        opacity: 0.1;

    }

    .case-image-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    .case-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 110%;
        height: 110%;
        background-size: cover;
        background-position: center center;
    }
}
</style>
