<template>
	<div class="dev-wrapper">

		<div class="ui">
			<div>
				<strong>Current point:</strong> <span v-text="point.x + ', ' + point.y"/>
			</div>
			<div>
				<button v-if="!interactive" class="btn btn-sm btn-primary" @click="interactive = true">Make
					interactive
				</button>
				<button v-if="interactive" class="btn btn-sm btn-primary" @click="interactive = false">Make non
					interactive
				</button>
			</div>
		</div>
		<interactive-weight-map
			:interactive="interactive"
            :isFirst="true"
			:point="point"
            :momentType="moment"
            :momentsHiddenInitially="false"
            tokenType="bothSameTime"
			@interactiveChanged="onInteractiveChanged"
			@pointChanged="onPointChanged"
		/>
	</div>
</template>

<script>
import InteractiveWeightMap from '../page-components/weight-map/interactive-weight-map'

export default {
	name: 'weight-map-tester',
	components: {InteractiveWeightMap},
	data() {
		return {
			point: {
				x: -1,
				y: -1,
			},
			interactive: true,
			moment: 'I was late to an important meeting. On my way I passed a group of workers, and noticed a fault in a work routine. I stayed to help them out.',
			momentId: 14,
		}
	},
	methods: {
		onPointChanged(point) {
			this.point.x = point.x
			this.point.y = point.y
		},
		onInteractiveChanged(interactive) {
			this.interactive = interactive
		},
	},
}
</script>

<style lang="less" scoped>

.ui {
	z-index: 99999999;
	position: fixed;
	top: 0;
	padding: 10px;
	right: 0;
	color: white;
	background-color: rgba(0, 0, 0, 0.7);
}

</style>
