<script>
import Vue from 'vue'
import axios from 'axios'
import { ConsoleLogger } from '@/console-logger'
import AppConfig from '@/app-config'
import MediaManager from '@/media-manager'
import { CurrentUrlInfo } from '@/current-url-info'
import { mapGetters } from 'vuex'

export default {
    name: 'api-client',
    data () {
        return {
            logger: new ConsoleLogger('ApiClient', 'background: #0000aa; color: white', null, !AppConfig.debug.api),
            http: null,
            lastResponse: null,
        }
    },
    computed: {
        ...mapGetters({
            userToken: 'getUserToken',
        }),
    },
    created () {
        if (!Vue.prototype.$apiClient) {
            Object.defineProperty(Vue.prototype, '$apiClient', { value: this })

            this.logger.logMessage(`Assigned to Vue.$apiClient`)
        }

        let currentUrlInfo = CurrentUrlInfo.instance()

        let host = AppConfig.colyseusHost || currentUrlInfo.getCurrentHost()
        let port = AppConfig.colyseusPort || currentUrlInfo.getCurrentPort()

        let apiEndPoint = host + ':' + port

        let baseUrl = (AppConfig.secureApiEndPoint ? 'https' : 'http') + '://' + apiEndPoint

        let baseUrlApi = baseUrl + '/api/'

        this.logger.logMessage(`Set API base URL to ${baseUrlApi}`)

        MediaManager.setup(baseUrl)

        this.http = axios.create({
            timeout: 5000,
        })

        this.http.defaults.baseURL = baseUrlApi
    },
    methods: {
        noopIfNull (callback) {
            if (callback) {
                return callback
            }
            else {
                return function () {
                }
            }
        },
        request (type, endPoint, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            let typePrint = type.toUpperCase()

            this.logger.logMessage(`${typePrint} ${endPoint}`)

            this.http[type](endPoint).then((response) => {
                this.lastResponse = response

                if (response && response.hasOwnProperty('data')) {
                    this.logger.logMessage(`${typePrint} ${endPoint} | SUCCESS`)

                    if (onResolve) {
                        onResolve(response.data)
                    }
                }
                else {
                    this.logger.logMessage(`${typePrint} ${endPoint} | ERROR | Could not find any data in response`)

                    onReject('Could not find any data in response')
                }
            }).catch((error) => {
                if (error.response) {
                    this.lastResponse = error.response
                }
                else {
                    this.lastResponse = null
                }

                this.logger.logMessage(`${typePrint} ${endPoint} | ERROR | Could not execute request: ${error}`)

                onReject(`Could not execute request: ${error}`)

                if (AppConfig.throwApiError) {
                    throw error
                }
            })
        },
        getBaseUrl () {
            return this.http.defaults.baseURL.replace(/[\/]+$/, '')
        },
        getFullUrl (path) {
            return this.getBaseUrl().replace(/\/+$/, '') + '/' + path.replace(/^\/+/, '')
        },
        login (username, password, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            let formData = new FormData()
            formData.set('username', username)
            formData.set('password', password)
            this.http.post(this.getFullUrl('user/authenticate'), formData).then((response) => {
                console.log(response)
                this.http.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token
                onResolve(response.data)
            }).catch((error) => {
                console.log('reject username', error)
                onReject('failure')
            })
        },
        logout () {
            this.http.defaults.headers.common['Authorization'] = ''
        },
        verify (onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            this.http.get(this.getFullUrl('user/verify')).then((response) => {
                onResolve(response.data.user)
            }).catch(() => {
                onReject()
            })
        },
        copyTheme (themeId, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            this.http.post(this.getFullUrl('copy/' + themeId)).then((response) => {
                onResolve(response.data)
            }).catch(() => {
                onReject()
            })
        },
        getThemes (language, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            this.request('get', `/theme/${language}`, (data) => {
                if (data.themes) {
                    onResolve(data.themes)
                }
                else {
                    onReject('noThemes')
                }
            }, onReject)
        },
        getAllThemes (onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            this.request('get', `/theme`, (data) => {
                if (data.themes) {
                    onResolve(data.themes)
                }
                else {
                    onReject('noThemes')
                }
            }, onReject)
        },
        getTheme (themeId, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            this.request('get', `/theme/${themeId}`, (data) => {
                if (data.theme) {
                    onResolve(data.theme)
                }
                else {
                    onReject(`noTheme`)
                }
            }, onReject)
        },
        postTheme (theme, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            let formData = new FormData()
            formData.set('theme', JSON.stringify(theme))
            this.http.post(this.getFullUrl('theme'), formData).then((response) => {
                onResolve(response.data)
            }).catch((error) => {
                onReject()
            })
        },
        deleteTheme (themeId, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            this.http.delete(this.getFullUrl('theme/' + themeId)).then((response) => {
                onResolve(response.data)
            }).catch((error) => {
                onReject()
            })
        },
        updateTheme (theme, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            let formData = new FormData()
            formData.set('theme', JSON.stringify(theme))
            this.http.put(this.getFullUrl('theme/' + theme.themeId), formData).then((response) => {
                onResolve(response.data)
            }).catch((error) => {
                onReject()
            })
        },
        getCase (caseId, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            this.request('get', `/case/${caseId}`, (data) => {
                if (data.case) {
                    onResolve(data.case)
                }
                else {
                    onReject('caseEmpty')
                }
            }, onReject)
        },
        getThemeCases (themeId, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            return this.request('get', `/theme/${themeId}/case`, (data) => {
                if (data.cases) {
                    onResolve(data.cases)
                }
                else {
                    onResolve('noCases')
                }
            }, onReject)
        },
        getPublishedThemeCases (themeId, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            return this.request('get', `/theme/${themeId}/case/published`, (data) => {
                if (data.cases) {
                    onResolve(data.cases)
                }
                else {
                    onResolve('noCases')
                }
            }, onReject)
        },
        postCase (themeId, _case, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            let formData = new FormData()
            formData.set('case', JSON.stringify(_case))
            this.http.post(this.getFullUrl('theme/' + themeId + '/case'), formData).then((response) => {
                onResolve(response.data)
            }).catch((error) => {
                onReject()
            })
        },
        deleteCase (caseId, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            this.http.delete(this.getFullUrl('case/' + caseId)).then((response) => {
                onResolve(response.data)
            }).catch((error) => {
                onReject()
            })
        },
        updateCase (_case, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            let formData = new FormData()
            formData.set('case', JSON.stringify(_case))
            this.http.put(this.getFullUrl('case/' + _case.caseId), formData).then((response) => {
                onResolve(response.data)
            }).catch((error) => {
                onReject()
            })
        },
        updateCaseState (_case, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            let formData = new FormData()
            formData.set('case', JSON.stringify(_case))
            this.http.put(this.getFullUrl('case/' + _case.caseId + '/state'), formData).then((response) => {
                onResolve(response.data)
            }).catch((error) => {
                onReject()
            })
        },
        getPage (pageId, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            this.request('get', `/page/${pageId}`, (data) => {
                if (data.page) {
                    onResolve(data.page)
                }
                else {
                    onReject('no page')
                }
            }, onReject)
        },
        getCasePages (caseId, type, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            return this.request('get', `/case/${caseId}/page/${type}`, (data) => {
                if (data.pages) {
                    onResolve(data.pages)
                }
                else {
                    onResolve('noPages')
                }
            }, onReject)
        },
        postPage (caseId, page, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            let formData = new FormData()
            formData.set('page', JSON.stringify(page))
            this.http.post(this.getFullUrl('case/' + caseId + '/page'), formData).then((response) => {
                onResolve(response.data)
            }).catch((error) => {
                onReject()
            })
        },
        updatePage (page, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            let formData = new FormData()
            formData.set('page', JSON.stringify(page))
            this.http.put(this.getFullUrl('page/' + page.id), formData).then((response) => {
                onResolve(response.data)
            }).catch((error) => {
                onReject()
            })
        },
        updatePages (caseId, pages, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            let formData = new FormData()
            formData.set('pages', JSON.stringify(pages))
            this.http.put(this.getFullUrl('case/' + caseId + '/pages'), formData).then((response) => {
                onResolve(response.data)
            }).catch((error) => {
                onReject()
            })
        },
        deletePages (pageIds, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            let promises = []

            for (let pageId of pageIds) {
                promises.push(this.http.delete(this.getFullUrl('page/' + pageId)))
            }

            Promise.all(promises).then((response) => {
                onResolve(pageIds)
            }).catch((error) => {
                onReject()
            })
        },
        deletePage (pageId, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            this.http.delete(this.getFullUrl('page/' + pageId)).then((response) => {
                onResolve(response.data)
            }).catch((error) => {
                onReject()
            })
        },
        getImages (onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            this.request('get', `/media`, (data) => {
                if (data.images) {
                    onResolve(data.images)
                }
                else {
                    onResolve('no images')
                }
            }, onReject())
        },
        getLocalImages (language, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            this.request('get', `/media/${language}`, (data) => {
                if (data.images) {
                    onResolve(data.images)
                }
                else {
                    onResolve('no local images')
                }
            }, onReject)
        },
        postImage (image, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            let formData = new FormData()
            formData.set('image', image)
            this.http.post(this.getFullUrl('media'), formData).then((response) => {
                onResolve(response.data)
            }).catch(onReject)
        },
        deleteImage (mediaId, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            this.http.delete(this.getFullUrl('media/' + mediaId)).then((response) => {
                onResolve(response.data)
            }).catch(onReject)
        },
        getSessions (onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            this.request('get', `/session`, (data) => {
                if (data.sessions) {
                    onResolve(data.sessions)
                }
                else {
                    onResolve('no sessions')
                }
            }, onReject)
        },
        getSessionsByLanguage (language, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            this.request('get', `/session/${language}`, (data) => {
                if (data.sessions) {
                    onResolve(data.sessions)
                }
                else {
                    onResolve('no sessions')
                }
            }, onReject)
        },
        postSession (session, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            let formData = new FormData()
            formData.set('session', JSON.stringify(session))
            this.http.post(this.getFullUrl('session'), formData).then((response) => {
                onResolve(response.data)
            }).catch(onReject)
        },
        updateSession (sessionId, data, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            let formData = new FormData()
            formData.set('data', JSON.stringify(data))
            this.http.put(this.getFullUrl('session/' + sessionId), formData).then((response) => {
                onResolve(response.data)
            }).catch((error) => {
                onReject()
            })
        },
        isRoomJoinable (roomId, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            this.request('get', `/room/${roomId}/joinable`, (data) => {
                if (data.joinable) {
                    onResolve()
                }
                else {
                    onReject('notJoinable:' + (data.error || 'Unknown error'))
                }
            }, onReject)
        },
        getAvailableRoomId (onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            this.request('get', `/room/availableId`, (data) => {
                if (data.roomId) {
                    onResolve(data.roomId)
                }
                else {
                    onReject('noRoomId')
                }
            }, onReject)
        },
        getClassInfo (guid, caseId, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            if (caseId) {
                let data = {
                    caseId: caseId,
                }

                this.http.put(this.getFullUrl(`class/${guid}/case`), data).then((response) => {
                    this.request('get', `/class/${guid}`, (data) => {
                        onResolve(data.classInfo)
                    }, onReject)
                }).catch((error) => {
                    onReject()
                })
            }
            else {
                this.request('get', `/class/${guid}`, (data) => {
                    onResolve(data.classInfo)
                }, onReject)
            }
        },
        getUserClasses (userToken, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            let data = {
                userToken: userToken,
            }

            this.http.put(this.getFullUrl(`user/classes`), data).then((response) => {
                onResolve(response.data.userInfo)
            }).catch((error) => {
                onReject(error)
            })
        },
        getUserClassInfo (classId, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            this.http.get(this.getFullUrl(`user/class/` + classId)).then((response) => {
                onResolve(response.data.classInfo)
            }).catch((error) => {
                onReject(error)
            })
        },

        startClassSession (classId, caseId, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            let data = {
                classId: classId,
                caseId: caseId,
                userToken: this.userToken,
            }

            this.http.put(this.getFullUrl(`class-session`), data).then((response) => {
                //console.log('response', response);
                onResolve(response.data.session)
            }).catch((error) => {
                //console.log('error', error);
                onReject(error)
            })
        },
        endClassSession (sessionUid, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            let data = {
                userToken: this.userToken,
            }

            this.http.put(this.getFullUrl(`class-session/${sessionUid}/end`), data).then((response) => {
                onResolve(response.data.session)
            }).catch((error) => {
                onReject(error)
            })
        },
        getClassSession (sessionUid, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            this.request('get', `/class-session/${sessionUid}`, (data) => {
                console.log('response getClassSession', data)
                if (data.session) {
                    onResolve(data.session)
                }
                else {
                    onReject('session')
                }
            }, onReject)
        },

        updateClassUserInfo (userId, data, onResolve = null, onReject = null) {
            onResolve = this.noopIfNull(onResolve)
            onReject = this.noopIfNull(onReject)

            this.http.put(this.getFullUrl(`user/info/` + userId), data).then((response) => {
                onResolve(response.data)
            }).catch((error) => {
                onReject(error)
            })
        },
    },
    render: function (h) {
        return h() // avoid warning message
    },
}
</script>
