var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.selected,
          expression: "selected",
        },
      ],
      staticClass: "form-control case-state-selector",
      class: {
        "case-draft": _vm.selected == "draft",
        "case-published": _vm.selected == "published",
        "case-archived": _vm.selected == "archived",
      },
      on: {
        change: [
          function ($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function (o) {
                return o.selected
              })
              .map(function (o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.selected = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          },
          function ($event) {
            return _vm.onSelect()
          },
        ],
      },
    },
    [
      _c("option", { attrs: { value: "draft" } }, [_vm._v("Draft")]),
      _c("option", { attrs: { value: "published" } }, [_vm._v("Published")]),
      _c("option", { attrs: { value: "archived" } }, [_vm._v("Archived")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }