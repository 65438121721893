<template>
    <div class="container" v-if="!teacherSkipIntro">
        <div class="intro-page">

            <div v-if="introPageIndex === 0">
                <div class="background">
                    <div class="gradient"/>
                    <div class="case-image-container">
                        <img class="case-image" src="@/_assets/img/misc/soil.jpg"/>
                    </div>
                </div>
                <div class="content">
                    <div class="blue-band">
                        <h1 class="mb-4 ws-pre">{{ $t('intro.title') }}</h1>
                        <app-button
                            class="py-4"
                            v-on:onClick="onContinue"
                        >
                            {{ $t('misc.start') }}
                        </app-button>
                    </div>
                </div>
            </div>
            <div v-if="introPageIndex === 1" class="intro-container">
                <h1>{{ $t('intro.cultureHeadline' )}}</h1>
                <p class="cursive centered bold">{{ $t('intro.cultureParagraph' )}}</p>
                <div class="image-container image-container-1 text-center">
                    <no-drag-image src="@/_assets/img/video-thumbnail.jpg"/>
                </div>
                <!--<div class="video-container">
                    <video-player thumbnail-source="@/_assets/img/video-thumbnail.png"
                                  video-source="@/_assets/videos/intro-video.mp4"/>
                </div>-->

            </div>

            <div v-if="introPageIndex === 2" class="intro-container">
                <h1>{{ $t('intro.whyUseHeadline' )}}</h1>
                <p>{{ $t('intro.whyUseParagraph1' )}}</p>
                <p>{{ $t('intro.whyUseParagraph2' )}}</p>
            </div>

            <div v-if="introPageIndex === 3" class="intro-container">
                <vue-markdown>{{ $t('intro.whenToUse') }}</vue-markdown>
            </div>
            <div v-if="!isIntro && (showBackButton || showNextButton)" class="button-wrapper">
                <div class="button-container">
                    <app-button
                        v-if="showBackButton"
                        class="py-4 intro-btn"
                        v-on:onClick="onBack"
                    >
                        {{ $t('misc.back') }}
                    </app-button>
                    <app-button
                        v-if="showNextButton"
                        class="py-4 intro-btn"
                        v-on:onClick="onContinue"
                    >
                        {{ $t('misc.next') }}
                    </app-button>

                </div>
            </div>


        </div>
    </div>
</template>

<script>
import AppButton from '@/components/app-button'
import Media from '@/components/media'
import { getTeacherUrl } from '@/utils/UrlUtil'
import * as MutationTypes from '@/store/mutation-types'
import VueMarkdown from 'vue-markdown'
import IntroPhase from '@/enum/IntroPhase'
import VideoPlayer from '../../../video-player'
import { mapGetters } from 'vuex'
import NoDragImage from '../../../client-components/sgi/NoDragImage'

export default {
    components: {
        VideoPlayer,
        AppButton,
        Media,
        VueMarkdown,
        NoDragImage,
    },
    mounted () {
        this.$store.commit(MutationTypes.SET_BREADCRUMB_TO, '/')
    },
    beforeMount () {
        if (this.teacherSkipIntro) {
            this.continueToNextFlowScene()
        }
    },
    data () {
        return {
            introPageIndex: 0,
        }
    },
    methods: {
        continueToNextFlowScene () {
            this.$router.push(getTeacherUrl('select-theme'))
        },
        onContinue () {
            if (this.introPageIndex === IntroPhase.Intro) {
                this.introPageIndex = IntroPhase.Videos
            }
            else if (this.introPageIndex === IntroPhase.Videos) {
                this.introPageIndex = IntroPhase.Explanation1
            }
            else if (this.introPageIndex === IntroPhase.Explanation1) {
                this.introPageIndex = IntroPhase.Explanation2
            }
            else if (this.introPageIndex === IntroPhase.Explanation2) {
                this.finish()
            }
        },
        onBack () {
            if (this.introPageIndex === IntroPhase.Explanation2) {
                this.introPageIndex = IntroPhase.Explanation1
            }
            else if (this.introPageIndex === IntroPhase.Explanation1) {
                this.introPageIndex = IntroPhase.Videos
            }
            else if (this.introPageIndex === IntroPhase.Videos) {
                this.introPageIndex = IntroPhase.Intro
            }
        },
        finish () {
            this.$store.commit(MutationTypes.SET_TEACHER_SKIP_INTRO, true)

            this.continueToNextFlowScene()
        },
    },
    computed: {
        ...mapGetters({
            teacherSkipIntro: 'getTeacherSkipIntro',
        }),
        showNextButton () {
            // return this.introPageIndex !== IntroPhase.Explanation2;
            return true
        },
        showBackButton () {
            return this.introPageIndex !== IntroPhase.Intro
        },
        isIntro () {
            return this.introPageIndex === IntroPhase.Intro
        },
    },
}
</script>

<style lang="less" scoped>

@import "~@less/variables.less";
@import "~@less/font-sizes.less";

.intro-page {
    margin: 0;
    padding: 0;
    float: none;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    overflow: auto;

    .button-wrapper {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 90px;
        z-index: 999;
        background: @color-light-grey;

        .button-container {
            position: absolute;
            top: 50%;
            width: fit-content;
            left: 50%;
            transform: translate(-50%, -50%);

            .intro-btn {
                margin: 10px;
            }
        }
    }


    .background {
        .gradient {
            background-color: @white;
            //background-color: #20bf55;
            //background-image: linear-gradient(230deg, #20bf55 0%, #01baef 74%);
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 1;
            opacity: 0.1;

        }

        .case-image-container {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
        }

        .case-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 110%;
            height: 110%;
            background-size: cover;
            background-position: center center;
        }
    }


    .content {
        z-index: 2;
        position: absolute;
        color: white;
        width: 100%;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);


        .blue-band {
            background: @color-purple;
            color: @white;
            bottom: 0;
            left: 0;
            width: 100%;
            padding-top: 80px;
            opacity: 0.8;

            h1 {
                font-size: 3.5em;
                text-align: center;
                width: 66%;
                margin-right: auto;
                margin-left: auto;
            }
        }

    }

}

.image-container {
    position: static;

    &.image-container-1 {
        height: 400px;
    }

    &.image-container-2 {
        text-align: right;
        height: 600px;
    }

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

</style>

<style lang="less">


.intro-container {
    width: 70%;
    margin: 100px auto 100px;
    text-align: left;

    .video-container {
        width: 50%;
        margin-right: auto;
        margin-left: auto;

        img {
            width: 100%;
        }

        #video-1 {

        }
    }

    ol {
        padding-left: 35px;

        li {
            padding-bottom: 5px;

            &::marker {
                font-weight: bold;
            }

            margin-bottom: 5px !important;
            font-weight: normal;
        }
    }

    p {
        font-weight: 400;
        margin-bottom: 35px;
        text-align: left;
    }

    .cursive {
        font-style: italic;
    }


    .centered {
        text-align: center !important;
    }

    .bold {
        font-weight: 700;
    }

    h1 {

        text-align: center;
        margin-right: auto;
        margin-left: auto;
    }

    .double-page-container {
        display: inline-flex;

        .double-page-text {
            width: 45%;
            text-align: left;
            margin-right: 5%;

            > p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .double-page-image {
            width: 45%;
            margin-left: 5%;

            img {
                width: 100%;
            }
        }
    }
}

.ws-pre {
    white-space: pre;
}
</style>
