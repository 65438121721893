<template>
	<div>
		<div v-if="showVote" class="container vote-container">
			<no-drag-image class="choice-background" src="@/_assets/img/misc/meeting-room.png"/>
			<div v-if="!(showUserVote && currentPagePhase === 1)" class="blue-overlay"/>
			<h1 v-if="currentPage.title">{{ currentPage.title }}</h1>
			<p v-if="choiceComponentOptions.text">{{ choiceComponentOptions.text }}</p>

			<div v-if="showUserVote && currentPagePhase === 1">
				<div class="user-input-field"><textarea v-model="userVote"
														:placeholder="momentType === 'moment' ? $t('misc.writeMomentPlaceholder') : $t('misc.writeDilemmaPlaceholder')"
														class="form-control"/>
				</div>
				<!--<div class="dabutton"><button class="btn btn-success" v-if="userVote" @click="addUserVote">Add</button></div>-->
				<div :class="(showUserVote && currentPagePhase === 1) ? 'opaque-bottom-bar' : ''"
					 class="bottom-bar-send-answer">
					<NotchHeader :color="'blue'"/>
					<app-button :enabled="userVote !== ''" v-on:click.native="addUserVote">
						{{ momentType === 'moment' ? $t('game.sendMoment') : $t('game.sendDilemma') }}
					</app-button>
                    <div class="timer-container" v-if="timerTimeTotal">
                        <timer-solid :time-remaining="timerTimeRemaining" :time-total="timerTimeTotal" :scale="0.5" />
                    </div>
				</div>
			</div>

			<div v-if="currentPagePhase !== 1">
				<choices
					:choice-type="choiceComponentOptions && choiceComponentOptions.choiceType ? choiceComponentOptions.choiceType : 'multipleChoice'"
					:instant-selection="false"
					:randomize="true"
					:selectable="true"
					class="choices"
					player-type="student"
					type="vertical"
					v-bind:choices="choices"
					v-bind:value="activeChoiceIndexes"
					v-on:value="activeChoiceIndexes = $event;"/>
				<div class="bottom-bar-send-answer">
					<NotchHeader :color="'blue-solid'"/>
					<app-button :enabled="canSendVote" v-on:click.native="onClickAnswer">{{
							$t('game.sendAnswer')
						}}
					</app-button>
                    <div class="timer-container" v-if="timerTimeTotal">
                        <timer-solid :time-remaining="timerTimeRemaining" :time-total="timerTimeTotal" :scale="0.5" />
                    </div>
				</div>
			</div>


		</div>
		<div v-else-if="showWeightMap" class="container weight-map-interactive-container">
			<interactive-weight-map
				:interactive="true"
				:isFirst="showWeightMapInput1"
				:momentType="momentType"
				:momentsHiddenInitially="momentsHiddenInitially"
				v-on:submit="onSubmit"/>
		</div>
		<div v-else>
			<div class="text-container">
				<NotchHeader :color="'blue'"/>
				<p class="case-info">
					{{ $t('game.stayTuned') }} <br><br>
					{{ $t('game.studentWaiting') }}
				</p>
			</div>
			<div class="case-image-container">
				<div :style="caseImageStyle" class="case-image">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PagePhase from '@/enum/PagePhase'
import {mapGetters} from 'vuex'
import LoadingIndicator from '@/components/loading-indicator'
import AppButton from '@/components/app-button'
import Choices from '@/components/choices'
import ThingToHtml from '../../../../lib/ThingToHtmlVue/thing-to-html'
import {getStudentUrl} from '@/utils/UrlUtil'
import MediaManager from '@/media-manager'
import * as MutationTypes from '@/store/mutation-types'
import {$eBus} from '@/event-bus'
import GameClientEvent from '@/enum/GameClientEvent'
import TimerSolid from '@/components/timer-solid/index'
import NotchHeader from '@/components/notch-header'
import WeightMap from '../../../page-components/weight-map/weight-map'
import InteractiveWeightMap from '../../../page-components/weight-map/interactive-weight-map'

export default {
	components: {
		InteractiveWeightMap,
		WeightMap,
		ThingToHtml,
		Choices,
		AppButton,
		LoadingIndicator,
		TimerSolid,
		NotchHeader,
	},
	data() {
		return {
			activeChoiceIndexes: [],
			sentVote: false,
			sentWeightVote1: false,
			sentWeightVote2: false,
			currentMediaUrl: null,

			timerTimeTotal: 0,
			timerTimeRemaining: 0,

			userVote: null,
		}
	},
	destroyed() {
		$eBus.$off(GameClientEvent.RoomMessage, this.onRoomMessage)
	},
	watch: {
		currentCasePage() {
			this.checkCurrentPageTimer()

			this.userVote = null
		},
		currentPagePhase(currentPagePhase) {
			if (currentPagePhase === PagePhase.Result) {
				// Reset sent vote
				this.sentVote = false
				this.sentWeightVote1 = false
				this.sentWeightVote2 = false
			} else if (currentPagePhase === PagePhase.WeightMapInput1 && this.choiceComponentOptions.listType === 'singleChoice') {
				this.sentWeightVote1 = false
				this.sentWeightVote2 = false
			}

			this.userVote = null

			this.reloadImageUrl()
		},
		currentPage(currentPage) {
			this.userVote = null

			this.reloadImageUrl()
		},
		showVote(showVote) {
			this.ensureBackgroundAccent()
		},
	},
	computed: {
		...mapGetters({
			currentTheme: 'getCurrentTheme',
			currentCase: 'getCurrentCase',
			currentGamePhase: 'getCurrentGamePhase',
			currentPagePhase: 'getCurrentPagePhase',
			currentPage: 'getCurrentPage',
		}),

		currentPageComponents() {
			if (!this.currentPage) {
				return null
			}

			if (!this.currentPage.components) {
				return null
			}

			return this.currentPage.components
		},
		//placeholderText () {
		//return this.momentType === 'moment' ? this.$t(misc.writeMomentPlaceholder) : this.$t(misc.writeDilemmaPlaceholder)
		//},
		leftComponentType() {
			if (!this.currentPageComponents) {
				return null
			}

			if (!this.currentPageComponents.left) {
				return null
			}

			return this.currentPageComponents.left.component
		},
		rightComponentType() {
			if (!this.currentPageComponents) {
				return null
			}

			if (!this.currentPageComponents.right) {
				return null
			}

			return this.currentPageComponents.right.component
		},
		momentType() {
			if (this.currentPageComponents.left.options.hasOwnProperty('momentType')) {
				return this.currentPageComponents.left.options.momentType
			}
			return null
		},
		momentsHiddenInitially() {
			if (this.currentPageComponents.left.options.hasOwnProperty('momentsHiddenInitially')) {
				return this.currentPageComponents.left.options.momentsHiddenInitially === 'yes';
			}
			return false;
		},

		isChoiceComponent() {
			return this.leftComponentType === 'Choices' || this.rightComponentType === 'Choices'
		},
		choiceComponentOptions() {
			if (this.leftComponentType === 'Choices') {
				return this.currentPageComponents.left.options
			} else if (this.rightComponentType === 'Choices') {
				return this.currentPageComponents.right.options
			}

			return null
		},
		showVote() {
			return this.isChoiceComponent &&
				(this.currentPagePhase === PagePhase.Normal || this.currentPagePhase === PagePhase.Voting) &&
				!this.sentVote
		},
		showWeightMapInput1() {
			return this.currentPagePhase === PagePhase.WeightMapInput1
		},
		showWeightMapInput2() {
			return this.currentPagePhase === PagePhase.WeightMapInput2
		},
		showWeightMap() {
			return this.isChoiceComponent && ((this.showWeightMapInput1 && !this.sentWeightVote1) ||
				(this.showWeightMapInput2 && !this.sentWeightVote2))
		},
		choices() {
			if (!this.choiceComponentOptions) {
				return []
			}

			return this.choiceComponentOptions.choices
		},
		canSendVote() {

			if (this.sentVote) {
				return false
			}

			if (this.activeChoiceIndexes.length <= 0) {
				return false
			}

			return true
		},
		caseImage() {
			if (!this.currentCase || !this.currentCase.image) {
				return
			}

			return MediaManager.getMediaUrl(this.currentCase.image)
		},
		caseImageStyle() {
			let caseImage = this.caseImage

			if (!caseImage) {
				return
			}

			return {
				'background-image': `url("${caseImage}")`,
			}
		},
		showUserVote() {
		    if (!this.choiceComponentOptions || !this.choiceComponentOptions.listType) {
		        console.log('Could not find choiceComponentOptions', this.choiceComponentOptions, this);

		        return false;
            }

			return this.isChoiceComponent && this.currentPagePhase === PagePhase.Normal &&
				this.choiceComponentOptions.listType === 'userAdded'
		},
	},
	methods: {
		addUserVote() {
			if (!this.userVote) {
				return
			}

			this.$gameClient.send({'addPlayerChoice': this.userVote})

			this.userVote = ''
		},
		ensureBackgroundAccent() {
			if (this.showWeightMapInput1 || this.showWeightMapInput2) {
				this.$store.commit(MutationTypes.SET_BACKGROUND_ACCENT, 'grey')
			} else {
				if (this.showVote) {
					this.$store.commit(MutationTypes.SET_BACKGROUND_ACCENT, 'white')
				} else {
					this.$store.commit(MutationTypes.SET_BACKGROUND_ACCENT, 'primary')
				}
			}
		},
		onSubmitChoice() {
			this.onClickAnswer()
		},
		reloadImageUrl() {
			let mediaUrl = null

			if (this.currentPageComponents && this.currentPagePhase === PagePhase.Normal) {
				for (let side in this.currentPageComponents) {
					let component = this.currentPage.components[side].component
					let componentOptions = this.currentPage.components[side].options

					if (component === 'Media') {
						if (componentOptions.media) {
							mediaUrl = this.$apiClient.baseUrl + '/uploads/' +
								componentOptions.media.replace(/^\/+/, '')

							break
						}
					}
				}
			}

			this.currentMediaUrl = mediaUrl
		},
		onClickAnswer() {
			if (!this.canSendVote) {
				return
			}

			this.$gameClient.send({
				currentVotes: this.activeChoiceIndexes,
			})

			this.sentVote = true
		},
		onSubmit(point) {
			if (this.showWeightMapInput1) {
				if (this.sentWeightVote1) {
					return
				}
				this.sentWeightVote1 = true

                point.voteType = 1;
			} else if (this.showWeightMapInput2) {
				if (this.sentWeightVote2) {
					return
				}
				this.sentWeightVote2 = true

                point.voteType = 2;
			} else {
			    point.voteType = 0;
            }

			this.$gameClient.send({
				weightMapVote: point,
			})
		},
		onPageChange() {
			this.ensureBackgroundAccent()
			console.log('onPageChange', this.currentPage)

			/*if (this.isChoiceComponent) {
			  this.$nextTick(() => {
			  setTimeout(() => {
				this.activeChoiceIndexes.push(1);

				this.onClickAnswer();
			  }, 1000);
			  })
			}*/
		},

        onTimerStart(timeTotal) {
            this.timerTimeTotal = timeTotal

            this.$store.commit(MutationTypes.SET_TIMER_TIME_TOTAL, timeTotal);
            this.$store.commit(MutationTypes.SET_TIMER_TIME_REMAINING, timeTotal);

            this.showTimer = true
        },
        onTimerTick(timeTotal, timeElapsed) {
            let timeRemaining = timeTotal - timeElapsed

            this.$store.commit(MutationTypes.SET_TIMER_TIME_TOTAL, timeTotal);
            this.$store.commit(MutationTypes.SET_TIMER_TIME_REMAINING, timeRemaining);

            this.timerTimeTotal = timeTotal
            this.timerTimeRemaining = timeRemaining
            this.showTimer = false;
        },
        onTimerComplete(timeTotal) {
            this.doStopTimer();
        },
        onTimerStop(timeTotal) {
            this.doStopTimer();
        },
        onTimerCancel() {
            this.doStopTimer();
        },
        doStopTimer() {
            this.timerTimeTotal = 0
            this.timerTimeRemaining = 0

            this.$store.commit(MutationTypes.SET_TIMER_TIME_TOTAL, 0);
            this.$store.commit(MutationTypes.SET_TIMER_TIME_REMAINING, 0);
        },
        onRoomMessage(room, message) {
            let regexMatch

            regexMatch = message.match(/^TimerEvent\.Start\:(\d+)$/)

            if (regexMatch) {
                this.onTimerStart(parseInt(regexMatch[1], 10))

                return
            }

            regexMatch = message.match(/^TimerEvent\.Tick\:(\d+):(\d+)$/)

            if (regexMatch) {
                this.onTimerTick(parseInt(regexMatch[1], 10), parseInt(regexMatch[2], 10))

                return
            }

            regexMatch = message.match(/^TimerEvent\.Stop\:(\d+)$/)

            if (regexMatch) {
                this.onTimerStop(parseInt(regexMatch[1], 10))

                return
            }

            regexMatch = message.match(/^TimerEvent\.Complete\:(\d+)$/)

            if (regexMatch) {
                this.onTimerComplete(parseInt(regexMatch[1], 10))

                return
            }

            regexMatch = message.match(/^TimerEvent\.Cancel$/)

            if (regexMatch) {
                this.onTimerCancel()

            }
        },
	},
	mounted() {
		console.log('FLOW STUDENT 2-GAME MOUNTED')
		let error = null

		if (!this.$gameClient) {
			error = 'Game client was empty'
		} else if (!this.$gameClient.isClientConnected()) {
			error = 'Game client not connected'
		} else if (!this.$gameClient.inRoom()) {
			error = 'Game client not in room'
		} else if (!this.currentCase) {
			error = 'No current case could be found'
		}

		if (error) {
			console.log('[STUDENT] Could not start game: ' + error)

			this.$router.push(getStudentUrl('connect'))

			return
		}

		this.onPageChange()

		$eBus.$on(GameClientEvent.RoomMessage, this.onRoomMessage)

		//this.checkCurrentPageTimer()
	},
}
</script>

<style lang="less" scoped>
@import "~@less/variables.less";

.dabutton {
	left: 50%;
	position: absolute;
	transform: translate(-50%, 0);
}

.center {
	position: absolute;
	top: 50%;
	text-align: center;
	width: 100%;
	left: 0;
	transform: translate(0, -50%);
	padding: 0 20px;
}

.user-input-field {
	top: 0;
	left: 0;
	position: fixed;
	height: 100%;
	width: 100%;

	padding: 30px 30px 130px 30px;

	.form-control {
		width: 100%;
		height: 100%;
	}
}


.answer-button-container {
	margin-top: 50px;
	text-align: center;
}

.choice-background {
	position: fixed;
	top: 0px;
	left: 0px;
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.blue-overlay {
	background-color: @color-purple;
	opacity: 0.8;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}

.media-container {
	img {
		max-width: 100%;
		max-height: 300px;
	}
}

.text-container {
	background-color: rgba(0, 18, 51, 0.8);
	position: fixed;
	bottom: 0;
	width: 100%;
	height: calc(54vh);
	z-index: 2;

	.info {
		margin: 0px auto 50px auto;
		z-index: 2;
	}
}


.case-image {
	width: auto;
	margin-bottom: 10px;
	background-size: cover;
	background-position: 10% center;
	background-color: #000000;
	height: 100%;
}

.case-image-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
}


p.case-info {
	font-size: @font-size-xxl;
	text-align: center;
	width: 90%;
	color: white;
	padding: 0 50px;
	font-weight: 500;
	// margin-top: 40px;
	bottom: 50%;
	right: 50%;
	position: absolute;
	transform: translate(50%, 40%);

}

.vote-container {
	width: auto;
	background-size: cover;
	background-position: center center;
	height: 100%;
	// background-image: url("../../assets/img/misc/meeting-room.png");
	padding-bottom: 150px;
}

.weight-map-interactive-container {
	padding: 0;
}

.bottom-bar-send-answer {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background: @color-purple;
	height: 90px;
	text-align: center;
	z-index: 99;

	.app-button-container {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -70%);
	}

}

.opaque-bottom-bar {
	background: rgba(0, 18, 51, 0.8) !important;
}

.timer-container {
    position: absolute;
    right: 20px;
    bottom: 20px;
}
</style>
