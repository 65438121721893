<template>
	<div :class="format === 'sendMessage' ? 'choice-message-center' : ''" class="choice-message">
		<div v-if="format === 'sendMessage'" class="inner-message">
			<p class="send-chat-message">{{ $t('game.sendMessage') }}</p>
			<vue-markdown :key="messageKey" class="chat-message">{{ message }}</vue-markdown>
		</div>
		<vue-markdown v-else :key="messageKey" class="inner-message">{{ message }}</vue-markdown>
	</div>
</template>

<script>
import VueMarkdown from 'vue-markdown'

export default {
	name: 'choice-message',
	props: {
		format: {
			type: String,
			required: true,
		},
		message: {
			type: String,
			required: true,
		},
		index: {
			type: Number,
			required: true,
		},
	},
	components: {
		VueMarkdown,
	},
	computed: {
		messageKey() {
			return this.format + '|' + this.message + '|' + this.index
		},
	},
}
</script>

<style lang="less">
@import "~@less/font-sizes.less";

.choice-message {
	font-size: 24px !important;
	overflow: hidden;
	min-height: 100px;
	position: relative;

	p {
		text-align: center;
		font-weight: 300;
		margin-bottom: 0px;
	}

	.inner-message {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		line-height: 100%;
	}

	&.choice-message-center {
		margin: 0 auto 0 auto;
	}

	.send-chat-message {
		margin-bottom: 5px;
	}

	.chat-message {
		background: #0084ff;
		color: white;
		padding: 10px;
		border-radius: 10px;
		text-align: center;
		margin: 0;
	}
}
</style>
