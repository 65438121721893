<template>
	<div class="container teacher-connect-container">
		<transition mode="out-in" name="fade">
			<div v-if="connecting" key="connecting" class="transition-root">
				<div class="row">
					<loading-indicator>{{ $t('room.connecting') }}..</loading-indicator>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import {$eBus} from '@/event-bus'
import GameClientEvent from '@/enum/GameClientEvent'
import LoadingIndicator from '@/components/loading-indicator/index'
import {CurrentUrlInfo} from '@/current-url-info'
import AppConfig from '@/app-config'
import * as MutationTypes from '@/store/mutation-types'
import {getTeacherUrl} from '@/utils/UrlUtil'
import {mapGetters} from 'vuex'
import RoomJoinerMixin from '@/components/mixins/RoomJoinerMixin'

export default {
	mixins: [
		RoomJoinerMixin,
	],
	components: {
		LoadingIndicator,
	},
	data() {
		let currentUrlInfo = CurrentUrlInfo.instance()

		let host = AppConfig.colyseusHost || currentUrlInfo.getCurrentHost()
		let port = AppConfig.colyseusPort
		let wsProtocol = AppConfig.colyseusSecure ? 'wss' : 'ws'

		return {
			connecting: true,
			connected: false,
			host: host,
			port: port,
			wsProtocol: wsProtocol,
		}
	},
	beforeDestroy() {
		$eBus.$off(GameClientEvent.Error, this.onClientError)
		$eBus.$off(GameClientEvent.Open, this.onClientCreated)
	},
	mounted() {
		$eBus.$on(GameClientEvent.Error, this.onClientError)
		$eBus.$on(GameClientEvent.Open, this.onClientCreated)

		this.$store.commit(MutationTypes.SET_BREADCRUMB_TO, null)

		this.connecting = true

		this.tryConnect()
	},
	computed: {
		...mapGetters({
			features: 'getFeatures',
		}),
	},
	methods: {
		tryConnect() {
			if (this.$gameClient) {
				this.connect()
			} else {
				console.log('Game client not yet found.. waiting till next tick and trying again!')

				this.$nextTick(() => {
					this.tryConnect()
				})
			}
		},
		connect() {
			this.$gameClient.connect(this.host, this.port, this.wsProtocol)
		},
		onClientError(client) {
			client.close()

			this.$store.commit(MutationTypes.SET_ERROR_ICON, 'connectionError')
			this.$store.commit(MutationTypes.SET_ERROR_MESSAGE, this.$t('room.serverError'))
			this.connecting = false
		},
		onClientCreated() {
			this.connected = true

			$eBus.$off(GameClientEvent.Error, this.onClientError)
			$eBus.$off(GameClientEvent.Open, this.onClientCreated)

			// Look up case info
			if (this.features.autoJoin) {
				if (this.features.dilemma && this.features.roleplay) {
					throw new Error(
						'You cannot run auto create rooms with both dilemma and roleplay as the game needs to know which of them is used')
				}

				this.connectTeacherToNewRoom().then(() => {
					console.log('success!')
				})
			} else {
				let promise

				if (AppConfig.forceRoomCode) {
					promise = this.connectTeacherToRoomCode(AppConfig.forceRoomCode)
				} else {
					promise = this.connectTeacherToNewRoom()
				}

				promise.then(() => {
					console.log('going TO SELECT THEME')
					this.$router.push(getTeacherUrl('intro'))
					//this.$router.push(getTeacherUrl('select-theme'))
				})
			}
		},
	},
}
</script>

<style lang="less">

</style>
