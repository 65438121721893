<template>
	<div
		:class="['emoji-code-' + type, 'emoji-code-border-' + (border ? 'true' : 'false'), emojiImages[intCode - 1] ? 'no-border' : '']"
		class="emoji-code">
		<i v-if="iconType === 'icon'" :class="emojiIconClasses[intCode - 1]" class="icon"/>
		<img v-else-if="iconType === 'image'" :src="emojiImages[intCode - 1]" class="icon"/>
	</div>
</template>

<script>
import emojis from './emojis.js'
import emojisHighRes from './emojis-highres.js'

const emojiIconClasses = [
	'fas fa-eye',
	'fas fa-rocket',
	'fas fa-heart',
	'fas fa-car',
	'fas fa-anchor',
	'fas fa-tree',
	'fas fa-star',
	'fas fa-umbrella',
	'fas fa-bicycle',
]

export default {
	name: 'single-emoji-code',
	props: {
		code: {
			required: true,
		},
		border: {
			type: Boolean,
			required: false,
			default: false,
		},
		type: {
			type: String,
			required: false,
			default: 'default',
		},
		iconType: {
			type: String,
			required: false,
			default: 'image',
		},
	},
	data() {
		return {
			emojiIconClasses: emojiIconClasses,
		}
	},
	computed: {
		emojiImages() {
			if (this.type === 'large') {
				return emojisHighRes;
			}

			return emojis;
		},
		intCode() {
			return parseInt(this.code, 10)
		},

	},
}
</script>
<style lang="less" scoped>
@import "~@less/mixins/mixins.less";

@padding: 5px;
@padding2: @padding * 2;

.emoji-code {
	width: 65px;
	height: 65px;
	display: inline-block;
	text-align: center;
	background-color: rgba(@color-white, 1);
	border-radius: 5px;
	margin-left: 20px;
	position: relative;
	color: @color-flush-orange;

	.icon {
		font-size: 64px - @padding2 - 10px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.unSelectable();

	&:first-child {
		margin-left: 0 !important;
	}

	&.emoji-code-border-true {
		border: 2px solid @color-light-grey;
		background-color: @color-background-light-grey;
	}

	&.emoji-code-tiny {
		.icon {
			font-size: 40px - @padding2 - 10px;
		}

		width: 40px;
		height: 40px;
		margin-left: 10px;
	}

	&.emoji-code-tiny-transparent {
		width: 40px;
		height: 40px;
		margin-left: 0px;
		background-color: transparent;
		border: none;
	}

	&.emoji-code-large {
		margin-left: 40px;
		width: 100px;
		height: 100px;

		img {
			width: 100%;
			height: 100%;
		}
	}

	&.no-border {
		border: none !important;
	}

	img {
		position: absolute;

		max-width: 80%;
		max-height: 80%;

		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);


	}
}
</style>
