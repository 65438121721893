<template>
	<div :class="'media-container-' + type + (subType ? ' media-container-sub-' + subType : '')" :style="containerStyle"
		 class="media-container">
		<span v-if="type === 'text'" :class="(xl ? 'media-text-xl' : '')" class="media-text">{{ textSource }}</span>
		<span v-if="type === 'mixed'" :class="(xl ? 'media-mixed-xl' : '')" class="media-mixed"
			  v-html="mixedSource"></span>

		<img v-if="type === 'image'" :src="imageSource" class="media-image"/>
		<img v-if="type === 'gif'" ref="gifContainer" :src="imageSource" class="media-image media-gif"
			 @click="reloadGif"/>

		<iframe
			v-if="type === 'frame'"
			:src="frameSource"
			allowfullscreen
			class="media-frame"
			frameborder="0"
			mozallowfullscreen
			webkitallowfullscreen
		></iframe>
	</div>
</template>
<script>
import * as RegexYouTube from 'youtube-regex'
import * as RegexVimeo from 'vimeo-regex'
import * as EmojiRegex from 'emoji-regex'
import {TextUtil} from '@/utils/TextUtil'
import MediaManager from '@/media-manager'

export default {
	name: 'media',
	props: {
		source: {
			type: String,
			required: true,
		},
		width: {
			default: 640,
		},
		height: {
			default: 360,
		},
	},
	data() {
		// The RegExp call makes sure that we remove the global param from the regex meaning
		// we'll be able continuously call "exec" without getting NULL half the time

		const regexYoutube = RegExp(RegexYouTube())
		const regexVimeo = RegexVimeo()
		const emojiRegex = EmojiRegex()

		let mediaFrame = Math.round(Math.random() * 4) + 1

		return {
			regexYoutube: regexYoutube,
			regexVimeo: regexVimeo,
			emojiRegex: emojiRegex,
			mediaFrame: mediaFrame,
		}
	},
	methods: {
		matchRegex(regex, source) {
			regex.lastIndex = 0

			return regex.exec(source)
		},
		reloadGif() {
			this.$refs.gifContainer.src = this.imageSource
		},
	},
	computed: {
		containerStyle() {
			let width = this.width
			let height = this.height

			if (width === 640 && height === 360) {
				width = '100%;'
				height = 500
			}

			if (typeof (width) === 'number') {
				width = width + 'px'
			}

			if (typeof (height) === 'number') {
				height = height + 'px'
			}

			return {
				width: width,
				height: height,
			}
		},
		xl() {
			let sourceWithoutEmojis = this.source.replace(this.emojiRegex, '')

			// The text only has emojis - let's make them large
			return sourceWithoutEmojis === ''
		},
		subType() {
			if (this.isYoutube) {
				// It's a youtube URL
				return 'video video-youtube'
			} else if (this.isVimeo) {
				// It's a vimeo URL
				return 'video video-vimeo'
			}

			return null
		},
		type() {
			if (this.source.match(/^https?\:\/\//)) {
				// It's an external url
				return 'frame'
			} else if (this.source.match(/\.(png|jpg|jpeg)$/)) {
				return 'image'
			} else if (this.source.match(/\.(gif)$/)) {
				return 'gif'
			} else if (this.source.match(this.emojiRegex)) {
				return 'mixed'
			} else {
				return 'text'
			}
		},
		isVimeo() {
			return this.regexVimeo.test(this.source)
		},
		isYoutube() {
			return this.regexYoutube.test(this.source)
		},
		textSource() {
			return this.source
		},
		mixedSource() {
			return TextUtil.replaceEmojisInText(this.source)
		},
		imageSource() {
			return MediaManager.getMediaUrl(this.source)
		},
		frameSource() {
			if (this.isVimeo) {
				let executionResult = this.matchRegex(this.regexVimeo, this.source)
				let vimeoId = executionResult[5] || executionResult[4]

				return 'https://player.vimeo.com/video/' + vimeoId
			} else if (this.isYoutube) {
				let executionResult = this.matchRegex(this.regexYoutube, this.source)

				let youtubeId = executionResult[1]

				return 'https://www.youtube.com/embed/' + youtubeId
			} else {
				// Just output the raw source
				return this.source
			}
		},
	},
}
</script>

<style lang="less" scoped>
@import "~@less/font-sizes.less";

.media-container {
	text-align: center;
	//max-width: 70%;
	//position: absolute;
	//top: 50%;
	//left: 50%;
}

.media-frame {
	width: 100%;
	height: 100%;
	margin: 0 auto;
}

.media-text.media-text-xl,
.media-mixed.media-mixed-xl {
	font-size: @app-font-size-emoji-xl;
}

.media-image {
	margin-top: 75px;
	max-width: 100%;
	max-height: 100%;
	//transform: translate(-50%, -35%) scale(1.5);
}

.media-gif:hover {
	cursor: pointer;
}

</style>
