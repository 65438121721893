<template>
	<div class="app-button-container">
		<router-link v-if="to && enabled"
					 :aria-label="label"
					 :class="buttonClasses"
					 :tabindex="0"
					 :to="to"
					 class="app-button"
					 role="button"
					 v-on:click.prevent="$emit('onClick')"
					 v-on:keyup.space="$emit('onClick')">
			<span class="app-button-text"><slot></slot></span>
		</router-link>
		<button v-else
				:aria-label="label"
				:class="buttonClasses"
				:tabindex="0"
				class="app-button"
				role="button"
				v-on:click.prevent="$emit('onClick')"
				v-on:keyup.space="$emit('onClick')">
			<span class="app-button-text"><slot></slot></span>
		</button>
	</div>
</template>

<script>
export default {
	name: 'app-button',
	props: {
		to: {
			type: String,
		},
		enabled: {
			type: Boolean,
			default: true,
		},
		accent: {
			type: String,
			default: 'primary', // primary / secondary / warning
		},
	},
	computed: {
		label() {
			return ''
		},
		buttonClasses() {
			let classes = []

			classes.push(this.accent)
			classes.push(this.enabled ? 'enabled' : 'disabled')

			return classes
		},
	},
}
</script>
<style lang="less" scoped>
@import "~@less/font-sizes.less";
@import "~@less/mixins/mixins.less";
@import "~@less/variables.less";

.app-button-container {
	position: relative;
	width: auto;
	display: inline-block;
}

@app-button-width: 190px;
@app-button-height: 38px;
@3d-effect-size: 4px;
@transition: all 0.2s ease-in-out;

.app-button {
	cursor: pointer;
	position: relative;
	width: @app-button-width;
	height: @app-button-height;
	color: white;
	text-align: center;
	font-size: @app-font-size-button;
	display: inline-block;
	transition: @transition;
	padding-top: 3px;
	outline: 0;
	border: 0;
	border-radius: 3px;

	&:focus, &:hover {
		text-decoration: none;
	}

	span {
		line-height: @app-button-height;
		position: relative;
		bottom: 3px;
		font-weight: 700 !important;
	}

	&.primary {
		background: @color-prim-green;
		color: @white;
	}

	&.secondary {
		background: @color-prim-green;
		color: @white;
	}

	&.warning {
		background: @color-prim-green;
		color: @white;
	}

	&.disabled {
		background: #C4C4C4;
		color: #818181;
	}
}

.media-lg({
	.app-button {
		width: @app-button-width * @size-percentage-lg;
		height: @app-button-height * @size-percentage-lg;

		span {
			line-height: @app-button-height * @size-percentage-lg;
		}
	}
});

.media-xl({
	.app-button {
		width: @app-button-width * @size-percentage-xl;
		height: @app-button-height * @size-percentage-xl;

		span {
			line-height: @app-button-height * @size-percentage-xl;
		}
	}
});

.media-xxl({
	.app-button {
		width: @app-button-width * @size-percentage-xxl;
		height: @app-button-height * @size-percentage-xxl;

		span {
			line-height: @app-button-height * @size-percentage-xxl;
		}
	}
});
</style>
