var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-card-container" }, [
    _c(
      "div",
      {
        staticClass: "new-content-card",
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.onClick.apply(null, arguments)
          },
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
            ) {
              return null
            }
            return _vm.onClick.apply(null, arguments)
          },
        },
      },
      [
        _c("NotchHeader", { attrs: { color: "blue" } }),
        _c("div", { staticClass: "title" }, [
          _vm._v("\n\t\t\t" + _vm._s(_vm.title) + "\n\t\t"),
        ]),
        _c("div", { staticClass: "title" }, [
          _vm._v("\n\t\t\t" + _vm._s(_vm.timeEstimate) + "\n\t\t"),
        ]),
        _c(
          "div",
          {
            staticClass: "description-icon",
            on: {
              click: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.onClickDescription.apply(null, arguments)
              },
            },
          },
          [_c("span", { staticClass: "description-icon-inner" }, [_vm._v("i")])]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }