var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container teacher-container" },
    [
      _c(
        "header",
        {
          staticClass:
            "top-bar fixed-top px-5 py-3 d-flex justify-content-between align-items-center",
        },
        [
          _vm.currentPage === "lobby" || _vm.currentPage === "intro"
            ? _c("img", {
                staticClass: "logo",
                attrs: { src: require("@img/misc/logo-small.png") },
              })
            : _vm._e(),
          _vm.currentPage === "lobby"
            ? _c("div", {
                staticClass: "logo-secret-button-admin",
                on: { click: _vm.clickSecretButtonAdmin },
              })
            : _vm._e(),
          _vm.currentPage === "lobby"
            ? _c("div", {
                staticClass: "logo-secret-button-student",
                on: { click: _vm.clickSecretButtonStudent },
              })
            : _vm._e(),
        ]
      ),
      _c(
        "transition",
        { attrs: { name: _vm.componentTransition } },
        [
          _c(_vm.currentPage, {
            tag: "component",
            staticClass: "teacher-page",
            on: {
              loggedIn: function ($event) {
                _vm.isLoggedIn = true
              },
            },
          }),
        ],
        1
      ),
      _vm.showBigQrCode
        ? _c(
            "div",
            {
              staticClass: "large-qr-wrapper",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.closeBigQrCode.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "large-qr-wrapper-popup",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.noop.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "close-btn ",
                      on: { click: _vm.closeBigQrCode },
                    },
                    [_c("i", { staticClass: "fas fa-times" })]
                  ),
                  _c(
                    "div",
                    { staticClass: "qr-code-container" },
                    [
                      _vm.studentUrlSimple
                        ? _c("qr-code", {
                            staticClass: "qr-code-large",
                            attrs: { scale: 15, value: _vm.studentUrlSimple },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.studentUrlSimple
                    ? _c("div", { staticClass: "student-url-large" }, [
                        _vm._v(_vm._s(_vm.studentUrlSimple)),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "popup-emoji-code-container" },
                    [
                      _c("emoji-code", {
                        attrs: {
                          "active-emoji-index": -1,
                          "auto-width": true,
                          "show-triangle": false,
                          type: "large",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm.showHelp
        ? _c(
            "div",
            {
              staticClass: "large-help-wrapper",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.closeHelp.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "large-help-wrapper-popup",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.noop.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "close-btn ", on: { click: _vm.closeHelp } },
                    [_c("i", { staticClass: "fas fa-times" })]
                  ),
                  _c(
                    "div",
                    { staticClass: "help-container" },
                    [
                      _c("no-drag-image", {
                        staticClass: "toggle-tick-image",
                        attrs: { src: _vm.helpImage },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm.popupText
        ? _c(
            "div",
            {
              staticClass: "popup-wrapper-container",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.closePopup.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "popup-wrapper",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.noop.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "close-btn ",
                      on: { click: _vm.closePopup },
                    },
                    [_c("i", { staticClass: "fas fa-times" })]
                  ),
                  _c("div", { staticClass: "popup-contents" }, [
                    _vm.popupHeadline
                      ? _c("h1", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.popupHeadline)),
                        ])
                      : _vm._e(),
                    _c("p", [_vm._v(_vm._s(_vm.popupText))]),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }