var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex align-items-center" }, [
    _c("i", { staticClass: "fas fa-language language-icon mr-2" }),
    _c(
      "select",
      {
        staticClass: "language-selector",
        domProps: { value: _vm.language },
        on: { change: _vm.setLanguage },
      },
      _vm._l(_vm.languages, function (key, value) {
        return _c("option", { key: key, domProps: { value: key } }, [
          _vm._v("\n            " + _vm._s(value) + "\n        "),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }