import { render, staticRenderFns } from "./player-slots.vue?vue&type=template&id=1f18b764&"
import script from "./player-slots.vue?vue&type=script&lang=js&"
export * from "./player-slots.vue?vue&type=script&lang=js&"
import style0 from "./player-slots.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\git\\christian-hansen\\christian-hansen-client\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1f18b764')) {
      api.createRecord('1f18b764', component.options)
    } else {
      api.reload('1f18b764', component.options)
    }
    module.hot.accept("./player-slots.vue?vue&type=template&id=1f18b764&", function () {
      api.rerender('1f18b764', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/debug/game-info-panel/player-slots.vue"
export default component.exports