var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "emoji-code-container",
      style: { width: _vm.codeContainerWidth },
    },
    [
      _c(
        "div",
        { staticClass: "emoji-code-picker-container" },
        _vm._l(_vm.rowCount, function (row, rowIndex) {
          return _c(
            "div",
            { staticClass: "emoji-code-picker-row row-fluid" },
            _vm._l(_vm.columnCount, function (column, columnIndex) {
              return _c("single-emoji-code", {
                key: rowIndex * _vm.columnCount + column,
                attrs: {
                  active:
                    _vm.codeParts[_vm.activeEmojiIndex] ===
                    rowIndex * _vm.columnCount + column,
                  code: rowIndex * _vm.columnCount + column,
                },
                nativeOn: {
                  click: function ($event) {
                    _vm.selectEmojiCode(rowIndex * _vm.columnCount + column)
                  },
                },
              })
            }),
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }