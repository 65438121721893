var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "text-container" },
      [
        _c("NotchHeader", { attrs: { color: "blue" } }),
        _c("p", { staticClass: "case-info" }, [
          _vm._v("\n\t\t\t" + _vm._s(_vm.$t("game.stayTuned")) + " "),
          _c("br"),
          _c("br"),
          _vm._v("\n\t\t\t" + _vm._s(_vm.$t("game.studentWaiting")) + "\n\t\t"),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "case-image-container" }, [
      _c("div", { staticClass: "case-image", style: _vm.caseImageStyle }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }