<template>
    <div>
        <div v-if="options && !gotoPageDisabled">
            <div v-if="showGoToPage">
                <form-row label="Goto Page">
                    <select v-model="options.gotoPageIndex" class="form-control" v-on:blur="sendUpdate()">
                        <option value="">(auto)</option>
                        <option v-for="n in possibleGotoIndices" v-bind:key="n" :value="n">#{{ n + 1 }}</option>
                    </select>
                </form-row>
            </div>
        </div>
        <div class="card text-white bg-dark my-3">
            <div class="card-header row mx-0 p-2">
                <div class="col-8">
                    {{ position }} Component
                </div>
            </div>
            <div class="card-body py-1">
                <form-row label="Type">
                    <select v-model="componentIndex" class="form-control">
                        <option v-for="(type, n) in componentTypes" v-bind:key="type" :value="n">{{ type }}</option>
                    </select>
                </form-row>
                <div class="my-3" style="border-bottom: 2px dashed white"/>
                <div v-if="options">
                    <form-row v-if="component !== 'BackgroundImage'" label="Text">
                        <markdown-editor v-model="options.text" v-on:input="sendUpdate()" :toolbar="getMarkdownToolbarForComponent(component)" :key="markdownKey"></markdown-editor>
                    </form-row>
                    <div v-if="component === 'Media'">
                        <form-row label="Media">
                            <select v-model="selectedMediaType" class="form-control">
                                <option value="video">Video Link</option>
                                <option value="image">Image</option>
                            </select>
                        </form-row>
                        <div v-if="selectedMediaType === 'video'">
                            <form-row label="Link">
                                <input v-model="options.url" class="form-control" type="text"
                                       v-bind:class="{'is-invalid': !isMediaLinkValid}" v-on:blur="sendUpdate()"/>
                            </form-row>
                        </div>
                        <div v-else>
                            <image-picker v-model="options.url" :showImage="true" :showUploader="false"
                                          v-on:change="sendUpdate()"/>
                        </div>
                    </div>
                    <div v-if="component === 'BackgroundImage'">
                        <image-picker v-model="options.url" :showImage="true" :showUploader="false"
                                      v-on:change="sendUpdate()"/>
                    </div>
                    <div v-else-if="component === 'Choices'">
						<form-row label="Time for users to put input moments/dilemmas">
							<input v-model="options.momentDilemmaTime" class="form-control" type="text" v-on:blur="sendUpdate()"/>
						</form-row>
						<form-row label="Time for users to vote on the moments/dilemmas">
							<input v-model="options.momentDilemmaVoteTime" class="form-control" type="text" v-on:blur="sendUpdate()"/>
						</form-row>
						<form-row label="Time for users to put their tokens on the weight map">
							<input v-model="options.weightMapTokenTime" class="form-control" type="text" v-on:blur="sendUpdate()"/>
						</form-row>
                        <form-row label="Result Type">
                            <select v-model="options.resultType" class="form-control" v-on:blur="sendUpdate()">
                                <option v-for="type in resultTypes" v-bind:key="type" :value="type">{{
                                        getResultTypeDescription(type)
                                    }}
                                </option>
                            </select>
                        </form-row>
                        <form-row label="Choice Type">
                            <select v-model="options.choiceType" class="form-control" v-on:blur="sendUpdate()">
                                <option v-for="type in choiceTypes" v-bind:key="type" :value="type">{{
                                        getChoiceTypeDescription(type)
                                    }}
                                </option>
                            </select>
                        </form-row>
                        <form-row label="List Type">
                            <select v-model="options.listType" class="form-control" v-on:blur="sendUpdate()">
                                <option v-for="type in listTypes" v-bind:key="type" :value="type">{{
                                        getListTypeDescription(type)
                                    }}
                                </option>
                            </select>
                        </form-row>
                        <form-row label="Moment type">
                            <select v-model="options.momentType" class="form-control" v-on:blur="sendUpdate()">
                                <option v-for="type in momentTypes" v-bind:key="type" :value="type">{{
                                        getMomentTypeDescription(type)
                                    }}
                                </option>
                            </select>
                        </form-row>
                        <form-row label="Moments hidden initially">
                            <select v-model="options.momentsHiddenInitially" class="form-control" v-on:blur="sendUpdate()">
                                <option v-for="value in yesNo" v-bind:key="value" :value="value">{{
                                        getYesNoTypeDescription(value)
                                    }}
                                </option>
                            </select>
                        </form-row>
                        <form-row label="Token type">
                            <select v-model="options.tokenType" class="form-control" v-on:blur="sendUpdate()">
                                <option v-for="value in tokenTypes" v-bind:key="value" :value="value">{{
                                        getTokenTypeDescription(value)
                                    }}
                                </option>
                            </select>
                        </form-row>
                        <div v-if="options.resultType === 'correctAnswer'">
                            <form-row label="Correct Index">
                                <select v-model="options.correctIndex" class="form-control" v-on:blur="sendUpdate()">
                                    <option v-for="n in options.choices.length" v-bind:key="n" :value="n - 1">
                                        {{ n }}
                                    </option>
                                </select>
                            </form-row>
                        </div>
                        <div v-for="(choice, i) in options.choices" v-bind:key="i" class="card text-white bg-dark my-3">
                            <div class="card-header row mx-0 p-2">
                                <div class="col-8">
                                    Choice {{ i + 1 }}
                                </div>
                                <div class="col d-flex justify-content-end">
                                    <button class="btn btn-sm btn-danger" v-on:click="deleteChoice(i)">Delete</button>
                                </div>
                            </div>
                            <div class="card-body py-1" v-if="!gotoPageDisabled">
                                <form-row
                                    v-if="options.resultType === 'chooseAnswer' || (options.resultType === 'correctAnswer' && options.correctIndex === i)"
                                    label="Goto Page"
                                >
                                    <select
                                        v-model="choice.gotoPageIndex"
                                        class="form-control"
                                        v-on:blur="sendUpdate()"
                                    >
                                        <option :value="null">(auto)</option>
                                        <option v-for="n in possibleGotoIndices" v-bind:key="n" :value="n">
                                            #{{ n + 1 }}
                                        </option>
                                    </select>
                                </form-row>
                                <form-row label="Format">
                                    <select v-model="choice.format" class="form-control" v-on:blur="sendUpdate()">
                                        <option v-for="type in formats" v-bind:key="type" :value="type">{{
                                                getChoiceFormatDescription(type)
                                            }}
                                        </option>
                                    </select>
                                </form-row>
                                <form-row label="Global Score">
                                    <input v-model="choice.globalScore" class="form-control" type="number"
                                           v-on:blur="sendUpdate()"/>
                                </form-row>
                                <form-row label="Text">
                                    <markdown-editor
                                        v-if="choice.format === 'markdown'"
                                        v-model="choice.text"
                                        v-on:input="sendUpdate()"
										:key="markdownKey"
									:toolbar="getMarkdownToolbarForComponent(component)">
                                    </markdown-editor>
                                    <textarea v-else v-model="choice.text" class="form-control"></textarea>
                                </form-row>

                            </div>
                        </div>

                        <div class="d-flex justify-content-center my-3">
                            <button :disabled="!canAddNewChoices" class="btn btn-success btn-sm"
                                    v-on:click="addChoice()">Add New Choice
                            </button>
                        </div>


                        <div v-for="(stage, j) in choiceStages">
                            <div v-for="(instruction, i) in options.instructions[stage].instructions"
                                 v-bind:key="'' + i + ',' + stage"
                                 :class="getInstructionTypeClass(stage)"
                                 class="card text-white my-3">
                                <div class="card-header row mx-0 p-2">
                                    <div class="col-8">
                                        Instruction {{ getInstructionTypeDescription(stage) }} {{ i + 1 }}
                                    </div>
                                    <div class="col d-flex justify-content-end">
                                        <button class="btn btn-sm btn-danger" v-on:click="deleteInstruction(i, stage)">
                                            Delete
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body py-1">
                                    <form-row label="Text">
                                        <markdown-editor
											:key="markdownKey + 'instruction' + i"
                                            :value="instruction"
                                            v-on:input="sendInstructionUpdate(i, stage, $event)">
                                        </markdown-editor>
                                    </form-row>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center my-3">
                                <button class="btn btn-success btn-sm"
                                        v-on:click="addInstruction(stage)">Add New Instruction {{
                                        getInstructionTypeDescription(stage)
                                    }}
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import FormRow from '@/components/form-row'
import MediaManager from '@/media-manager'
import ImagePicker from '@/components/image-picker'
import MarkdownEditor from '@/components/markdown-editor'
import ChoiceStages from '@/enum/ChoiceStages'

export default {
    name: 'component-editor',
    props: {
        content: {
            type: Object,
        },
        position: {
            type: String,
        },
        pagesInCase: {
            type: Number,
        },
        pageIndex: {
            type: Number,
        },
        pageType: {
            type: String,
        },
        prevPageComponentType: {
          type: String,
        }
    },
    components: {
        FormRow,
        ImagePicker,
        MarkdownEditor,
    },
    data () {
        return {
            componentIndex: 0,
            options: null,
            selectedMediaType: 'image',
            canAddNewChoices: true,
            userAddChoices: ChoiceStages.UserAddChoices,
            userVoteChoices: ChoiceStages.UserVoteChoices,
            teacherChooseChoice: ChoiceStages.TeacherChooseChoice,
            weightMapInput1: ChoiceStages.WeightMapInput1,
            weightMapInput2: ChoiceStages.WeightMapInput2,
            weightMapOutput: ChoiceStages.WeightMapOutput,
        }
    },
    mounted () {
        console.log('pageIndex', this.pageIndex)

        if (this.content) {
            console.log('loadContent')

            this.loadContent(this.content)
        }
        else {
            console.log('setDefaultOptions')

            this.setDefaultOptions()
        }

        //console.log('this.content.options', JSON.stringify(this.content.options, null, ' '));
        //console.log('this.options', JSON.stringify(this.options, null, ' '));

        this.recalculateAddNewChoice()
    },
    watch: {
        componentIndex (value, previousValue) {
            if (previousValue !== 0) {
                this.clearOptions()
            }

            this.setDefaultOptions(false)
            this.sendUpdate()
        },
        content () {
            this.loadContent(this.content)
        },
        /*options: {
          deep: true,
          handler() {
            this.recalculateAddNewChoice();
          }
        }*/
    },
    methods: {
        loadContent (content) {

            console.log('LOADING', content)
            this.componentIndex = this.componentTypes.indexOf(content.component)

            let options = Object.assign({}, content.options)

            console.log('optionsafter', options)

            if (this.component === 'Media') {
                this.selectedMediaType = this.isMediaLinkValid ? 'video' : 'image'
            }

            if (this.component === 'Choices') {
                if (!options.listType) {
                    options.listType = 'predefined'
                }

                if (!options.choiceType) {
                    options.choiceType = 'multipleChoice'
                }

                if (!options.momentType) {
                    options.momentType = 'moment'
                }

                if (!options.momentsHiddenInitially) {
                    options.momentsHiddenInitially = 'no';
                }

                if (!options.tokenType) {
                    options.tokenType = 'bothSameTime';
                }

				if (!options.momentDilemmaTime) {
					options.momentDilemmaTime = 120;
				}

				if (!options.momentDilemmaVoteTime) {
					options.momentDilemmaVoteTime = 30;
				}

				if (!options.weightMapTokenTime) {
					options.weightMapTokenTime = 30;
				}

                if (options.choices) {
                    for (let choice of options.choices) {
                        if (!choice.format || choice.format !== 'markdown') {
                            choice.format = 'markdown'
                        }
                    }
                }

                if (!options.instructions) {
                    let tempInstructions = {}
                    for (let stage of this.choiceStages) {
                        tempInstructions[stage] = { instructions: [] }
                    }
                    options.instructions = tempInstructions
                }
                console.log('Loading stuff instructions', options.instructions)
            }

            console.log('after loading', options)
            this.options = options
        },
        recalculateAddNewChoice () {
            this.canAddNewChoices = (this.options.listType !== 'singleChoice' ||
                (this.options.listType === 'singleChoice' && this.options.choices.length < 1)) &&
                (!this.options.choices || this.options.choices.length <= 5)
        },
        sendInstructionUpdate (index, instructionType, event) {
            if (this.options.instructions) {
                this.options.instructions[instructionType].instructions[index] = event
                console.log(this.options.instructions, event, 'index', index)
                this.sendUpdate()
            }

        },
        sendUpdate () {
            this.sanitizeOptions()

            console.log('options', this.options);

            this.$emit('updated', {
                component: this.component,
                options: this.options,
            })
        },
		getMarkdownToolbarForComponent(component) {
			return 'bold italic bullist quote | preview';

        	if (component === 'WhiteBox' || component === 'DarkBox') {
        		return 'heading | bold italic bullist quote | preview';
			} else {
        		return 'bold italic bullist quote | preview';
			}
		},
        sanitizeOptions () {
            if (this.component === 'Choices') {
                if (this.options.resultType !== 'correctAnswer') {
                    if (this.options.hasOwnProperty('correctIndex')) {
                        Vue.delete(this.options, 'correctIndex')
                    }
                }

                if (this.options.listType === 'singleChoice') {
                    if (this.options.choices.length < 1) {
                        this.canAddNewChoices = true
                    }
                    else {
                        Vue.set(this.options, 'choices', [this.options.choices[0]])
                        this.canAddNewChoices = false
                    }
                }
                else {
                    this.canAddNewChoices = true
                }

                if (this.options.resultType !== 'chooseAnswer' && this.options.hasOwnProperty('choices') &&
                    this.options.choices) {
                    for (let i = 0, len = this.options.choices.length; i < len; i++) {
                        if (this.options.choices[i].hasOwnProperty('gotoPageIndex')) {
                            Vue.delete(this.options.choices[i], 'gotoPageIndex')
                        }
                    }
                }

                // For some reason it can be set as a string
                for (const choice of this.options.choices) {
                    if (typeof choice.globalScore !== 'number' && choice.globalScore) {
                        choice.globalScore = parseInt(choice.globalScore)
                    }

                    if (typeof choice.gotoPageIndex !== 'number') {
                        if (choice.gotoPageIndex) {
                            choice.gotoPageIndex = parseInt(choice.gotoPageIndex)
                        }
                        else {
                            choice.gotoPageIndex = null
                        }
                    }
                }
            }

            if (this.component === 'Choices' && this.options.resultType === 'noAnswer' && this.options.hasOwnProperty(
                'gotoPageIndex')) {
                Vue.delete(this.options, 'gotoPageIndex')
            }


            if (this.gotoPageDisabled) {
                Vue.delete(this.options, 'gotoPageIndex')

                if (this.options.choices) {
                    for (const choice of this.options.choices) {
                        Vue.delete(choice, 'gotoPageIndex')
                    }
                }
            }

            console.log('Doing a sanitize!!!', this.component, this.options)
        },
        clearOptions () {
            for (let prop in this.options) {
                if (this.options.hasOwnProperty(prop)) {
                    Vue.delete(this.options, prop)
                }
            }
        },
        setDefaultOptions (clearOptions = false, sendUpdate = true) {
            console.log('BEFORE CLEAR pre.setDefaultOptions', this.pageIndex, JSON.stringify(this.options, null, ' '))
            if (clearOptions) {
                this.clearOptions()
            }

            console.log('pre.setDefaultOptions', this.pageIndex, JSON.stringify(this.options, null, ' '))
            this.options = Object.assign({}, this.defaultOptions, this.options)

            switch (this.component) {
                case 'Media':
                    this.options = Object.assign({}, this.defaultMediaOptions, this.options)
                    break
                case 'BackgroundImage':
                    this.options = Object.assign({}, this.defaultMediaOptions, this.options)
                    break
                case 'Choices':
                    this.options = Object.assign({}, this.defaultChoicesOptions, this.options)
                    break
            }

            console.log('post.setDefaultOptions', this.pageIndex, JSON.stringify(this.options, null, ' '))

            if (sendUpdate) {
                this.sendUpdate()
            }
        },
        addChoice () {
            this.options.choices.push(this.createNewChoice())
            this.sendUpdate()
        },
        addInstruction (instructionType) {
            this.options.instructions[instructionType].instructions.push('')
            console.log('addInstruction', this.options.instructions)
        },
        deleteChoice (index) {
            this.options.choices.splice(index, 1)
            this.sendUpdate()
        },
        deleteInstruction (index, instructionType) {
            this.options.instructions[instructionType].instructions.splice(index, 1)
        },
        createNewChoice () {
            if (this.gotoPageDisabled) {
                return {
                    globalScore: 0,
                    text: '',
                    format: 'markdown',
                }
            } else {
                return {
                    gotoPageIndex: '',
                    //gotoPageIndex: this.pageIndex + 1,
                    globalScore: 0,
                    text: '',
                    format: 'markdown',
                }
            }
        },
        getAnswerTypeDescription (type) {
            switch (type) {
                case 'singleAnswer':
                    return 'Single answer'
                case 'multipleAnswers':
                    return 'Multiple answers'
                default:
                    return 'Unknown answer type'
            }
        },
        getInstructionTypeClass (type) {
            switch (type) {
                case ChoiceStages.UserAddChoices:
                    return 'bg-dark-purple'
                case ChoiceStages.UserVoteChoices:
                    return 'bg-dark-blue'
                case ChoiceStages.TeacherChooseChoice:
                    return 'bg-dark-green'
                case ChoiceStages.WeightMapInput1:
                    return 'bg-dark-yellow'
                case ChoiceStages.WeightMapInput2:
                    return 'bg-dark-orange'
                case ChoiceStages.WeightMapOutput:
                    return 'bg-dark-red'
                default:
                    return ''
            }
        },
        getInstructionTypeDescription (type) {
            switch (type) {
                case ChoiceStages.UserAddChoices:
                    return 'User Add Choices'
                case ChoiceStages.UserVoteChoices:
                    return 'User Vote Choices'
                case ChoiceStages.TeacherChooseChoice:
                    return 'Teacher Choose Choice'
                case ChoiceStages.WeightMapInput1:
                    return 'Student Place Tokens'
                case ChoiceStages.WeightMapInput2:
                    return 'Student Place Second Tokens'
                case ChoiceStages.WeightMapOutput:
                    return 'Tokens are Placed on Board'
                default:
                    return 'Unknown instruction type'
            }
        },
        getListTypeDescription (type) {
            switch (type) {
                case 'predefined':
                    return 'Predefined list'
                case 'predefinedWithFreetext':
                    return 'Predefined list with a freetext field'
                case 'userAdded':
                    return 'Ask the users for dynamic choices'
                case 'singleChoice':
                    return 'Predefine one choice and go straight to weightMap'
                default:
                    return 'Unknown list type'
            }
        },
        getMomentTypeDescription (type) {
            switch (type) {
                case 'moment':
                    return 'Moment'
                case 'dilemma':
                    return 'Dilemma'
                default:
                    return 'Unknown moment type'
            }
        },
        getYesNoTypeDescription (value) {
            switch (value) {
                case 1:
                case true:
                case '1':
                case 'true':
                case 'yes':
                    return 'Yes'
                default:
                    return 'No'
            }
        },
        getChoiceTypeDescription (type) {
            switch (type) {
                case 'multipleChoice':
                    return 'Multiple choice (single answer)'
                case 'multipleAnswers':
                    return 'Multiple answers'
                case 'likertScale':
                    return 'Likert scale'
                default:
                    return 'Unknown choice type'
            }
        },
        getChoiceFormatDescription (type) {
            switch (type) {
                case 'markdown':
                    return 'Markdown'
                case 'raw':
                    return 'Raw'
                default:
                    return 'Unknown display type'
            }
        },
        getResultTypeDescription (type) {
            switch (type) {
                case 'noAnswer':
                    return 'Popularity vote'
                case 'correctAnswer':
                    return 'One answer is correct'
                case 'chooseAnswer':
                    return 'Facilitator chooses a correct answer'
                case 'none':
                    return 'none - skip'
                default:
                    return 'Unknown result type'
            }
        },
        getTokenTypeDescription(type) {
            switch (type) {
                case 'bothSameTime':
                    return 'Both axis, asked simultaneously'
                case 'bothSeparately':
                    return 'Both axis, asked separately'
                case 'horizontal':
                    return 'Horizontal (---)'
                case 'vertical':
                    return 'Vertical ( | )'
                default:
                    return 'Unknown token type'
            }
        }
    },
    computed: {
    	markdownKey() {
    		return this.pageIndex + '-' + this.pageType + '-' + this.component;
		},

        choiceStages () {
            return [
                this.userAddChoices,
                this.userVoteChoices,
                this.teacherChooseChoice,
                this.weightMapInput1,
                this.weightMapInput2,
                this.weightMapOutput]
        },
        component () {
            return this.componentTypes[this.componentIndex]
        },
        componentTypes () {
            let types = []
            if (this.pageType === 'dilemma') {
                types = ['Text', 'Media', 'BackgroundImage', 'WeightMap', 'WhiteBox', 'DarkBox']
                if (!this.isLastPage) {
                    types.push('Choices')
                }
            }

            return types
        },
        resultTypes () {
            return ['noAnswer', 'correctAnswer', 'chooseAnswer', 'none']
        },
        listTypes () {
            return ['predefined', 'predefinedWithFreetext', 'userAdded', 'singleChoice']
        },
        choiceTypes () {
            return ['multipleChoice', 'multipleAnswers', 'likertScale']
        },
        momentTypes () {
            return ['moment', 'dilemma']
        },
        formats () {
            return ['markdown', 'raw']
        },
        yesNo () {
            return ['no', 'yes']
        },
        tokenTypes () {
    	    return ['bothSameTime', 'bothSeparately', 'horizontal', 'vertical'];
        },
        showGoToPage () {
            return this.pageType === 'dilemma' && !this.isLastPage && this.position === 'Left' &&
                (this.component !== 'Choices' || this.options.resultType === 'noAnswer')
        },
        possibleGotoIndices () {
            let indices = []
            for (let i = 0; i < this.pagesInCase; i++) {
                if (i !== this.pageIndex) {
                    indices.push(i)
                }
            }
            return indices
        },
        defaultOptions () {
            return {
                text: '',
                gotoPageIndex: this.pageIndex + 1,
            }
        },
        defaultMediaOptions () {
            return {
                url: '',
            }
        },
        defaultChoicesOptions () {
            return {
                choices: [this.createNewChoice()],
                resultType: 'noAnswer',
                choiceType: 'multipleChoice',
                momentType: 'moment',
                momentsHiddenInitially: 'no',
                tokenType: 'bothSameTime',
                listType: 'predefined',

				momentDilemmaTime: 120,
				momentDilemmaVoteTime: 30,
				weightMapTokenTime: 30,
            }
        },
        isMediaLinkValid () {
            let url = this.options && this.options.url ? this.options.url : null

            if (!url) {
                return false
            }

            return MediaManager.urlIsVimeo(url) || MediaManager.urlIsYoutube(url)
        },
        isLastPage () {
            return this.pageIndex === this.pagesInCase - 1
        },
        gotoPageDisabled() {
    	    return true;
        }
    },
}
</script>

<style lang="less" scoped>
.invalid-feedback {
    font-size: 14px;
}

.bg-dark-purple {
    background-color: rebeccapurple !important;
}

.bg-dark-blue {
    background-color: #004B88 !important;
}

.bg-dark-green {
    background-color: seagreen !important;
}

.bg-dark-yellow {
    background-color: #FADA5E !important;
}

.bg-dark-orange {
    background-color: #E8AC41 !important;
}

.bg-dark-red {
    background-color: darkred !important;
}
</style>
