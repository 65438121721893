<template>
	<div class="input-container">
		<h2 class="input-title">
			{{ title }}
		</h2>
		<input v-model="localValue" :type="inputType" class="input-field" @input="$emit('update:value', localValue)">
	</div>
</template>

<script>
export default {
	name: 'app-input',
	props: {
		title: {
			type: String,
			required: true,
		},
		type: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			localValue: '',
		}
	},
	computed: {
		inputType() {
			return this.type || 'text'
		},
	},
}
</script>

<style lang="less" scoped>
@import "~@less/font-sizes.less";

// .input-container {
// 	color: black;
// }

.input-title {
	margin-bottom: 10px;
}

.input-field {
	background-color: white;
	border: solid #333333 2px;
	padding: 2px 10px;
	font-size: @font-size-sm;
	font-weight: bold;
	width: inherit;

	transition: all 0.2s ease-in-out;
}

.input-field:focus {
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
	transform: scale(1.01);
	outline: none;
}
</style>
