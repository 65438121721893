<template>
	<div class="container">
		<button class="btn btn-primary" v-on:click="() => { $router.go(-1) }">Back</button>
		<h1>Case View</h1>
		<h3 class="mb-2">Case</h3>
		<form-row label="Title">
			<input :value="title" class="form-control" type="text" v-on:blur="onTitleFieldBlur"/>
		</form-row>
		<form-row label="Description">
			<input :value="description" class="form-control" type="text" v-on:blur="onDescriptionFieldBlur"/>
		</form-row>
		<image-picker v-model="image"/>
		<br>
		<div class="dilemma-container">
			<div class="d-flex align-items-center mb-1">
				<h3 class="my-auto mr-3">Dilemma</h3>
				<button v-show="expandDilemmaPages" class="btn btn-warning" v-on:click="toggleDilemmaPages()">Hide
				</button>
				<button v-show="!expandDilemmaPages" class="btn btn-success" v-on:click="toggleDilemmaPages()">Show
				</button>
			</div>
			<template v-if="expandDilemmaPages">
				<div v-for="(page, i) in pages.dilemma" v-bind:key="page.id" class="mb-2">
					<page-editor :page="page" :pagesInCase="pages.dilemma.length"
								 :prevPage=" i !== 0 ? pages.dilemma[i - 1] : null"
								 v-on:deleted="onPageDeleted('dilemma', i)"
								 v-on:movedDown="onPageMoved('dilemma', i, 1)"
								 v-on:movedUp="onPageMoved('dilemma', i, -1)"
								 v-on:updated="(data) => onPageUpdated('dilemma', i, data)"></page-editor>
				</div>
			</template>
			<button v-if="expandDilemmaPages" class="btn btn-block btn-primary" v-on:click="addPage('dilemma')">Add
				dilemma page
			</button>
			<br>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import ImagePicker from '@/components/image-picker'
import PageEditor from '@/components/page-editor'
import FormRow from '@/components/form-row'
import AddButton from '@/components/add-button'
import {getAdminUrl} from '@/utils/UrlUtil'

export default {
	name: 'case',
	components: {
		ImagePicker,
		PageEditor,
		FormRow,
		AddButton,
	},
	data() {
		return {
			caseId: null,
			title: '',
			description: '',
			image: '',
			pages: {
				dilemma: [],
			},
			expandDilemmaPages: true,
		}
	},
	mounted() {
		this.$apiClient.verify(() => {
			this.loadData()
		}, () => {
			this.$router.push(getAdminUrl('login'))
		})
	},
	methods: {
		loadData() {
			this.caseId = this.$router.currentRoute.params.id

			this.$apiClient.getCase(this.caseId, (data) => {
				this.title = data.title
				this.description = data.description
				this.image = data.image

				this.$watch('image', () => {
					this.save()
				})
			})

			this.$apiClient.getCasePages(this.caseId, 'dilemma', (pages) => {
				for (let page of pages) {
					Vue.set(this.pages['dilemma'], page.index, page)
				}
			})
		},
		save() {
			let updatedCase = {
				caseId: this.caseId,
				title: this.title,
				description: this.description,
				image: this.image,
			}

			this.$apiClient.updateCase(updatedCase, () => {
			})
		},
		addPage(type) {
			const numOfPages = this.pages[type].length

			if (numOfPages > 0) {
				this.pages[type][numOfPages - 1].lastPage = false
				this.$apiClient.updatePage(this.pages[type][numOfPages - 1], () => {
				}, () => {
				})
			}

			let newPage = {
				caseId: this.caseId,
				index: numOfPages,
				title: 'New Page',
				timer: 0,
				showBackButton: true,
				hidePlayAgainButton: false,
				components: {
					left: null,
					right: null,
				},
				lastPage: true,
				type: type,
			}

			this.$apiClient.postPage(this.caseId, newPage, (res) => {
				newPage.id = res.id
				Vue.set(this.pages[type], newPage.index, newPage)
			})
		},
		toggleDilemmaPages() {
			this.expandDilemmaPages = !this.expandDilemmaPages
		},
		onTitleFieldBlur(event) {
			if (event.target.value != this.title) {
				this.title = event.target.value
				this.save()
			}
		},
		onDescriptionFieldBlur(event) {
			if (event.target.value != this.description) {
				this.description = event.target.value
				this.save()
			}
		},
		indexPages(type, setIndex, setLastPage) {
			for (let i = 0; i < this.pages[type].length; i++) {
				if (setIndex) {
					this.pages[type][i].index = i
				}
				if (setLastPage) {
					this.pages[type][i].lastPage = i == this.pages[type].length - 1
				}
			}
		},
		onPageUpdated(type, index, data) {
			let page = this.pages[type][index]
			for (let key in data) {
				page[key] = data[key]
			}

			this.$apiClient.updatePage(page, () => {
			}, () => {
			})
		},
		pageContainsReferenceToPageIndex(page, index) {
			console.log('page', page)
			console.log('index', index)
		},
		onPageDeleted(type, index) {
			/*console.log('onPageDeleted', type, index);

			let pageIndexesWithReferences = [];

			for (let i = 0, len = this.pages[type].length; i < len; i++) {
			  let page = this.pages[type][i];

			  if (this.pageContainsReferenceToPageIndex(page, index)) {
				pageIndexesWithReferences.push(index);
			  }
			}

			console.log(pageIndexesWithReferences);

			return;*/
			this.$apiClient.deletePage(this.pages[type][index].id, () => {
				Vue.delete(this.pages[type], index)
				this.indexPages(type, true, true)
				this.$apiClient.updatePages(this.caseId, this.pages[type], () => {
				}, () => {
				})
			})
		},
		onPageMoved(type, index, change) {
			//Temp set index to something different to avoid duplicate indeces error in database
			this.pages[type][index].index = this.pages[type].length
			this.pages[type][index + change].index = this.pages[type].length + 1
			this.$apiClient.updatePage(this.pages[type][index], () => {
				this.$apiClient.updatePage(this.pages[type][index + change], () => {
					this.pages[type][index].index = index + change
					this.pages[type][index + change].index = index
					this.pages[type].sort((a, b) => a.index - b.index)
					this.indexPages(type, false, true)
					this.$apiClient.updatePages(this.caseId, this.pages[type], () => {
					}, () => {
					})
				})
			})
		},
	},
}
</script>
