var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "center" }, [
        _c("h1", { staticClass: "title" }, [_vm._v("DIBL")]),
        _c("img", {
          staticClass: "logo mb-5",
          attrs: { alt: "logo", src: require("@img/misc/logo.png") },
        }),
        _c("div", { staticClass: "buttons" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }