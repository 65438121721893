<template>
	<div class="content-card-container">

		<div class="new-content-card" v-on:click.prevent="onClick"
			 v-on:keyup.space="onClick">
			<NotchHeader :color="'blue'"/>
			<div class="title">
				{{ title }}
			</div>
			<div class="title">
				{{ timeEstimate }}
			</div>
			<!--<div class="description">
				{{ description }}
			</div>-->
			<div class="description-icon" v-on:click.prevent.stop="onClickDescription">
				<span class="description-icon-inner">i</span>
			</div>

		</div>


		<!--<div
				:aria-label="title"
				:class="['content-card-' + (clickable ? 'clickable' : 'not-clickable')]"
				:role="clickable ? 'button' : null"
				:tabindex="clickable ? 0 : null"
				class="content-card"
				v-on:click.prevent="onClick"
				v-on:keyup.space="onClick">

				<div class="title">
					{{ title }}
				</div>
				<div class="media-container">
					<div class="media-frame" :style="mediaFrameStyle"/>
				</div>
			</div>-->
	</div>
</template>

<script>
import MediaManager from '@/media-manager'
import NotchHeader from '@/components/notch-header'

export default {
	name: 'content-card',
	components: {
		NotchHeader,
	},
	props: {
		title: {
			type: String,
			required: true,
		},
		description: {
			type: String,
			required: false,
			default: '',
		},
		timeEstimate: {
			type: String,
			required: false,
			default: '',
		},
		media: {
			type: String,
			required: false,
		},
		mediaRaw: {
			type: Boolean,
			default: false,
		},
		index: {
			type: Number,
			default: 1,
		},
		themeColorNumber: {
			type: Number,
			default: 1,
		},
		clickable: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		mediaUrl() {
			return MediaManager.getMediaUrl(this.media)
		},
		mediaFrameStyle() {
			let style = {}

			if (this.mediaUrl) {
				style['background-image'] = `url("${this.mediaUrl}")`
			}

			return style
		},
	},
	methods: {
		onClickDescription() {
			this.$emit('onClickDescription');
		},
		onClick() {
			this.$emit('onClick')
		},
	},
}
</script>
<style lang="less" scoped>
@import "~@less/variables.less";

.new-content-card {
	position: relative;
	width: calc(60vw);
	background-color: rgba(0, 18, 51, 0.8);
	margin-top: 26px;
	color: #fff;
	padding: 10px 25px 15px 25px;
	margin-left: auto;
	margin-right: auto;
	cursor: pointer;

	&:hover {
		.media-container {
			box-shadow: @shadow;
		}
	}

	.title {
		font-size: 1.4em;
	}

	.description {
		font-size: 1em;
		font-weight: 500;
		padding-bottom: 15px;
	}
}

.content-card {
	height: 100%;
	z-index: 100;
	outline: 0 !important;
	max-width: 400px;
	margin: 0 auto;

	&.content-card-clickable {
		cursor: pointer;

		&:hover {
			.media-container {
				box-shadow: @shadow;
			}
		}
	}

	&.content-card-not-clickable {
		cursor: default !important;
	}

	.media-frame {
		transition: all 0.2s ease-in-out;
	}

	.title {
		text-align: center;
		margin: 10px;
	}

	.description {
		font-weight: 500;
	}

	.media-frame {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		border: 4px solid @grey-light;
		padding-right: 50px;
	}

	.media-container {
		margin: 0 auto;
		height: 250px;
		width: 100%;
		position: relative;
		//height: 200px;
		//width: 100%;
		text-align: center;
		padding: 20px;
		transition: all 0.2s ease-in-out;

		.media-content {
			background: white;
			width: 100%;
			height: 100%;
		}

		.media-frame {
			background-size: cover;
			background-position: center center;
		}
	}
}

.description-icon {
	position: absolute;
	top: 50%;
	right: 30px;
	transform: translateY(-50%);

	.description-icon-inner {

		display: inline-block;
		height: 40px;
		border-radius: 20px;
		width: 40px;
		border: 1px solid white;
		line-height: 40px;
		text-align: center;
		font-size: 40px;

		transition: all;
		transition-duration: 400ms;
		transition-timing-function: ease-in-out;

		&:hover {
			background: white;
			color: rgba(0, 18, 51, 0.8);
		}
	}
}
</style>
