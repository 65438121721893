<template>
	<div class="player-slots card">
		<div class="card-header">
			<nav class="nav nav-pills card-header-pills nav-fill">
				<button v-for="(player, playerId) in players"
						:class="(playerId === activePlayerId ? 'btn-primary' : 'btn-secondary')"
						class="nav-item btn nav-link"
						v-on:click="setActivePlayer(playerId)"
				>
					<div>
						<div class="player-id badge badge-dark">
							{{ playerId }}
						</div>
					</div>
					<div>
						<div v-if="playerId === mePlayerId" class="player-is-me badge badge-light">you</div>
					</div>
				</button>
			</nav>
		</div>
		<div v-if="activePlayer" class="card-body">
			<div class="container">
				<ThingToHtml
					:button="false"
					:thing="activePlayer"
					class="player-thing"
					theme="dark"
				></ThingToHtml>
			</div>
			<div v-if="activePlayerId === mePlayerId" class="container" style="margin: 10px;">
				<div class="row">
					<button :class="editPlayer ? 'btn-info': ''" class="btn" style="margin-left: 10px;" type="button"
							v-on:click="toggleEditPlayer">Edit (advanced) <i
						:class="editPlayer ? 'fa-toggle-on' : 'fa-toggle-off'"
						class="fas"></i>
					</button>
				</div>
				<div v-if="editPlayer" class="row edit-player">
					<strong>Raw player update to send to server</strong>
					<textarea v-model="playerObject" class="form-control"></textarea>
					<button class="btn btn-success" style="margin-top: 10px;" v-on:click="sendUpdateToServer">Send
						update to server
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ThingToHtml from '@/lib/ThingToHtmlVue/index'
import * as JsonBeautify from 'json-beautify'
import clone from 'clone'

//import * as JsonBeautify from 'json-beautify';

export default {
	components: {
		ThingToHtml,
	},
	data() {
		return {
			editPlayer: false,
			activePlayerId: null,
			playerObject: '{\n}',
		}
	},
	watch: {
		players() {
			this.checkActivePlayer()
		},
	},
	mounted() {
		this.checkActivePlayer()
	},
	methods: {
		updateCustomPlayerObject() {
			let activePlayer = {}

			activePlayer['#' + this.activePlayerId] = clone(this.activePlayer)

			let jsonFormatted = JsonBeautify(activePlayer, null, 2, 100)

			console.log(jsonFormatted)

			this.playerObject = jsonFormatted
		},
		setActivePlayer(playerId) {
			this.activePlayerId = playerId

			this.updateCustomPlayerObject()
		},
		toggleEditPlayer() {
			this.editPlayer = !this.editPlayer

			if (this.editPlayer) {
				this.updateCustomPlayerObject()
			}
		},
		checkActivePlayer() {
			let activePlayer = this.activePlayer

			if (!activePlayer) {
				// Active player must have disappeared
				// Select the first next player
				for (let playerId in this.players) {
					this.activePlayerId = playerId

					break
				}
			}
		},
		sendUpdateToServer() {
			let playerObject = JSON.parse(this.playerObject)

			this.$gameClient.send(playerObject)
		},
	},
	computed: {
		players() {
			try {
				return this.$gameClient.room.state.players
			} catch (error) {
				return []
			}
		},
		mePlayerId() {
			return this.$gameClient.room.sessionId
		},
		playerCount() {
			return Object.keys(this.players).length
		},
		activePlayer() {
			if (!this.activePlayerId) {
				return null
			}

			if (!this.players.hasOwnProperty(this.activePlayerId)) {
				return null
			}

			return this.players[this.activePlayerId]
		},
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
@import "~@less/font-sizes.less";

.player-slots {
	.nav-pills {
		.nav-item {
			a.disabled {
				color: #888888 !important;
			}
		}
	}

	.player-name {
		&&.-nameless {
			font-style: italic;
		}
	}

	.card-body {
		padding: 0;
	}

	.player-thing {
		width: 100%;
		height: 100%;

		.thing {
			width: 100%;
			height: 100%;
		}
	}
}

.edit-player {
	margin-right: 3px;

	textarea {
		height: 380px;
		font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
		font-size: @app-font-size-debug-panel-player-slots;
	}
}
</style>
