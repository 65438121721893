var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "login",
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.login()
        },
      },
    },
    [
      _c("app-input", {
        staticClass: "input mb-4",
        attrs: {
          title: _vm.$t("login.username"),
          value: _vm.input.username,
          type: "text",
        },
        on: {
          "update:value": function ($event) {
            return _vm.$set(_vm.input, "username", $event)
          },
        },
      }),
      _c("app-input", {
        staticClass: "input mb-4",
        attrs: {
          title: _vm.$t("login.password"),
          value: _vm.input.password,
          type: "password",
        },
        on: {
          "update:value": function ($event) {
            return _vm.$set(_vm.input, "password", $event)
          },
        },
      }),
      _c(
        "div",
        { staticClass: "buttons" },
        [
          _c(
            "app-button",
            {
              staticClass: "mx-2",
              attrs: { accent: "secondary" },
              on: {
                onClick: function ($event) {
                  return _vm.$emit("cancel")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("misc.back")))]
          ),
          _c(
            "app-button",
            {
              staticClass: "mx-2",
              on: {
                onClick: function ($event) {
                  return _vm.login()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("login.login")))]
          ),
        ],
        1
      ),
      _vm.showFailedLogin
        ? _c("h4", { staticClass: "mt-3" }, [
            _c("small", { staticClass: "text-muted" }, [
              _vm._v(_vm._s(_vm.$t("login.failed"))),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }