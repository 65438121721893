<template>
    <div :style="{ 'height': '100%', 'background-color': colorToHex(containerBackground) }">
        <div class="container">
            <div class="col-sm-10">
                <table class="color-tester-table table table-striped table-bordered">
                    <thead>
                    <tr>
                        <th>Variable</th>
                        <th>Color</th>
                        <th>Preview</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="color in colors" :key="color.variable">
                        <td>{{ color.variable }}</td>
                        <td>{{ colorToHex(color.color) }}</td>
                        <td class="color-box-container">
                            <div
                                class="color-box"
                                @click="clickColor(color)"
                                :style="{ 'background-color': colorToHex(color.color) }"
                            />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-sm-2">

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'color-tester',
    data() {
        return {
            containerBackground: 0xffffff,

            colors: [
                { variable: '@color-white', color: 0xffffff },
                { variable: '@color-cornflower', color: 0x8ecae6 },
                { variable: '@color-eastern-blue', color: 0x219ebc },
                { variable: '@color-green-vogue', color: 0x023047 },
                { variable: '@color-selective-yellow', color: 0xffb703 },
                { variable: '@color-flush-orange', color: 0xfb8500 },
                { variable: '@color-mercury', color: 0xe5e5e5 },
                { variable: '@color-gray', color: 0x888888 },
                { variable: '@color-scarlet', color: 0xf23000 },
                { variable: '@color-purp', color: 0x001233 },
                { variable: '@color-prim-green', color: 0x009864 },
                { variable: '@color-light-grey', color: 0xC6C9CE },
                { variable: '@color-background-light-grey', color: 0xF5F6F6 },
                { variable: '@color-black', color: 0x303030 },
            ]
        };
    },
    methods: {
        clickColor(color) {
            if (this.containerBackground !== color.color) {
                this.containerBackground = color.color;
            } else {
                this.containerBackground = 0xffffff;
            }
        },
        colorToHex(i) {
            // https://stackoverflow.com/a/35467358/247893
            let hex =
                ((i >> 16) & 0xFF).toString(16) +
                ((i >> 8) & 0xFF).toString(16) +
                ((i) & 0xFF).toString(16);

            hex += '000000'; // pad result
            hex = hex.substring(0, 6);

            return "#" + hex;
        },
    },
};
</script>

<style lang="less">
    .color-tester-table {
        font-size: 12px;
        width: 500px !important;
        margin: 0 auto;

        .color-box-container {
            text-align: center;
        }

        .color-box {
            width: 25px;
            height: 25px;
            border: 1px solid black;
            cursor: pointer;
            display: inline-block;
        }
    }
</style>