<template>
	<div>
		<form-row label="Image">
			<div class="d-flex">
				<image-uploader v-if="showUploader" class="mr-2" v-on:upload="onImageUploaded"/>
				<img v-if="showImage && selectedImageUrl" :src="selectedImageUrl" class="mr-2">
				<select v-model="m_selectedImagePath" class="form-control" v-on:change="handleChangeEvent($event)">
					<option value="">Select an image..</option>
					<option v-for="image in images" v-bind:key="image.id" :value="image.path">{{ image.name }}</option>
				</select>
			</div>
		</form-row>
	</div>
</template>

<script>
import FormRow from '@/components/form-row'
import ImageUploader from '@/components/image-uploader'
import MediaManager from '@/media-manager'

export default {
	name: 'image-picker',
	components: {
		FormRow,
		ImageUploader,
	},
	props: {
		selectedImagePath: {
			type: String,
			default: '',
		},
		showImage: {
			type: Boolean,
			default: true,
		},
		showUploader: {
			type: Boolean,
			default: true,
		},
	},
	model: {
		prop: 'selectedImagePath',
		event: 'change',
	},
	data() {
		return {
			images: [],
			m_selectedImagePath: this.selectedImagePath,
		}
	},
	watch: {
		selectedImagePath: function (val) {
			this.m_selectedImagePath = val
		},
	},
	mounted() {
		this.loadImages()
	},
	methods: {
		handleChangeEvent(event) {
			this.m_selectedImagePath = event.target.value
			this.$emit('change', this.m_selectedImagePath)
		},
		loadImages() {
			this.images = []
			this.$apiClient.getImages((images) => {
				for (let image of images) {
					this.images.push(image)
				}
			}, () => {
			})
		},
		onImageUploaded(image) {
			this.images.push(image)
		},
	},
	computed: {
		selectedImageUrl() {
			if (this.selectedImagePath.length == 0) {
				return ''
			}

			return MediaManager.getMediaUrl(this.selectedImagePath)
		},
	},
}
</script>

<style lang="less" scoped>
img {
	height: 32px;
	width: auto;
}
</style>
