<template>
	<div :class="'counter-' + type">
		<div :class="'counter-inner-' + type">
			<div v-if="hasSlot && slotLocation === 'prefix'" class="counter-label counter-label-prefix">
				<slot></slot>
			</div>
			<div v-if="type !== 'blue-thin'">
				<div v-if="totalInt >= 0" class="counter-numbers">
					{{ currentInt }}<span class="total-separator">/</span>{{ totalInt }}
				</div>
				<div v-else class="counter-numbers">
					{{ currentInt }}
				</div>
				<div v-if="hasSlot && slotLocation === 'affix'" class="counter-label counter-label-affix">
					<slot></slot>
				</div>
			</div>
			<div v-else class="counter-label-thin thin-label">
				{{ currentInt }}
				<slot></slot>
			</div>

		</div>
	</div>
</template>

<script>
export default {
	name: 'counter',
	props: {
		current: {
			type: Number,
			required: false,
			default: 0,
		},
		total: {
			type: Number,
			required: false,
			default: -1,
		},
		slotLocation: {
			type: String,
			default: 'prefix',
		},
		type: {
			type: String,
			default: 'green',
		},
	},
	computed: {
		hasSlot() {
			return !!this.$slots.default
		},
		currentInt() {
			if (this.current && !Number.isNaN(this.current)) {
				return Math.round(this.current)
			} else {
				return 0
			}
		},
		totalInt() {
			if (this.total && !Number.isNaN(this.total)) {
				return Math.round(this.total)
			} else {
				return 0
			}
		},
	},
	methods: {},
}
</script>
<style lang="less" scoped>
@import "~@less/variables.less";

@diameter: 125px;
@radius: @diameter / 2;

@small-diameter: 100px;
@small-radius: @small-diameter / 2;

@thin-height: @small-diameter / 3.2;
@thin-radius: @small-diameter / 2;

.counter-green {
	text-align: center;
	display: inline-block;
	width: @diameter;
	height: @diameter;
	background: @color-prim-green;
	position: relative;
	color: white;
	transform: translate(-50%, -50%) rotate(45deg);
	font-family: 'Fira Code', Arial, Helvetica, sans-serif;

}

.counter-blue-thin {
	text-align: center;
	display: inline-block;
	width: @small-diameter;
	height: @thin-height;
	background: @color-purple;
	border-radius: @thin-radius;
	position: relative;
	color: white;
	transform: translate(0, -20%);
	font-family: 'Open Sans', Arial, Helvetica, sans-serif;
	border-style: solid;
	border-color: @color-white;
	border-radius: @thin-radius;
	border-width: 1px;

}

.thin-label {
	top: 50%;
	position: absolute;
	left: 50%;
	transform: translate(-50%, -50%);
}

.counter-blue {
	text-align: center;
	display: inline-block;
	width: @small-diameter;
	height: @small-diameter;
	background: @color-purple;
	border-radius: @small-radius;
	position: relative;
	color: white;
	transform: translate(0, -20%);
	// transform: translate(-50%, -50%) rotate(45deg);
	font-family: 'Open Sans', Arial, Helvetica, sans-serif;

}

.counter-numbers {
	font-size: 30px;
	line-height: 0.75;
}

.counter-label {
	font-size: 16.8px;
	line-height: 16.8px;
	white-space: nowrap;

	&.counter-label-prefix {
		margin-bottom: 10px;
	}

	&.counter-label-affix {
		margin-top: 10px;
	}
}

.counter-label-thin {
	font-size: 12.8px;
	line-height: 12.8px;
	white-space: nowrap;
}

.counter-inner-green {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(-45deg);
}

.counter-inner-blue {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
</style>
