<template>
	<div class="log-item row">
		<span class="log-time">{{ time }}</span><span class="log-message">{{ message }}</span>
	</div>
</template>

<script>
import { formatDateYMDHIS } from '../../../utils/DateUtil'

export default {
	props: {
		message: String,
	},
	data() {
		return {
			time: null,
		}
	},
	mounted() {
		this.time = formatDateYMDHIS(new Date())
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.log-time {
	font-weight: bold;
	color: #888888;
	margin-right: 10px;
}
</style>
