var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: {
        height: "100%",
        "background-color": _vm.colorToHex(_vm.containerBackground),
      },
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "col-sm-10" }, [
          _c(
            "table",
            {
              staticClass:
                "color-tester-table table table-striped table-bordered",
            },
            [
              _vm._m(0),
              _c(
                "tbody",
                _vm._l(_vm.colors, function (color) {
                  return _c("tr", { key: color.variable }, [
                    _c("td", [_vm._v(_vm._s(color.variable))]),
                    _c("td", [_vm._v(_vm._s(_vm.colorToHex(color.color)))]),
                    _c("td", { staticClass: "color-box-container" }, [
                      _c("div", {
                        staticClass: "color-box",
                        style: {
                          "background-color": _vm.colorToHex(color.color),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.clickColor(color)
                          },
                        },
                      }),
                    ]),
                  ])
                }),
                0
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "col-sm-2" }),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Variable")]),
        _c("th", [_vm._v("Color")]),
        _c("th", [_vm._v("Preview")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }