<template>
	<div class="login" @keyup.enter="login()">
		<app-input :title="$t('login.username')" :value.sync="input.username" class="input mb-4" type="text"/>
		<app-input :title="$t('login.password')" :value.sync="input.password" class="input mb-4" type="password"/>
		<div class="buttons">
			<app-button accent="secondary" class="mx-2" @onClick="$emit('cancel')">{{ $t('misc.back') }}</app-button>
			<app-button class="mx-2" @onClick="login()">{{ $t('login.login') }}</app-button>
		</div>
		<h4 v-if="showFailedLogin" class="mt-3">
			<small class="text-muted">{{ $t('login.failed') }}</small>
		</h4>
	</div>
</template>

<script>
import {CurrentUrlInfo} from '@/current-url-info'
import {getAdminUrl} from '@/utils/UrlUtil'
import AppConfig from '@/app-config'
import AppInput from '@/components/app-input'
import AppButton from '@/components/app-button'

export default {
	name: 'login',
	components: {
		AppInput,
		AppButton,
	},
	data() {
		return {
			showFailedLogin: false,
			input: {
				username: '',
				password: '',
			},
		}
	},
	mounted() {
		const host = AppConfig.colyseusHost || CurrentUrlInfo.instance().getCurrentHost()
		const port = AppConfig.colyseusPort
		const wsProtocol = AppConfig.colyseusSecure ? 'wss' : 'ws'
		//this.$apiClient.onGameClientEndPointChanged(host + ':' + port)
	},
	methods: {
		login() {
			this.$apiClient.login(this.input.username, this.input.password, (result) => {
				if (result.success) {
					this.showFailedLogin = false
					this.$router.push(getAdminUrl('overview'))
				} else {
					this.showFailedLogin = true
				}
			})
		},
	},
}
</script>

<style lang="less" scoped>
.login {
	margin: auto;
	display: flex;
	flex-direction: column;
}

.input {
	width: 100%;
}

.buttons {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}
</style>
