var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("transition", { attrs: { mode: "out-in", name: "fade" } }, [
        _vm.connecting
          ? _c("div", { key: "connecting", staticClass: "transition-root" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("loading-indicator", [
                    _vm._v(_vm._s(_vm.$t("room.connecting")) + ".."),
                  ]),
                ],
                1
              ),
            ])
          : _vm.loading
          ? _c("div", { key: "loading", staticClass: "transition-root" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("loading-indicator", [
                    _vm._v(_vm._s(_vm.$t("room.loading")) + ".."),
                  ]),
                ],
                1
              ),
            ])
          : _vm.loadingClassInfo
          ? _c(
              "div",
              { key: "loadingClassInfo", staticClass: "transition-root" },
              [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("loading-indicator", [
                      _vm._v(_vm._s(_vm.$t("room.loading")) + ".."),
                    ]),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        !_vm.connecting && !_vm.loading
          ? _c(
              "div",
              {
                key: "notConnecting",
                staticClass: "content-container transition-root",
              },
              [
                _c("img", {
                  staticClass: "logo",
                  attrs: { src: require("@img/misc/logo-mini.png") },
                }),
                _c(
                  "div",
                  { staticClass: "top-bar" },
                  [
                    _c("h1", { staticClass: "room-code-title" }, [
                      _vm._v(_vm._s(_vm.$t("room.participate"))),
                    ]),
                    _c("p", { staticClass: "room-code-text" }, [
                      _vm._v(_vm._s(_vm.$t("room.enterDescription"))),
                    ]),
                    _c("emoji-code-current", {
                      attrs: {
                        activeEmojiIndex: _vm.activeEmojiIndex,
                        "show-triangle": true,
                        value: _vm.roomCode,
                      },
                      on: {
                        activeEmojiIndexChange: _vm.activeEmojiIndexChange,
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "bottom-bar" }, [
                  _c(
                    "div",
                    { staticClass: "emoji-container" },
                    [
                      _c("emoji-code-picker", {
                        attrs: {
                          type: "tiny",
                          activeEmojiIndex: _vm.activeEmojiIndex,
                        },
                        on: {
                          activeEmojiIndexChange: _vm.activeEmojiIndexChange,
                          fillOutStatusChanged: _vm.onFilloutStatusChanged,
                        },
                        model: {
                          value: _vm.roomCode,
                          callback: function ($$v) {
                            _vm.roomCode = $$v
                          },
                          expression: "roomCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "buttons enter-button mt-1" },
                    [
                      _c(
                        "app-button-fit",
                        {
                          attrs: { enabled: _vm.canJoin },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onClickJoin.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("room.enter")) + "\n\t\t\t\t\t")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }