<template>
	<div class="container">
		<div v-if="!loaded">
			<div><strong>API base URL:</strong> {{ apiBaseUrl }}</div>
			<div>{{ status }}</div>
		</div>
		<div v-else>
			<div class="row current-page-row">
				<div class="input-group mb-2">
					<div class="input-group-prepend">
						<div class="input-group-text">Current Page</div>
					</div>
					<select v-model="currentPageIndex" class="form-control">
						<option v-for="(pageInfo, pageIndex) in currentCasePages"
								:value="pageIndex"
						>Page #{{ pageIndex }} - {{ getPageDescription(pageInfo) }}
						</option>
					</select>
					<div class="input-group-append">
						<button
							:class="canGoBack ? 'btn-info' : 'btn-info-outline disabled'"
							class="btn btn-info"
							@click="pageGoBack"
						>Previous page
						</button>
					</div>
					<div class="input-group-append">
						<button
							:class="canGoForward ? 'btn-info' : 'btn-info-outline disabled'"
							class="btn btn-info"
							@click="pageGoForward"
						>Next page
						</button>
					</div>
				</div>
			</div>
			<div v-if="currentPageIndex >= 0">
				<page-controller :redirectToLobby="false" :useTransitions="false"/>
			</div>
		</div>
		<div class="page-debugger">
			<thing-to-html v-if="currentCasePage" :button="false" :thing="currentCasePage"/>
		</div>
	</div>
</template>
<script>
import PageController from '@/components/_flow-teacher/flow/5-page-controller/index.vue'
import * as MutationTypes from '@/store/mutation-types'
import {mapGetters} from 'vuex'
import ThingToHtml from '@/lib/ThingToHtmlVue/thing-to-html'

export default {
	name: 'page-tester',
	components: {
		ThingToHtml,
		PageController,
	},
	mounted() {
		//this.$apiClient.onGameClientEndPointChanged(this.apiEndPoint)

		this.status = 'Loading themes..'
		this.$apiClient.getThemes('classRoom', (themes) => {
			for (let theme of themes) {
				if (theme.id === this.themeId) {
					this.$store.commit(MutationTypes.SET_CURRENT_THEME, theme)
				}
			}

			this.loaded = false
			this.status = 'Loading cases..'
			this.$apiClient.getThemeCases(this.themeId, (cases) => {
				this.status = 'Loading single case..'

				this.$apiClient.getCase(this.caseId, (_case) => {
					this.$apiClient.getCasePages(this.caseId, (pages) => {
						this.$store.commit(MutationTypes.SET_CURRENT_CASE, _case)
						this.currentPageIndex = 0
						this.loaded = true
					})
				})
			})
		})
	},
	computed: {
		...mapGetters({
			currentCase: 'getCurrentCase',
			actualCurrentPageIndex: 'getCurrentPageIndex',
		}),

		currentCasePages() {
			return this.currentCase ? this.currentCase.pages : []
		},

		apiBaseUrl() {
			if (!this.$apiClient) {
				return null
			}

			return this.$apiClient.getBaseUrl()
		},
		currentCasePage() {
			if (this.actualCurrentPageIndex === null) {
				return null
			}

			let currentCasePages = this.currentCasePages

			if (!currentCasePages.length) {
				return null
			}

			return currentCasePages[this.actualCurrentPageIndex]
		},
		canGoBack() {
			if (this.actualCurrentPageIndex === null) {
				return false
			}

			let currentCasePages = this.currentCasePages

			if (!currentCasePages.length) {
				return false
			}

			return this.actualCurrentPageIndex > 0
		},
		canGoForward() {
			if (this.actualCurrentPageIndex === null) {
				return false
			}

			let currentCasePages = this.currentCasePages

			if (!currentCasePages.length) {
				return false
			}

			return this.actualCurrentPageIndex < currentCasePages.length - 1
		},
	},
	data() {
		return {
			loaded: false,
			status: null,

			themeId: 2,
			caseId: 2,
			apiEndPoint: '127.0.0.1:2670',

			currentPageIndex: null,
		}
	},
	watch: {
		currentPageIndex(value) {
			this.$store.commit(MutationTypes.SET_CURRENT_PAGE_INDEX, value)
		},
	},
	methods: {
		getPageDescription(pageInfo) {
			let title = pageInfo.title

			let leftComponent, rightComponent

			if (pageInfo.components.left) {
				leftComponent = pageInfo.components.left.component

				title += ' | Left: ' + leftComponent
			}

			if (pageInfo.components.right) {
				rightComponent = pageInfo.components.right.component

				title += ' | Right: ' + rightComponent
			}

			return title
		},
		pageGoBack() {
			if (!this.canGoBack) {
				return
			}

			this.currentPageIndex--
		},
		pageGoForward() {
			if (!this.canGoForward) {
				return
			}

			this.currentPageIndex++
		},
	},
}
</script>

<style lang="less">
.current-page-row {
	border-bottom: 1px solid #333333;
	margin-bottom: 10px;
}

.page-debugger {
	margin-top: 10px;
	border-top: 1px solid #333333;
	padding-top: 10px;
}
</style>
