<template>
	<div class="container">
		<button class="btn btn-primary" v-on:click="() => { $router.go(-1) }">Back</button>
		<h1>Theme View</h1>
		<h3 class="mb-2">Theme</h3>
		<form-row label="Title">
			<input :value="title" class="form-control" type="text" v-on:blur="onTitleFieldBlur"/>
		</form-row>
		<!--To easily add a time element comment this in, and add coloumn in database-->
		<!--<form-row label="Time">
		  <input :value="time" class="form-control" type="text" v-on:blur="onTimeFieldBlur"/>
		</form-row>-->
		<form-row label="Description">
			<input :value="description" class="form-control" type="text" v-on:blur="onDescriptionFieldBlur"/>
		</form-row>
		<image-picker v-model="image"/>
		<br>
		<div class="d-flex">
			<h3 class="mb-2">Cases</h3>
			<add-button class="ml-3" v-on:click="addCase()"/>
		</div>
		<data-table>
			<template v-slot:head>
				<tr>
					<th>Title</th>
					<th>State</th>
					<th class="w-25">Actions</th>
				</tr>
			</template>
			<template v-slot:body>
				<tr v-for="themeCase in cases" v-bind:key="themeCase.id">
					<td>{{ themeCase.title }}</td>
					<td>
						<case-state-selector :state="themeCase.state"
											 v-on:change="onCaseStateChanged(themeCase.id, $event)"/>
					</td>
					<td>
						<router-link :to="'/admin/case/'+themeCase.id+'/edit'" class="btn btn-info">Edit</router-link>
						<button class="btn btn-danger" v-on:click="deleteCase(themeCase.id)">Delete</button>
					</td>
				</tr>
			</template>
		</data-table>
	</div>
</template>

<script>
import Vue from 'vue'
import DataTable from '@/components/data-table'
import FormRow from '@/components/form-row'
import ImagePicker from '@/components/image-picker'
import AddButton from '@/components/add-button'
import CaseStateSelector from '@/components/case-state-selector'
import {getAdminUrl} from '@/utils/UrlUtil'

export default {
	name: 'theme',
	data() {
		return {
			themeId: null,
			title: '',
			time: '',
			description: '',
			image: '',
			language: '',
			cases: {},
		}
	},
	components: {
		DataTable,
		FormRow,
		ImagePicker,
		AddButton,
		CaseStateSelector,
	},
	mounted() {
		this.$apiClient.verify(() => {
			this.loadData()
		}, () => {
			this.$router.push(getAdminUrl('login'))
		})
	},
	methods: {
		loadData() {
			this.themeId = this.$router.currentRoute.params.id

			this.$apiClient.getTheme(this.themeId, (theme) => {
				this.title = theme.title
				this.time = theme.time
				this.description = theme.description
				this.image = theme.image
				this.language = theme.language

				this.$watch('image', () => {
					this.save()
				})
			})

			this.$apiClient.getThemeCases(this.themeId, (cases) => {
				for (let c of cases) {
					Vue.set(this.cases, c.id, {
						id: c.id,
						title: c.title,
						state: c.state,
					})
				}
			})
		},
		save() {
			let updatedTheme = {
				themeId: this.themeId,
				title: this.title,
				time: this.time,
				description: this.description,
				image: this.image,
				language: this.language,
			}
			this.$apiClient.updateTheme(updatedTheme, () => {
			}, () => {
			})
		},
		addCase() {
			let newCase = {
				title: 'New Case',
				time: '',
				description: '',
				image: '',
				state: 'draft',
			}
			this.$apiClient.postCase(this.themeId, newCase, (res) => {
				newCase.id = res.id
				Vue.set(this.cases, newCase.id, newCase)
			})
		},
		deleteCase(caseId) {
			if (!confirm('Are you sure you want to delete the case?')) {
				return
			}

			this.$apiClient.deleteCase(caseId, () => {
				Vue.delete(this.cases, caseId)
			})
		},
		onTitleFieldBlur(event) {
			if (event.target.value != this.title) {
				this.title = event.target.value
				this.save()
			}
		},
		onTimeFieldBlur(event) {
			if (event.target.value != this.time) {
				this.time = event.target.value
				this.save()
			}
		},
		onDescriptionFieldBlur(event) {
			if (event.target.value != this.description) {
				this.description = event.target.value
				this.save()
			}
		},
		onCaseStateChanged(id, $event) {
			this.cases[id].state = $event.state

			let updatedCase = {
				caseId: id,
				state: this.cases[id].state,
			}
			this.$apiClient.updateCaseState(updatedCase, () => {
			}, () => {
			})
		},
	},
}
</script>
