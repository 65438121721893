<template>
	<div class="container teacher-login-container" v-if="1">
		<div class="login-box">
			<div class="row logo-container">
				<img class="logo" src="~@img/misc/logo.png"/>
			</div>
		</div>

        <div class="container container-max-width">
        <div class="row buttons enter-button mt-3 button-container">
            <div class="col-6" v-for="language in languages">
                <app-button-fit
                    v-on:click.native="selectLanguage(language)"
                >{{ language.label }}</app-button-fit>
            </div>
        </div>
        </div>
	</div>
</template>

<script>
import LoadingIndicator from '@/components/loading-indicator/index'
import AppButton from '@/components/app-button'
import AppButtonFit from '@/components/app-button-fit'

import * as MutationTypes from '@/store/mutation-types'
import {mapGetters} from 'vuex'
import { getStudentUrl, getTeacherUrl } from '../../../../utils/UrlUtil'
import { getLanguages } from '../../../../utils/LanguageUtil'

export default {
	components: {
		LoadingIndicator,
		AppButton,
		AppButtonFit,
	},
	data() {
		return {
			error: null,
            languages: getLanguages(),
		}
	},
    methods: {
		selectLanguage(language) {
		    const languageCode = language.code;

            console.log('Changing locale/language to "' + languageCode + '"')

            this.$i18n.locale = languageCode
            this.$root.$i18n.locale = languageCode

            this.$store.commit(MutationTypes.SET_LANGUAGE, languageCode)
		},
	},
}
</script>

<style lang="less">
.login-box {
	margin: 0 auto;
	width: 500px;

	img {
		max-width: 100%;
	}
}

.error-container {
	color: #aa0000;
}

.student-connect-link {
    cursor: pointer;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 10px;
    height: 10px;
    display: block;

}

.row.button-container {
    width: 650px;
    transform: translateX(-50%);
    margin-left: 50%;

    .app-button-container {
        margin: 10px;
    }
}
</style>
