var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.activeEmojiIndex === -1
          ? "emoji-code-static"
          : "emoji-code-dynamic",
        "emoji-code-container-" + _vm.type,
      ],
      style: _vm.containerStyle,
    },
    [
      _vm.showVersionInfo
        ? _c("div", { staticClass: "mb-4 app-version" }, [
            _c("strong", [_vm._v("App Version:")]),
            _vm._v(" " + _vm._s(_vm.appVersion)),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "row-fluid text-center emoji-code-current-code" },
        _vm._l(_vm.codeParts, function (singleCode, i) {
          return _c("single-emoji-code", {
            key: i,
            class: _vm.activeEmojiIndex === i ? "emoji-code-active" : "",
            attrs: { border: true, code: singleCode, type: _vm.type },
            nativeOn: {
              click: function ($event) {
                return _vm.setActiveEmojiIndex(i)
              },
            },
          })
        }),
        1
      ),
      _vm.showTriangle
        ? _c(
            "div",
            { staticClass: "row-fluid text-center emoji-code-picker-header" },
            [
              _c("div", {
                staticClass: "emoji-code-picker-header-triangle",
                class:
                  "emoji-code-picker-header-triangle-" + _vm.activeEmojiIndex,
                style: { left: _vm.triangleLeft },
              }),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }