<template>
    <div
        v-if="canFullscreen"
        :aria-label="ariaLabel"
        class="fullscreen-toggle-button" role="button" v-on:click="toggleFullscreen()">
        <transition name="fade">
            <button v-if="fullscreenEnabled" key="contract" :aria-label="ariaLabel" role="button">
                <img alt="" src="~@img/misc/fullscreen-contract.png">
            </button>
            <button v-else key="expand" :aria-label="ariaLabel" role="button">
                <img alt="" src="~@img/misc/fullscreen-expand.png">
            </button>
        </transition>
    </div>
</template>
<script>
import * as screenfull from 'screenfull'

export default {
    name: 'fullscreen-togg-ebutton',
    data () {
        return {
            fullscreenEnabled: false,
        }
    },
    mounted () {
        if (screenfull) {
            screenfull.on('change', () => {
                this.fullscreenEnabled = screenfull.isFullscreen
            })

            this.fullscreenEnabled = screenfull.isFullscreen
        }
    },
    computed: {
        ariaLabel () {
            if (this.fullscreenEnabled) {
                return 'Deactivate fullscreen'
            }
            else {
                return 'Activate fullscreen'
            }
        },
        canFullscreen () {
            return screenfull && screenfull.enabled
        },
    },
    methods: {
        toggleFullscreen () {
            screenfull.toggle()
        },
    },
}
</script>
<style lang="less" scoped>
.fullscreen-toggle-button {
    width: 50px;
    height: 50px;
    display: inline-block;
}

button {
    border: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: inline-block;
    animation-duration: 200ms;
    transition-duration: 200ms;
    background-color: transparent;

    &:focus {
        outline: 0;
    }

    img {
        max-width: 100%;
    }
}
</style>
