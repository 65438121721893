<template>
	<div class="choice-text"><p>{{ text }}</p></div>
</template>

<script>
import VueMarkdown from 'vue-markdown'

export default {
	name: 'choice-text',
	props: {
		text: {
			type: String,
			required: false,
			default: '',
		},
	},
	components: {
		VueMarkdown,
	},
}
</script>

<style lang="less">
@import "~@less/font-sizes.less";
@import "~@less/variables.less";

.choice-text {
	p {
		text-align: left;
		font-weight: 400;
		margin-bottom: 0px;
		color: @blackish;
	}
}
</style>
