var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "emoji-code",
      class: [
        "emoji-code-" + _vm.type,
        "emoji-code-border-" + (_vm.border ? "true" : "false"),
        _vm.emojiImages[_vm.intCode - 1] ? "no-border" : "",
      ],
    },
    [
      _vm.iconType === "icon"
        ? _c("i", {
            staticClass: "icon",
            class: _vm.emojiIconClasses[_vm.intCode - 1],
          })
        : _vm.iconType === "image"
        ? _c("img", {
            staticClass: "icon",
            attrs: { src: _vm.emojiImages[_vm.intCode - 1] },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }