var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    { staticClass: "breadcrumb-btn", on: { click: _vm.onClick } },
    [
      _c("img", {
        staticClass: "breadcrumb-icon",
        attrs: { src: require("@img/misc/back.png") },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }