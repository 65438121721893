<template>
	<div :class="[activeEmojiIndex === -1 ? 'emoji-code-static' : 'emoji-code-dynamic', 'emoji-code-container-' + type]"
		 :style="containerStyle">
        <div v-if="showVersionInfo" class="mb-4 app-version"><strong>App Version:</strong> {{ appVersion }}</div>
		<div class="row-fluid text-center emoji-code-current-code">
			<single-emoji-code
				v-for="(singleCode, i) in codeParts"
				v-bind:key="i"
				:border="true"
				:class="activeEmojiIndex === i ? 'emoji-code-active' : ''"
				v-bind:code="singleCode"
				v-bind:type="type"
				v-on:click.native="setActiveEmojiIndex(i)"
			></single-emoji-code>
		</div>
		<div v-if="showTriangle" class="row-fluid text-center emoji-code-picker-header">
			<div
				:class="'emoji-code-picker-header-triangle-' + activeEmojiIndex"
				:style="{ left: triangleLeft }"
				class="emoji-code-picker-header-triangle"
			>
			</div>
		</div>
	</div>
</template>

<script>
import SingleEmojiCode from './single-emoji-code'
import {mapGetters} from 'vuex'

export default {
	name: 'emoji-code-current',
	components: {
		SingleEmojiCode,
	},
	props: {
		type: {
			type: String,
			required: false,
			default: 'default',
		},
		value: {
			type: String,
			required: true,
		},
		activeEmojiIndex: {
			type: Number,
			default: 0,
		},
		autoWidth: {
			type: Boolean,
			default: false,
		},
		showTriangle: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters({
			features: 'getFeatures',
		}),
		codeContainerWidth() {
			let codeContainerWidth = 20 + (76 * this.emojiLength)

			return codeContainerWidth + 'px'
		},
		triangleLeft() {
			let totalWidth = this.codeContainerWidth.replace(/px$/, '')
			let triangleWidth2 = 15
			let percent = this.activeEmojiIndex / (this.emojiLength - 1)

			let padding = 35
			let startX = padding
			let endX = totalWidth - padding

			let triangleLeft = startX + ((endX - startX) * percent) - triangleWidth2

			return triangleLeft + 'px'
		},
		emojiLength() {
			return this.features.roomIdLength
		},
		containerStyle() {
			let containerStyle = {}

			if (!this.autoWidth) {
				containerStyle.width = this.codeContainerWidth
			}

			return containerStyle
		},
	},
	data() {
		// Cast all values to numbers
		return {
			codeParts: [],

            showVersionInfo: false,
            versionDebugCounter: 0,
            appVersion: process.env.PACKAGE_VERSION || 'unknown',
		}
	},
	watch: {
		value(newValue) {
			this.codeParts = this.getCodePartsFromValue(newValue)
		},
	},
	mounted() {
		this.codeParts = this.getCodePartsFromValue(this.value)
	},
	methods: {
		getCodePartsFromValue(value) {
			while (value.length < this.emojiLength) {
				value += '0'
			}

			let codeParts = value.split(/(?=.)/g)

			// Cast all values to numbers
			codeParts = codeParts.map(Number)

			return codeParts
		},
		setActiveEmojiIndex(index) {
		    if (index === 2) {
		        this.versionDebugCounter++;

		        if (this.versionDebugCounter >= 10) {
		            this.showVersionInfo = true;

		            this.versionDebugCounter = 0;
                }
            } else {
                this.showVersionInfo = false;
		        this.versionDebugCounter = 0;
            }

			this.$emit('activeEmojiIndexChange', index)
		},
	},
}
</script>
<style lang="less" scoped>
@import "~@less/mixins/less-triangle.less";
@import "~@less/variables.less";

.emoji-code-container-default {
	width: 100%;
	text-align: center;
	margin: 0 auto 15px auto;

	// line-height: 2.35;


	&.emoji-code-dynamic {
		margin: 0 auto;

		.emoji-code {
			cursor: pointer;
			transition: all 0.2s ease-in-out;

			&:hover, &.emoji-code-active {
				transform: scale(1.1);
			}
		}
	}
}

.emoji-code-container-tiny {
	width: 100%;
	text-align: center;

	// line-height: 2.35;


	&.emoji-code-dynamic {
		margin: 0 auto;

		.emoji-code {
			cursor: pointer;
			transition: all 0.2s ease-in-out;

			&:hover, &.emoji-code-active {
				transform: scale(1.1);
			}
		}
	}
}


.emoji-code-current-code {
	margin-bottom: 0;
	margin-left: auto;
	margin-right: auto;
}

.emoji-code-picker-header {
	position: relative;
	height: 15px;
	margin-top: 15px;

	.emoji-code-picker-header-triangle {
		.triangle(up, 15px, rgba(2, 48, 71, 0.1));
		position: absolute;
		// opacity: 0.1;
		transition: all 0.2s ease-in-out;
	}
}

.app-version {
    font-size: 12px;
    font-family: Consolas, Menlo, Monaco, 'Lucida Console', 'Liberation Mono', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Courier New', monospace, serif;
}
</style>
