var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container admin-container" },
    [
      _c(
        "transition",
        { attrs: { name: _vm.componentTransition } },
        [_c(_vm.currentPage, { tag: "component", staticClass: "admin-page" })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }