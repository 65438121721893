var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.teacherSkipIntro
    ? _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "intro-page" }, [
          _vm.introPageIndex === 0
            ? _c("div", [
                _vm._m(0),
                _c("div", { staticClass: "content" }, [
                  _c(
                    "div",
                    { staticClass: "blue-band" },
                    [
                      _c("h1", { staticClass: "mb-4 ws-pre" }, [
                        _vm._v(_vm._s(_vm.$t("intro.title"))),
                      ]),
                      _c(
                        "app-button",
                        {
                          staticClass: "py-4",
                          on: { onClick: _vm.onContinue },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("misc.start")) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.introPageIndex === 1
            ? _c("div", { staticClass: "intro-container" }, [
                _c("h1", [_vm._v(_vm._s(_vm.$t("intro.cultureHeadline")))]),
                _c("p", { staticClass: "cursive centered bold" }, [
                  _vm._v(_vm._s(_vm.$t("intro.cultureParagraph"))),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "image-container image-container-1 text-center",
                  },
                  [
                    _c("no-drag-image", {
                      attrs: {
                        src: require("@/_assets/img/video-thumbnail.jpg"),
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.introPageIndex === 2
            ? _c("div", { staticClass: "intro-container" }, [
                _c("h1", [_vm._v(_vm._s(_vm.$t("intro.whyUseHeadline")))]),
                _c("p", [_vm._v(_vm._s(_vm.$t("intro.whyUseParagraph1")))]),
                _c("p", [_vm._v(_vm._s(_vm.$t("intro.whyUseParagraph2")))]),
              ])
            : _vm._e(),
          _vm.introPageIndex === 3
            ? _c(
                "div",
                { staticClass: "intro-container" },
                [
                  _c("vue-markdown", [
                    _vm._v(_vm._s(_vm.$t("intro.whenToUse"))),
                  ]),
                ],
                1
              )
            : _vm._e(),
          !_vm.isIntro && (_vm.showBackButton || _vm.showNextButton)
            ? _c("div", { staticClass: "button-wrapper" }, [
                _c(
                  "div",
                  { staticClass: "button-container" },
                  [
                    _vm.showBackButton
                      ? _c(
                          "app-button",
                          {
                            staticClass: "py-4 intro-btn",
                            on: { onClick: _vm.onBack },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("misc.back")) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.showNextButton
                      ? _c(
                          "app-button",
                          {
                            staticClass: "py-4 intro-btn",
                            on: { onClick: _vm.onContinue },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("misc.next")) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "background" }, [
      _c("div", { staticClass: "gradient" }),
      _c("div", { staticClass: "case-image-container" }, [
        _c("img", {
          staticClass: "case-image",
          attrs: { src: require("@/_assets/img/misc/soil.jpg") },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }