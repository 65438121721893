<script>
import Vue from 'vue'
import { $eBus } from '@/event-bus/index'
import GameClientEvent from '@/enum/GameClientEvent'
import { ConsoleLogger } from '@/console-logger'
import AppConfig from '@/app-config'

import GamePhase from '@/enum/GamePhase'
import PagePhase from '@/enum/PagePhase'

export default {
    name: 'game-client',
    data () {
        return {
            internalIsConnected: false,
            client: null,
            room: null,
            roomId: null,
            logMessages: [],
            playerSessionId: false, // set in this.room.onJoin
            expanded: false,
            colyseusEndPoint: null,
            logger: new ConsoleLogger('GameClientOffline', 'background: #00aa00; color: white', null,
                !AppConfig.debug.gameClient),
        }
    },
    mounted () {
        Object.defineProperty(Vue.prototype, '$gameClient', {
            value: this,
            configurable: true,
        })

        this.logMessage(`Assigned to Vue.$gameClient`)
    },
    watch: {
        phase () {
            let newPhase = this.phase

            this.logMessage(`Phase changed! New phase: ${newPhase}`)

            $eBus.$emit(GameClientEvent.PhaseChanged, newPhase)
        },
        roomId () {
            $eBus.$emit(GameClientEvent.CustomRoomIdChanged, this.roomId)
        },
    },
    computed: {
        phase () {
            try {
                return this.room.state.phase
            }
            catch (error) {
                return null
            }
        },
    },
    methods: {
        isPhase (phase) {
            if (Array.isArray(phase)) {
                return phase.includes(this.phase)
            }
            else {
                return phase === this.phase
            }
        },

        isMe (playerId) {
            return true // With the offline player you're every player because there's only you there!
        },

        // Logger
        clearLog () {
            console.log('Clearing log!')

            this.logMessages = []
        },
        logMessage (message) {
            this.logger.logMessage(message)

            this.logMessages.push(message)
        },

        // Game client control
        connect (host, port, wsProtocol) {
            let colyseusHostWithPort = `${host}:${port}`

            $eBus.$emit(GameClientEvent.EndPointChanged, colyseusHostWithPort)

            $eBus.$emit(GameClientEvent.Open, null)

            this.internalIsConnected = true
        },
        joinRoom (roomId, onJoin = null) {
            this.roomId = roomId

            this.playerSessionId = 'room-offline-' + roomId

            this.logMessage(GameClientEvent.RoomJoin)

            $eBus.$emit(GameClientEvent.RoomJoin)

            this.room = {
                state: {
                    phase: GamePhase.Setup, // The current game phase

                    selectedThemeId: -1,
                    selectedCaseId: -1,

                    currentPageIndex: -1,
                    pagePhase: PagePhase.Normal, // The current page phase

                    voteCounts: [0, 0, 0, 0],

                    studentCount: 0,

                    customId: null, // The custom ID of the room (aka. "the room name")
                },
            }

            if (onJoin) {
                onJoin()
            }

            /*this.room.onMessage.add((message) => {
              this.logMessage(GameClientEvent.RoomMessage + ': ' + message)

              $eBus.$emit(GameClientEvent.RoomMessage, this.room, message)
            })

            this.room.onStateChange.add((state) => {
              this.logMessage(GameClientEvent.RoomStateChange)
              this.logMessage(JSON.stringify(state, null, 2))

              $eBus.$emit(GameClientEvent.RoomStateChange, this.room, state)
            })*/

            $eBus.$emit(GameClientEvent.RoomCreated, this.room)
        },
        leaveRoom () {
            if (this.room) {
                this.logMessage('Leaving room..')

                this.room = null
            }
        },
        inRoom () {
            return !!this.room
        },
        isConnected () {
            return this.internalIsConnected
        },
        isClientConnected () {
            return this.isConnected() || this.inRoom()
        },
        send (data) {
            let stateData = {}
            let playerData = {}

            let hasStateData = false
            let hasPlayerData = false

            for (let key in data) {
                let dataValue = data[key]

                if (key[0] === '_') {
                    let stateKey = key.substr(1)

                    stateData[stateKey] = dataValue
                    this.room.state[stateKey] = dataValue

                    hasStateData = true
                }
                else {
                    playerData[key] = dataValue
                    hasPlayerData = true
                }
            }

            console.log(data)

            //$eBus.$emit(GameClientEvent.RoomMessage, this.room, JSON.stringify(data));

            if (hasStateData) {
                $eBus.$emit(GameClientEvent.RoomStateChange, this.room, stateData)
            }
        },
        disconnect () {
            this.logMessage('Disconnecting..')

            this.leaveRoom()
            this.closeConnection()

            this.logMessage('Disconnected!')
        },
        closeConnection () {
            this.room = null
            this.internalIsConnected = false
        },
    },
    render: function (h) {
        return h() // avoid warning message
    },
}
</script>
