var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group row align-items-center my-2" }, [
    _c("label", { staticClass: "col-3 form-label" }, [
      _vm._v(_vm._s(_vm.label)),
    ]),
    _c("div", { staticClass: "col-9" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }