var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dev-wrapper" },
    _vm._l([0.5, 1], function (scale) {
      return _c(
        "div",
        [
          _c("h1", [_vm._v(_vm._s(scale))]),
          _c("timer-solid", {
            attrs: {
              "time-remaining": _vm.timerTimeRemaining,
              "time-total": _vm.timerTimeTotal,
              scale: scale,
            },
          }),
          _c("hr"),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }