import { render, staticRenderFns } from "./single-emoji-code.vue?vue&type=template&id=07b2d88e&scoped=true&"
import script from "./single-emoji-code.vue?vue&type=script&lang=js&"
export * from "./single-emoji-code.vue?vue&type=script&lang=js&"
import style0 from "./single-emoji-code.vue?vue&type=style&index=0&id=07b2d88e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07b2d88e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\git\\christian-hansen\\christian-hansen-client\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('07b2d88e')) {
      api.createRecord('07b2d88e', component.options)
    } else {
      api.reload('07b2d88e', component.options)
    }
    module.hot.accept("./single-emoji-code.vue?vue&type=template&id=07b2d88e&scoped=true&", function () {
      api.rerender('07b2d88e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/emoji-code/single-emoji-code.vue"
export default component.exports