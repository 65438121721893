<template>
    <div class="error-container-wrapper">
        <div class="error-container">
            <div class="error-image">
                <img :src="errorIconSource"/>
            </div>
            <div class="error-text">
                <slot>Something went wrong</slot>
            </div>
            <div v-if="errorButton" class="error-button">
                <app-button :text="errorButton" v-on:click="errorButtonAction"/>
            </div>
        </div>
    </div>
</template>
<script>
import MediaManager from '@/media-manager'
import AppButton from '@/components/app-button'

export default {
    name: 'error-container',
    components: { AppButton },
    props: {
        errorIcon: {
            type: String,
            required: false,
            default: 'misc/error.png',
        },
        errorButton: {
            type: String,
            required: false,
            default: null,
        },
        errorButtonAction: {
            required: false,
            default: null,
        },
    },
    computed: {
        errorIconSource () {
            return MediaManager.getAsset(this.errorIcon)
        },
    },
}
</script>
<style lang="less" scoped>
@import "~@less/mixins/mixins.less";
@import "~@less/font-sizes.less";

.error-container-wrapper {
    background: rgba(255, 255, 255, .8);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999;
    .unSelectable();
    cursor: pointer;
    animation-duration: 200ms;
    transition-duration: 200ms;

    .error-container {
        position: absolute;
        left: 50%;
        top: 50%;

        transform: translate(-50%, -50%);

        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.6);

        margin: 0 auto;
        width: 400px;
        min-height: 133px;
        background: white;
        border-radius: 5px;
        text-align: center;
        padding: 10px;

        .error-image {
            text-align: center;
        }

        .error-text {
            padding-top: 15px;
            text-align: center;
            font-family: "Titillium Web", sans-serif;
            font-weight: bold;
            font-size: @app-font-size-error;
        }
    }
}
</style>
<style lang="less">
.error-container-wrapper {
    .app-button-container .app-button {
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.6);
    }
}
</style>
