var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: "counter-" + _vm.type }, [
    _c("div", { class: "counter-inner-" + _vm.type }, [
      _vm.hasSlot && _vm.slotLocation === "prefix"
        ? _c(
            "div",
            { staticClass: "counter-label counter-label-prefix" },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm.type !== "blue-thin"
        ? _c("div", [
            _vm.totalInt >= 0
              ? _c("div", { staticClass: "counter-numbers" }, [
                  _vm._v("\n\t\t\t\t" + _vm._s(_vm.currentInt)),
                  _c("span", { staticClass: "total-separator" }, [_vm._v("/")]),
                  _vm._v(_vm._s(_vm.totalInt) + "\n\t\t\t"),
                ])
              : _c("div", { staticClass: "counter-numbers" }, [
                  _vm._v("\n\t\t\t\t" + _vm._s(_vm.currentInt) + "\n\t\t\t"),
                ]),
            _vm.hasSlot && _vm.slotLocation === "affix"
              ? _c(
                  "div",
                  { staticClass: "counter-label counter-label-affix" },
                  [_vm._t("default")],
                  2
                )
              : _vm._e(),
          ])
        : _c(
            "div",
            { staticClass: "counter-label-thin thin-label" },
            [
              _vm._v("\n\t\t\t" + _vm._s(_vm.currentInt) + "\n\t\t\t"),
              _vm._t("default"),
            ],
            2
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }