<template>
	<div class="container select-theme-container">
		<transition mode="out-in" name="fade">
			<div v-if="loading" key="loading" class="transition-root">
				<div class="row">
					<loading-indicator>{{ $t('room.loading') }}..</loading-indicator>
				</div>
			</div>
			<div v-else key="notLoading" class="transition-root">
				<!--<h1>{{ $t('master.selectTheme') }}</h1>-->
				<div class="content-cards-container">
					<div v-for="(card, i) in themeCards">
						<content-card
							v-bind:key="i"
							v-bind:description="card.description"
							v-bind:index="i"
							v-bind:media="card.image"
							v-bind:themeColorNumber="getThemeColorNumber(card)"
							v-bind:title="card.title"
							v-on:onClick="onSelectTheme(card)"
							v-on:onClickDescription="onSelectThemeDescription(card)"
						/>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
import ContentCard from '@/components/content-card'
import LoadingIndicator from '@/components/loading-indicator'
import * as MutationTypes from '@/store/mutation-types'
import {getTeacherUrl} from '@/utils/UrlUtil'
import AppConfig from '@/app-config'

export default {
	components: {
		ContentCard,
		LoadingIndicator,
	},
	data() {
		return {
			loading: true,
			themeCards: [],
		}
	},
	mounted() {
		console.log('GAME CLIENT');
		if (!this.$gameClient || !this.$gameClient.isConnected()) {
			console.log('$gameClient was not connected to the server - going back to index')

			// The game is not at the right point right now.. let's revert the user to the connect screen
			this.$router.push(getTeacherUrl('index'))

			return
		}

		this.$store.commit(MutationTypes.SET_BREADCRUMB_TO, '/')
		this.$store.commit(MutationTypes.SET_CURRENT_THEME, null)

		this.$apiClient.getThemes(this.language, (themes) => {
			this.themeCards = themes
			this.loading = false

			// IF there's only one theme, just select that one
			if (this.features.themeAutoSelectIfOnlyOne && themes.length === 1) {
				this.onSelectTheme(themes[0])
			} else if (AppConfig.autoThemeSelection) {
				for (let theme of themes) {
					if (theme.id === AppConfig.autoThemeSelection) {
						this.onSelectTheme(theme)
					}
				}
			}
		})
	},
	methods: {
		onSelectTheme(theme) {
			console.log('onSelectTheme', theme)

			this.$store.commit(MutationTypes.SET_CURRENT_THEME, theme)
			this.$router.push(getTeacherUrl('select-case'))
		},
		onSelectThemeDescription(theme) {
			this.$store.commit(MutationTypes.SET_POPUP_HEADLINE, theme.title)
			this.$store.commit(MutationTypes.SET_POPUP_TEXT, theme.description)
		},
		getThemeColorNumber(card) {
			if (card && card.extra_data && card.extra_data.themeColor) {
				return card.extra_data.themeColor
			}

			return 1
		},
	},
	computed: {
		...mapGetters({
			language: 'getLanguage',
			features: 'getFeatures',
		}),
	},
}
</script>

<style lang="less" scoped>
.content-cards > * + * {
	margin-left: 20px;
}
</style>
