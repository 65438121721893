var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "error-container-wrapper" }, [
    _c("div", { staticClass: "error-container" }, [
      _c("div", { staticClass: "error-image" }, [
        _c("img", { attrs: { src: _vm.errorIconSource } }),
      ]),
      _c(
        "div",
        { staticClass: "error-text" },
        [
          _vm._t("default", function () {
            return [_vm._v("Something went wrong")]
          }),
        ],
        2
      ),
      _vm.errorButton
        ? _c(
            "div",
            { staticClass: "error-button" },
            [
              _c("app-button", {
                attrs: { text: _vm.errorButton },
                on: { click: _vm.errorButtonAction },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }