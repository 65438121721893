<template>
    <div class="dev-wrapper">
        <markdown-editor
            value="Before we begin, let's unfold our culture model that articulates the essence of the Chr. Hansen culture. The model consists of four cultural drivers, creating a dynamic force that guides our ambitions and actions at work.\n\nThe purpose of the board is to facilitate discussions about how the cultural drivers come into play in our everyday situations. \n\nLet’s have a closer look at the four drivers."
            toolbar="bold italic bullist quote | preview"
         />
    </div>
</template>

<script>
import MarkdownEditor from '../markdown-editor'

export default {
    name: 'markdown-tester',
    components: {MarkdownEditor}
}
</script>
