var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "media-container",
      class:
        "media-container-" +
        _vm.type +
        (_vm.subType ? " media-container-sub-" + _vm.subType : ""),
      style: _vm.containerStyle,
    },
    [
      _vm.type === "text"
        ? _c(
            "span",
            { staticClass: "media-text", class: _vm.xl ? "media-text-xl" : "" },
            [_vm._v(_vm._s(_vm.textSource))]
          )
        : _vm._e(),
      _vm.type === "mixed"
        ? _c("span", {
            staticClass: "media-mixed",
            class: _vm.xl ? "media-mixed-xl" : "",
            domProps: { innerHTML: _vm._s(_vm.mixedSource) },
          })
        : _vm._e(),
      _vm.type === "image"
        ? _c("img", {
            staticClass: "media-image",
            attrs: { src: _vm.imageSource },
          })
        : _vm._e(),
      _vm.type === "gif"
        ? _c("img", {
            ref: "gifContainer",
            staticClass: "media-image media-gif",
            attrs: { src: _vm.imageSource },
            on: { click: _vm.reloadGif },
          })
        : _vm._e(),
      _vm.type === "frame"
        ? _c("iframe", {
            staticClass: "media-frame",
            attrs: {
              src: _vm.frameSource,
              allowfullscreen: "",
              frameborder: "0",
              mozallowfullscreen: "",
              webkitallowfullscreen: "",
            },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }