<template>
	<div class="container admin-container">
		<transition :name="componentTransition">
			<component :is="currentPage" class="admin-page"></component>
		</transition>
	</div>
</template>

<script>
import * as extend from 'extend'

import LoginPage from './flow/0-login'
import OverviewPage from './flow/1-overview'
import ThemePage from './flow/2-theme'
import CasePage from './flow/3-case'

import {ConsoleLogger} from '@/console-logger'
import AppConfig from '@/app-config'
import * as MutationTypes from '@/store/mutation-types'

const pageComponents = {
	'login': LoginPage,
	'overview': OverviewPage,
	'theme': ThemePage,
	'case': CasePage,
}

const components = {}

export default {
	components: extend(components, pageComponents),
	data() {
		return {
			componentTransition: AppConfig.componentTransition,
			currentPage: 'login',
			logger: new ConsoleLogger('AdminController', 'background: #00aaaa; color: white', null,
				!AppConfig.debug.adminController),
		}
	},
	watch: {
		'$route'(to, from) {
			this.reloadCurrentPage(to)
		},
	},
	methods: {
		reloadCurrentPage(route) {
			let currentPage = route.params.page
			console.log(route)

			if (!currentPage || !components.hasOwnProperty(currentPage)) {
				this.logger.logMessage(`Unknown AdminPage "${currentPage}"`)
				return
			}

			this.logger.logMessage(`AdminPage changed to "${currentPage}"`)

			this.currentPage = currentPage
		},
	},
	mounted() {
		this.reloadCurrentPage(this.$router.currentRoute)
		this.$store.commit(MutationTypes.SET_BACKGROUND_ACCENT, 'white')
	},
}
</script>
<style lang="less" scoped>
.admin-container {
	position: relative;
	height: 100%;
}

.admin-page {
	position: absolute;
	width: 100%;
	height: 100%;
	color: #333;
}

#fullscreen-toggle-button {
	position: fixed;
	top: 10px;
	right: 10px;
}
</style>
