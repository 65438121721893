var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container select-theme-container" },
    [
      _c("transition", { attrs: { mode: "out-in", name: "fade" } }, [
        _vm.loading
          ? _c("div", { key: "loading", staticClass: "transition-root" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("loading-indicator", [
                    _vm._v(_vm._s(_vm.$t("room.loading")) + ".."),
                  ]),
                ],
                1
              ),
            ])
          : _c("div", { key: "notLoading", staticClass: "transition-root" }, [
              _c(
                "div",
                { staticClass: "content-cards-container" },
                _vm._l(_vm.themeCards, function (card, i) {
                  return _c(
                    "div",
                    [
                      _c("content-card", {
                        key: i,
                        attrs: {
                          description: card.description,
                          index: i,
                          media: card.image,
                          themeColorNumber: _vm.getThemeColorNumber(card),
                          title: card.title,
                        },
                        on: {
                          onClick: function ($event) {
                            return _vm.onSelectTheme(card)
                          },
                          onClickDescription: function ($event) {
                            return _vm.onSelectThemeDescription(card)
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }