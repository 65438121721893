var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container select-case-container" },
    [
      _c("transition", { attrs: { mode: "out-in", name: "fade" } }, [
        _vm.loading
          ? _c("div", { key: "loadingCases", staticClass: "transition-root" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("loading-indicator", [
                    _vm._v(_vm._s(_vm.$t("room.loading")) + ".."),
                  ]),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.loadingLobby
          ? _c("div", { key: "loadingLobby", staticClass: "transition-root" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("loading-indicator", [
                    _vm._v(_vm._s(_vm.$t("room.connecting")) + ".."),
                  ]),
                ],
                1
              ),
            ])
          : _vm._e(),
        !_vm.loading && !_vm.loadingLobby
          ? _c("div", { key: "notLoading", staticClass: "transition-root" }, [
              _c(
                "div",
                { staticClass: "content-cards-container" },
                _vm._l(_vm.caseCards, function (card, i) {
                  return _c("content-card", {
                    key: i,
                    attrs: {
                      description: card.description,
                      index: i,
                      title: card.title,
                    },
                    on: {
                      onClick: function ($event) {
                        return _vm.onSelectCase(card)
                      },
                    },
                  })
                }),
                1
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }