var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "game-client-container" } }, [
    _vm.expanded
      ? _c("div", { staticClass: "game-client-expanded" }, [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-dark",
                  on: { click: _vm.kickAllStudents },
                },
                [_vm._v("Kick all students in room")]
              ),
              _c("game-info", { staticClass: "row" }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "game-client-expand",
        on: {
          click: function ($event) {
            _vm.expanded = !_vm.expanded
          },
        },
      },
      [
        _c("span", {
          domProps: { textContent: _vm._s(_vm.expanded ? "-" : "+") },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }