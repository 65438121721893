var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "add-button",
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.$emit("click")
        },
      },
    },
    [
      _c(
        "svg",
        {
          attrs: { viewBox: "0 0 64 64", xmlns: "http://www.w3.org/2000/svg" },
        },
        [
          _c("path", {
            attrs: {
              d: "M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M38.5,28H28v11c0,1.104-0.896,2-2,2\n                s-2-0.896-2-2V28H13.5c-1.104,0-2-0.896-2-2s0.896-2,2-2H24V14c0-1.104,0.896-2,2-2s2,0.896,2,2v10h10.5c1.104,0,2,0.896,2,2\n                S39.604,28,38.5,28z",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }