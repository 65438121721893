<template>


	<div class="single-choice-container">


		<div class="content-container">

			<choice-text
				v-bind:text="message"
			/>
		</div>
		<div v-if="showResults && voteCount !== -1" class="toggle-image-container">
			<div v-if="voteCount > 0">
				<no-drag-image class="toggle-tick-image" src="@/_assets/img/misc/pointDiamond.png"/>
				<div class="vote-count-number">{{ voteCount }}</div>
			</div>
		</div>
		<div v-else-if="toggled" class="toggle-image-container">
			<no-drag-image class="toggle-tick-image" src="@/_assets/img/misc/checked.png"/>
		</div>
		<div v-else class="toggle-image-container">
			<no-drag-image class="toggle-tick-image" src="@/_assets/img/misc/unchecked.png"/>
		</div>
		<div class="choice-id">#{{ formattedId }}</div>
		<div v-if="showResults && toggled" class="choice-selected-white"/>
		<div v-if="showResults && toggled" class="choice-selected"/>
		<NotchHeader></NotchHeader>
	</div>


</template>

<script>
import ChoiceMessage from './choice-message'
import ChoiceText from './choice-text'
import NotchHeader from '../notch-header'

export default {
	name: 'choice',
	components: {
		ChoiceMessage,
		ChoiceText,
		NotchHeader,
	},
	props: {
		index: {
			type: Number,
			required: true,
		},
		format: {
			type: String,
			required: true,
		},
		message: {
			type: String,
			required: false,
			default: '',
		},
		messageID: {
			type: Number,
			default: 999,
		},
		bookmarkIsAnonymous: {
			type: Boolean,
			required: false,
			default: false,
		},
		toggled: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
		},
		voteCount: {
			type: Number,
			default: -1,
		},
		showResults: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		formattedId() {
			return this.index + 1
		},
	},
}
</script>
<style lang="less" scoped>

@import "~@less/variables.less";

.choice-selected {
	position: absolute;
	width: calc(10px + 100%);
	height: calc(25px + 100%);
	background-color: #004B88;
	opacity: 0.2;
	top: -20px;
	left: -5px;
	z-index: -5;

}

.choice {
	position: relative;
	// border: 2px solid @color-green-vogue;
	margin-top: 25px;
	// padding: 5px 15px 15px 15px;
	padding: 5px 15px 15px 15px;
	z-index: 1000000;
}

.choice-selected-white {
	background-color: @white;
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: -4;
}

.content-container {
	z-index: 0;
}

.choice-id {
	position: absolute;
	top: -15px;
	right: 5px;
	opacity: 0.3;
	font-weight: 500;
	z-index: 10;
}

.bookmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 50px;
	background: red;
}

.toggle-image-container {
	position: absolute;
	border-radius: 14px;
	z-index: 1000000;

	bottom: 0;
	right: 0;
	transform: translate(0, 15px);

	.vote-count-number {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: white;
		font-weight: 500;
		font-size: 22px;
	}
}

.single-choice-container {
	min-height: 60px;
}
</style>
