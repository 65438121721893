<template>
	<div class="container">
		<div class="center">
			<h1 class="title">DIBL</h1>
			<img alt="logo" class="logo mb-5" src="~@img/misc/logo.png">
			<div class="buttons">
				<!--<app-button :to="teacherTo" class="mb-3">{{ $t('mainMenu.create') }}</app-button>
				<app-button :to="adminTo" accent="secondary" class="mb-3">{{ $t('mainMenu.admin') }}</app-button>-->
				<!-- <language-selector /> -->
			</div>
		</div>
	</div>
</template>

<script>
import AppButton from '@/components/app-button'
import LanguageSelector from '@/components/language-selector'
import {getAdminUrl, getStudentUrl, getTeacherUrl} from '@/utils/UrlUtil'
import * as MutationTypes from '@/store/mutation-types'
import { mapGetters } from 'vuex'

export default {
	components: {
		AppButton,
		LanguageSelector,
	},
	computed: {
		studentTo() {
			return getStudentUrl('connect')
		},
		teacherTo() {
			return getTeacherUrl('index')
		},
		adminTo() {
			return getAdminUrl('login')
		},
	},
	beforeMount() {
		this.$store.commit(MutationTypes.SET_BACKGROUND_ACCENT, 'primary')

        const languageCode = 'en';

        this.$router.push(getTeacherUrl('index'))
	},
}
</script>

<style lang="less" scoped>
@import "~@less/font-sizes.less";

.center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	align-items: center;
}

.title {
	font-size: @font-size-xxxxl;
}

.logo {
	height: 350px;
}

.buttons {
	display: flex;
	flex-direction: column;
}

.logo-container {
	height: 350px;
}
</style>
