var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dev-wrapper" },
    [
      _c("markdown-editor", {
        attrs: {
          value:
            "Before we begin, let's unfold our culture model that articulates the essence of the Chr. Hansen culture. The model consists of four cultural drivers, creating a dynamic force that guides our ambitions and actions at work.\\n\\nThe purpose of the board is to facilitate discussions about how the cultural drivers come into play in our everyday situations. \\n\\nLet’s have a closer look at the four drivers.",
          toolbar: "bold italic bullist quote | preview",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }