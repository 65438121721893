<template>
	<div id="game-client-container">
		<div v-if="expanded" class="game-client-expanded">
			<div class="container">
				<button class="btn btn-dark" v-on:click="kickAllStudents">Kick all students in room</button>
				<game-info class="row"></game-info>
			</div>
		</div>
		<div class="game-client-expand" v-on:click="expanded = !expanded">
			<span v-text="expanded ? '-' : '+'"></span>
		</div>
	</div>
</template>

<script>
import GameInfo from '@/components/debug/game-info-panel/index'

export default {
	components: {
		GameInfo,
	},
	data() {
		return {
			expanded: false,
		}
	},
	methods: {
		kickAllStudents() {
			console.log('Kicking all students')
			this.$gameClient.send({
				_kickStudents: true,
			})
		},
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@import "~@less/mixins/mixins.less";
@import "~@less/font-sizes.less";

.game-client-expand {
	position: fixed;
	bottom: 0;
	right: 0;
	//padding: 20px 50px;
	font-size: @app-font-size-debug-panel-expand;
	font-weight: bold;
	background: white;
	border-radius: 5px 0 0 0;
	cursor: pointer;
	height: 40px;
	width: 40px;
	text-align: center;
	line-height: 40px;
	.unSelectable();
	box-shadow: -2px -2px 2px 0px rgba(0, 0, 0, .3);
	z-index: 1005;

	&:hover {
		background: black;
		color: white;
	}
}

.game-client-expanded {
	background: fadeout(#d1e0e0, 10%);
	z-index: 1000;
	border-top: 3px solid #333333;
	padding-top: 50px;
	margin-top: 50px;
	height: 300px;
	overflow-y: scroll;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
}
</style>
