<template>
	<div class="container">
		<button class="btn btn-primary" @click="logout">Log Out</button>
		<h1>Overview</h1>
		<div class="d-flex">
			<h3 class="mb-2">Themes</h3>
			<add-button class="ml-3" v-on:click="addTheme()"/>
		</div>
		<data-table>
			<template v-slot:head>
				<tr>
					<th>Title</th>
					<th class="w-25">Language</th>
					<th class="w-25">Actions</th>
				</tr>
			</template>
			<template v-slot:body>
				<tr v-for="theme in themes" v-bind:key="theme.id">
					<td>{{ theme.title }}</td>
					<td>
						<select v-model="theme.language" class="form-control language-selector"
								v-on:change="updateTheme(theme)">
							<option v-for="(key, value) in languages" v-bind:key="key" :value="key">{{ value }}</option>
						</select>
					</td>
					<td>
						<router-link :to="'/admin/theme/'+theme.id+'/edit'" class="btn btn-info">Edit</router-link>
						<button class="btn btn-danger" v-on:click="deleteTheme(theme.id)">Delete</button>
						<button class="btn btn-success" v-on:click="copyTheme(theme.id)">Copy</button>
					</td>
				</tr>
			</template>
		</data-table>
		<br>
		<div class="d-flex">
			<h3 class="mb-2">Media</h3>
			<image-uploader class="ml-3" v-on:upload="onImageUploaded"/>
		</div>
		<data-table>
			<template v-slot:head>
				<tr>
					<th>Name</th>
					<th class="w-25">Image</th>
					<th class="w-25">Actions</th>
				</tr>
			</template>
			<template v-slot:body>
				<tr v-for="(image, n) in images" v-bind:key="image.id">
					<td>{{ image.name }}</td>
					<td>
						<img :src="getImageSrc(image.path)" class="media-img">
					</td>
					<td>
						<button class="btn btn-danger" v-on:click="deleteImage(n)">Delete</button>
					</td>
				</tr>
			</template>
		</data-table>
	</div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import DataTable from '@/components/data-table'
import AddButton from '@/components/add-button'
import ImageUploader from '@/components/image-uploader'
import Language from '@/enum/Language'
import MediaManager from '@/media-manager'
import {getAdminUrl} from '@/utils/UrlUtil'
import { getLanguages } from '../../../../utils/LanguageUtil'

export default {
	name: 'overview',
	components: {
		DataTable,
		AddButton,
		ImageUploader,
	},
	data() {
		return {
			themes: {},
			images: [],
			superAdmin: false,
		}
	},
	mounted() {
		this.$apiClient.verify((user) => {
			if (user.role == 'superAdmin') {
				this.superAdmin = true
			}
			this.loadData()
		}, () => {
			this.$router.push(getAdminUrl('login'))
		})
	},
	methods: {
		logout() {
			this.$apiClient.logout()
			this.$router.push(getAdminUrl('login'))
		},
		loadData() {
			this.loadThemes()
			this.loadImages()
		},
		loadThemes() {
			this.$apiClient.getAllThemes((themes) => {
				for (let theme of themes) {
					Vue.set(this.themes, theme.id, theme)
				}
			})
		},
		deleteTheme(themeId) {
			if (!confirm('Are you sure you want to delete the theme?')) {
				return
			}

			this.$apiClient.deleteTheme(themeId, () => {
				Vue.delete(this.themes, themeId)
			})
		},
		addTheme() {
			let newTheme = {
				title: 'New Theme',
				description: '',
				image: '',
				language: this.language,
			}

			this.$apiClient.postTheme(newTheme, (res) => {
				newTheme.id = res.id
				Vue.set(this.themes, newTheme.id, newTheme)
			})
		},
		copyTheme(themeId) {
			this.$apiClient.copyTheme(themeId, (res) => {
				Vue.set(this.themes, res.theme.id, res.theme)
			}, () => {
				//Rejected
			})
		},
		updateTheme(theme) {
			let updatedTheme = theme
			updatedTheme.themeId = theme.id
			this.$apiClient.updateTheme(updatedTheme, () => {
			}, () => {
			})
		},
		loadImages() {
			this.images = []
			this.$apiClient.getImages((images) => {
				for (let image of images) {
					this.images.push(image)
				}
			}, () => {
			})
		},
		deleteImage(index) {
			if (!confirm('Are you sure you want to delete the image?')) {
				return
			}

			this.$apiClient.deleteImage(this.images[index].id, () => {
				this.images.splice(index, 1)
			})
		},
		onImageUploaded(image) {
			this.images.push(image)
		},
		getImageSrc(path) {
			return MediaManager.getMediaUrl(path)
		},
	},
	computed: {
		...mapGetters({
			language: 'getLanguage',
		}),
		languages() {
		    return Language;
		},
	},
}
</script>

<style lang="less" scoped>
.language-selector {
	height: 30px;
	font-size: 13px;
}

.media-img {
	height: 32px;
	width: auto;
}
</style>
