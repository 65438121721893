var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("img", {
    ref: "image",
    class: _vm.isLoaded ? "img-loaded" : "",
    attrs: { src: _vm.src, draggable: "false" },
    on: { load: _vm.onLoad },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }