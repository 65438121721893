var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container",
      staticStyle: { background: "#001233", height: "100%" },
    },
    [
      _c("div", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.timeTotal,
              expression: "timeTotal",
              modifiers: { number: true },
            },
          ],
          attrs: { type: "text" },
          domProps: { value: _vm.timeTotal },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.timeTotal = _vm._n($event.target.value)
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
      ]),
      _c("div", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.timeRemaining,
              expression: "timeRemaining",
              modifiers: { number: true },
            },
          ],
          attrs: { max: _vm.timeTotal, min: "0", type: "range" },
          domProps: { value: _vm.timeRemaining },
          on: {
            __r: function ($event) {
              _vm.timeRemaining = _vm._n($event.target.value)
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
      ]),
      _c("div", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.scale,
              expression: "scale",
              modifiers: { number: true },
            },
          ],
          attrs: { type: "text" },
          domProps: { value: _vm.scale },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.scale = _vm._n($event.target.value)
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
      ]),
      _c("TextWireBorder", {
        attrs: { text: "ejrfererf er fjeij rfe er k", title: "Heyo!" },
      }),
      _c("div", [_c("TimerSolid")], 1),
      _c("choice", {
        attrs: {
          choiceType: "multipleAnswers",
          choices: _vm.choices,
          selectable: true,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }