var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return 0
    ? _c(
        "div",
        { staticClass: "container teacher-login-container" },
        [
          _c("div", { staticClass: "login-box" }, [
            _vm._m(0),
            _c("div", { staticClass: "row mt-3" }, [
              _vm.error
                ? _c("div", { staticClass: "mb-3 error-container" }, [
                    _c("strong", [_vm._v(_vm._s(_vm.$t("login.error")) + ":")]),
                    _vm._v(" " + _vm._s(_vm.error) + "\n\t\t\t\t"),
                  ])
                : _vm._e(),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password",
                  },
                ],
                staticClass: "form-control form-control-lg",
                attrs: { type: "password" },
                domProps: { value: _vm.password },
                on: {
                  keypress: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.doLogin.apply(null, arguments)
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.password = $event.target.value
                  },
                },
              }),
            ]),
            _c(
              "div",
              { staticClass: "row buttons enter-button mt-3" },
              [
                _c(
                  "app-button-fit",
                  {
                    attrs: { enabled: !!_vm.password },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.doLogin.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("login.login")))]
                ),
              ],
              1
            ),
          ]),
          _c("router-link", {
            staticClass: "student-connect-link",
            attrs: { to: _vm.studentConnectLink },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row logo-container" }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("@img/misc/logo.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }