var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container teacher-connect-container" },
    [
      _c("transition", { attrs: { mode: "out-in", name: "fade" } }, [
        _vm.connecting
          ? _c("div", { key: "connecting", staticClass: "transition-root" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("loading-indicator", [
                    _vm._v(_vm._s(_vm.$t("room.connecting")) + ".."),
                  ]),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }