<template>
	<div class="container select-case-container">
		<transition mode="out-in" name="fade">
			<div v-if="loading" key="loadingCases" class="transition-root">
				<div class="row">
					<loading-indicator>{{ $t('room.loading') }}..</loading-indicator>
				</div>
			</div>
			<div v-if="loadingLobby" key="loadingLobby" class="transition-root">
				<div class="row">
					<loading-indicator>{{ $t('room.connecting') }}..</loading-indicator>
				</div>
			</div>
			<div v-if="!loading && !loadingLobby" key="notLoading" class="transition-root">
				<!--<h1>{{ themeTitle }}</h1>-->
				<div class="content-cards-container">
					<content-card
						v-for="(card, i) in caseCards"
						v-bind:key="i"
						v-bind:description="card.description"
						v-bind:index="i"
						v-bind:title="card.title"
						v-on:onClick="onSelectCase(card)"
					/>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
import ContentCard from '@/components/content-card'
import LoadingIndicator from '@/components/loading-indicator'
import * as MutationTypes from '@/store/mutation-types'
import {getTeacherUrl} from '@/utils/UrlUtil'
import AppConfig from '@/app-config'

export default {
	components: {
		ContentCard,
		LoadingIndicator,
	},
	data() {
		return {
			loadingLobby: false,
			loading: true,
			caseCards: [],
		}
	},
	mounted() {
		if (!this.currentTheme) {
			console.log('Current theme missing - going back to index')

			this.$router.push(getTeacherUrl('select-theme'))

			return
		}

		console.log('getting published theme cases')

		this.$apiClient.getPublishedThemeCases(this.currentTheme.id, (cases) => {
			console.log('getting published theme cases', cases)

			this.caseCards = cases
			this.loading = false

			if (this.features.caseAutoSelectIfOnlyOne && cases.length === 1) {
				this.onSelectCase(cases[0])
			} else if (AppConfig.autoCaseSelection) {
				for (let singleCase of cases) {
					if (singleCase.id === AppConfig.autoCaseSelection) {
						this.onSelectCase(singleCase)
					}
				}
			}
		})

		this.$store.commit(MutationTypes.SET_BREADCRUMB_TO, getTeacherUrl('intro'))
		this.$store.commit(MutationTypes.SET_CURRENT_CASE, null)
	},
	methods: {
		onSelectCase(_case) {
			console.log('onSelectCase', _case)

			let caseId = _case.id

			this.loadingLobby = true

			this.$gameClient.send({
				_selectedCaseId: caseId,
				_selectedThemeId: this.currentTheme.id,
			})

			//_phase: GamePhase.Lobby,

			console.log('_case', _case, {
				_selectedCaseId: caseId,
				_selectedThemeId: this.currentTheme.id,
			})

			this.$store.commit(MutationTypes.SET_CURRENT_CASE, _case)
		},
	},
	computed: {
		...mapGetters({
			features: 'getFeatures',
			currentTheme: 'getCurrentTheme',
		}),
		themeTitle() {
			return this.currentTheme ? this.currentTheme.title : ''
		},
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
h1 {
	text-align: center;
}

.content-cards-container {
}
</style>
