<template>
	<div class="dev-wrapper">
        <div v-for="scale in [0.5, 1]">
            <h1>{{ scale }}</h1>
            <timer-solid :time-remaining="timerTimeRemaining" :time-total="timerTimeTotal" :scale="scale" />
            <hr />
        </div>
	</div>
</template>

<script>
import TimerSolid from '../timer-solid'

export default {
	name: 'weight-map-tester',
	components: {TimerSolid},
    data() {
	    const timerTimeTotal = 5;

	    return {
            timerTimeRemaining: timerTimeTotal,
            timerTimeTotal: timerTimeTotal,
        }
    },
    mounted() {
	    this.timerTimeRemaining = this.timerTimeTotal;

	    let timer;

	    timer = setInterval(() => {
	        if (this.timerTimeRemaining > 0) {
                this.timerTimeRemaining -= 1;
            } else {
	            clearInterval(timer);

	            timer = null;
            }
        }, 1000);
    }
}
</script>
