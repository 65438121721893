var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "player-slots card" }, [
    _c("div", { staticClass: "card-header" }, [
      _c(
        "nav",
        { staticClass: "nav nav-pills card-header-pills nav-fill" },
        _vm._l(_vm.players, function (player, playerId) {
          return _c(
            "button",
            {
              staticClass: "nav-item btn nav-link",
              class:
                playerId === _vm.activePlayerId
                  ? "btn-primary"
                  : "btn-secondary",
              on: {
                click: function ($event) {
                  return _vm.setActivePlayer(playerId)
                },
              },
            },
            [
              _c("div", [
                _c("div", { staticClass: "player-id badge badge-dark" }, [
                  _vm._v("\n\t\t\t\t\t\t" + _vm._s(playerId) + "\n\t\t\t\t\t"),
                ]),
              ]),
              _c("div", [
                playerId === _vm.mePlayerId
                  ? _c(
                      "div",
                      { staticClass: "player-is-me badge badge-light" },
                      [_vm._v("you")]
                    )
                  : _vm._e(),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
    _vm.activePlayer
      ? _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c("ThingToHtml", {
                staticClass: "player-thing",
                attrs: {
                  button: false,
                  thing: _vm.activePlayer,
                  theme: "dark",
                },
              }),
            ],
            1
          ),
          _vm.activePlayerId === _vm.mePlayerId
            ? _c(
                "div",
                { staticClass: "container", staticStyle: { margin: "10px" } },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn",
                        class: _vm.editPlayer ? "btn-info" : "",
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "button" },
                        on: { click: _vm.toggleEditPlayer },
                      },
                      [
                        _vm._v("Edit (advanced) "),
                        _c("i", {
                          staticClass: "fas",
                          class: _vm.editPlayer
                            ? "fa-toggle-on"
                            : "fa-toggle-off",
                        }),
                      ]
                    ),
                  ]),
                  _vm.editPlayer
                    ? _c("div", { staticClass: "row edit-player" }, [
                        _c("strong", [
                          _vm._v("Raw player update to send to server"),
                        ]),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.playerObject,
                              expression: "playerObject",
                            },
                          ],
                          staticClass: "form-control",
                          domProps: { value: _vm.playerObject },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.playerObject = $event.target.value
                            },
                          },
                        }),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-success",
                            staticStyle: { "margin-top": "10px" },
                            on: { click: _vm.sendUpdateToServer },
                          },
                          [_vm._v("Send\n\t\t\t\t\tupdate to server\n\t\t\t\t")]
                        ),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }