var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "single-choice-container" },
    [
      _c(
        "div",
        { staticClass: "content-container" },
        [_c("choice-text", { attrs: { text: _vm.message } })],
        1
      ),
      _vm.showResults && _vm.voteCount !== -1
        ? _c("div", { staticClass: "toggle-image-container" }, [
            _vm.voteCount > 0
              ? _c(
                  "div",
                  [
                    _c("no-drag-image", {
                      staticClass: "toggle-tick-image",
                      attrs: {
                        src: require("@/_assets/img/misc/pointDiamond.png"),
                      },
                    }),
                    _c("div", { staticClass: "vote-count-number" }, [
                      _vm._v(_vm._s(_vm.voteCount)),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm.toggled
        ? _c(
            "div",
            { staticClass: "toggle-image-container" },
            [
              _c("no-drag-image", {
                staticClass: "toggle-tick-image",
                attrs: { src: require("@/_assets/img/misc/checked.png") },
              }),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "toggle-image-container" },
            [
              _c("no-drag-image", {
                staticClass: "toggle-tick-image",
                attrs: { src: require("@/_assets/img/misc/unchecked.png") },
              }),
            ],
            1
          ),
      _c("div", { staticClass: "choice-id" }, [
        _vm._v("#" + _vm._s(_vm.formattedId)),
      ]),
      _vm.showResults && _vm.toggled
        ? _c("div", { staticClass: "choice-selected-white" })
        : _vm._e(),
      _vm.showResults && _vm.toggled
        ? _c("div", { staticClass: "choice-selected" })
        : _vm._e(),
      _c("NotchHeader"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }