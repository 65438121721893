var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          on: {
            click: function () {
              _vm.$router.go(-1)
            },
          },
        },
        [_vm._v("Back")]
      ),
      _c("h1", [_vm._v("Case View")]),
      _c("h3", { staticClass: "mb-2" }, [_vm._v("Case")]),
      _c("form-row", { attrs: { label: "Title" } }, [
        _c("input", {
          staticClass: "form-control",
          attrs: { type: "text" },
          domProps: { value: _vm.title },
          on: { blur: _vm.onTitleFieldBlur },
        }),
      ]),
      _c("form-row", { attrs: { label: "Description" } }, [
        _c("input", {
          staticClass: "form-control",
          attrs: { type: "text" },
          domProps: { value: _vm.description },
          on: { blur: _vm.onDescriptionFieldBlur },
        }),
      ]),
      _c("image-picker", {
        model: {
          value: _vm.image,
          callback: function ($$v) {
            _vm.image = $$v
          },
          expression: "image",
        },
      }),
      _c("br"),
      _c(
        "div",
        { staticClass: "dilemma-container" },
        [
          _c("div", { staticClass: "d-flex align-items-center mb-1" }, [
            _c("h3", { staticClass: "my-auto mr-3" }, [_vm._v("Dilemma")]),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.expandDilemmaPages,
                    expression: "expandDilemmaPages",
                  },
                ],
                staticClass: "btn btn-warning",
                on: {
                  click: function ($event) {
                    return _vm.toggleDilemmaPages()
                  },
                },
              },
              [_vm._v("Hide\n\t\t\t")]
            ),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.expandDilemmaPages,
                    expression: "!expandDilemmaPages",
                  },
                ],
                staticClass: "btn btn-success",
                on: {
                  click: function ($event) {
                    return _vm.toggleDilemmaPages()
                  },
                },
              },
              [_vm._v("Show\n\t\t\t")]
            ),
          ]),
          _vm.expandDilemmaPages
            ? _vm._l(_vm.pages.dilemma, function (page, i) {
                return _c(
                  "div",
                  { key: page.id, staticClass: "mb-2" },
                  [
                    _c("page-editor", {
                      attrs: {
                        page: page,
                        pagesInCase: _vm.pages.dilemma.length,
                        prevPage: i !== 0 ? _vm.pages.dilemma[i - 1] : null,
                      },
                      on: {
                        deleted: function ($event) {
                          return _vm.onPageDeleted("dilemma", i)
                        },
                        movedDown: function ($event) {
                          return _vm.onPageMoved("dilemma", i, 1)
                        },
                        movedUp: function ($event) {
                          return _vm.onPageMoved("dilemma", i, -1)
                        },
                        updated: function (data) {
                          return _vm.onPageUpdated("dilemma", i, data)
                        },
                      },
                    }),
                  ],
                  1
                )
              })
            : _vm._e(),
          _vm.expandDilemmaPages
            ? _c(
                "button",
                {
                  staticClass: "btn btn-block btn-primary",
                  on: {
                    click: function ($event) {
                      return _vm.addPage("dilemma")
                    },
                  },
                },
                [_vm._v("Add\n\t\t\tdilemma page\n\t\t")]
              )
            : _vm._e(),
          _c("br"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }