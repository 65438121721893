var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "choice-message",
      class: _vm.format === "sendMessage" ? "choice-message-center" : "",
    },
    [
      _vm.format === "sendMessage"
        ? _c(
            "div",
            { staticClass: "inner-message" },
            [
              _c("p", { staticClass: "send-chat-message" }, [
                _vm._v(_vm._s(_vm.$t("game.sendMessage"))),
              ]),
              _c(
                "vue-markdown",
                { key: _vm.messageKey, staticClass: "chat-message" },
                [_vm._v(_vm._s(_vm.message))]
              ),
            ],
            1
          )
        : _c(
            "vue-markdown",
            { key: _vm.messageKey, staticClass: "inner-message" },
            [_vm._v(_vm._s(_vm.message))]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }