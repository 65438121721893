var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("h1", [_vm._v("Media tester")]),
    _c("div", { staticClass: "form-group" }, [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.test,
              expression: "test",
            },
          ],
          staticClass: "form-control",
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.test = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
          },
        },
        [
          _c("option", { attrs: { value: "" } }, [_vm._v("Select a test")]),
          _c("option", { attrs: { value: "youtube" } }, [_vm._v("youtube")]),
          _c("option", { attrs: { value: "vimeo" } }, [_vm._v("vimeo")]),
          _c("option", { attrs: { value: "image" } }, [_vm._v("image")]),
          _c("option", { attrs: { value: "emojiImageFull" } }, [
            _vm._v("emojiImageFull"),
          ]),
          _c("option", { attrs: { value: "emojiImage" } }, [
            _vm._v("emojiImage"),
          ]),
          _c("option", { attrs: { value: "emojiSingle" } }, [
            _vm._v("emojiSingle"),
          ]),
          _c("option", { attrs: { value: "emojiSingleNoExist" } }, [
            _vm._v("emojiSingleNoExist"),
          ]),
          _c("option", { attrs: { value: "emojiInText" } }, [
            _vm._v("emojiInText"),
          ]),
          _c("option", { attrs: { value: "emojiInTextNoExist" } }, [
            _vm._v("emojiInTextNoExist"),
          ]),
        ]
      ),
    ]),
    _c("div", { staticClass: "well" }, [
      _vm.test === "youtube"
        ? _c(
            "div",
            [
              _c("h2", [_vm._v("Youtube")]),
              _c("media", {
                attrs: {
                  source: "https://www.youtube.com/watch?v=fEvM-OUbaKs",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.test === "vimeo"
        ? _c(
            "div",
            [
              _c("h2", [_vm._v("Vimeo")]),
              _c("media", { attrs: { source: "https://vimeo.com/22439234" } }),
            ],
            1
          )
        : _vm._e(),
      _vm.test === "image"
        ? _c(
            "div",
            [
              _c("h2", [_vm._v("Image")]),
              _c("media", {
                attrs: { source: "theme2/case3/mobilen-bipper-1.png" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.test === "emojiImageFull"
        ? _c(
            "div",
            [
              _c("h2", [_vm._v("Emoji image (full)")]),
              _c("media", {
                attrs: { source: "emojis/emoji-confused-240x240.png" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.test === "emojiImage"
        ? _c(
            "div",
            [
              _c("h2", [_vm._v("Emoji image (Relative)")]),
              _c("media", { attrs: { source: "emoji-poop-240x240.png" } }),
            ],
            1
          )
        : _vm._e(),
      _vm.test === "emojiSingle"
        ? _c(
            "div",
            [
              _c("h2", [_vm._v("Emoji single (exists)")]),
              _c("media", { attrs: { source: "😍" } }),
            ],
            1
          )
        : _vm._e(),
      _vm.test === "emojiSingleNoExist"
        ? _c(
            "div",
            [
              _c("h2", [_vm._v("Emoji single (does not exist)")]),
              _c("media", { attrs: { source: "🙌" } }),
            ],
            1
          )
        : _vm._e(),
      _vm.test === "emojiInText"
        ? _c(
            "div",
            [
              _c("h2", [_vm._v("Emoji in text (exists)")]),
              _c("media", {
                attrs: { source: "This is some text with an emoji 😝" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.test === "emojiInTextNoExist"
        ? _c(
            "div",
            [
              _c("h2", [_vm._v("Emoji in text (does not exist)")]),
              _c("media", {
                attrs: { source: "This is some text with an emoji 🙌" },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }