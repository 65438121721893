<script>
import PlayerType from '@/enum/PlayerType'
import GamePhase from '@/enum/GamePhase'
import {mapGetters} from 'vuex'

export default {
	name: 'room-joiner-mixin',
	methods: {
		connectStudentToRoomCode(roomCode, joinOptions = {}) {
			return new Promise((resolve, reject) => {
				console.log('connectStudentToRoomCode', roomCode, joinOptions)

				if (this.$gameClient.roomId) {
					this.$gameClient.leaveRoom();
				}

				this.$gameClient.joinRoom(roomCode, PlayerType.Student, () => {
					console.log('Joined room - code: ' + roomCode)

                    if (joinOptions && Object.keys(joinOptions).length) {
                        this.$gameClient.send(joinOptions)
                    }

					resolve()
				}, (e) => {
					console.log('Student join reject', e)
					reject(e)
				})
			})
		},
		connectTeacherToNewRoom(joinOptions = {}) {
			console.log('connectTeacherToNewRoom', joinOptions)

			return this.connectTeacherToRoomCode(null, joinOptions)
		},
		connectTeacherToRoomCode(roomCode, joinOptions = {}) {
			if (this.$gameClient.roomId) {
				this.$gameClient.leaveRoom();
			}

			if (!roomCode) {
				roomCode = this.generateRoomCode()
			}

			console.log('connectTeacherToRoomCode', roomCode, joinOptions)

			return new Promise((resolve, reject) => {
				let afterJoinOptions = {
					_currentPageIndex: 0,
					_phase: GamePhase.Setup,
				}

				if (joinOptions && Object.keys(joinOptions).length) {
					for (let key in joinOptions) {
                        afterJoinOptions[key] = joinOptions[key]
					}
				}

				this.$gameClient.joinOrCreateRoom(roomCode, PlayerType.Teacher, () => {
					console.log('Joined room - code: ' + roomCode)

                    if (afterJoinOptions && Object.keys(afterJoinOptions).length) {
                        this.$gameClient.send(afterJoinOptions);
                    }

					resolve()
				}, (e) => {
					console.log('Teacher join reject', e)
					reject(e)
				})
			})
		},
		generateRoomCode() {
			let characters = '123456789'
			let characterLength = characters.length
			let roomIdLength = this.features.roomIdLength

			let roomId = ''

			for (let i = 0; i < roomIdLength; i++) {
				roomId += characters.substr(Math.floor(Math.random() * characterLength), 1)
			}

			console.log('roomId', roomId)

			return roomId
		},
	},
	computed: {
		...mapGetters({
			features: 'getFeatures',
			userInfo: 'getUserInfo',
		}),
	},
}
</script>
