var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.showVote
      ? _c(
          "div",
          { staticClass: "container vote-container" },
          [
            _c("no-drag-image", {
              staticClass: "choice-background",
              attrs: { src: require("@/_assets/img/misc/meeting-room.png") },
            }),
            !(_vm.showUserVote && _vm.currentPagePhase === 1)
              ? _c("div", { staticClass: "blue-overlay" })
              : _vm._e(),
            _vm.currentPage.title
              ? _c("h1", [_vm._v(_vm._s(_vm.currentPage.title))])
              : _vm._e(),
            _vm.choiceComponentOptions.text
              ? _c("p", [_vm._v(_vm._s(_vm.choiceComponentOptions.text))])
              : _vm._e(),
            _vm.showUserVote && _vm.currentPagePhase === 1
              ? _c("div", [
                  _c("div", { staticClass: "user-input-field" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.userVote,
                          expression: "userVote",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        placeholder:
                          _vm.momentType === "moment"
                            ? _vm.$t("misc.writeMomentPlaceholder")
                            : _vm.$t("misc.writeDilemmaPlaceholder"),
                      },
                      domProps: { value: _vm.userVote },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.userVote = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "bottom-bar-send-answer",
                      class:
                        _vm.showUserVote && _vm.currentPagePhase === 1
                          ? "opaque-bottom-bar"
                          : "",
                    },
                    [
                      _c("NotchHeader", { attrs: { color: "blue" } }),
                      _c(
                        "app-button",
                        {
                          attrs: { enabled: _vm.userVote !== "" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.addUserVote.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(
                                _vm.momentType === "moment"
                                  ? _vm.$t("game.sendMoment")
                                  : _vm.$t("game.sendDilemma")
                              ) +
                              "\n\t\t\t\t\t"
                          ),
                        ]
                      ),
                      _vm.timerTimeTotal
                        ? _c(
                            "div",
                            { staticClass: "timer-container" },
                            [
                              _c("timer-solid", {
                                attrs: {
                                  "time-remaining": _vm.timerTimeRemaining,
                                  "time-total": _vm.timerTimeTotal,
                                  scale: 0.5,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.currentPagePhase !== 1
              ? _c(
                  "div",
                  [
                    _c("choices", {
                      staticClass: "choices",
                      attrs: {
                        "choice-type":
                          _vm.choiceComponentOptions &&
                          _vm.choiceComponentOptions.choiceType
                            ? _vm.choiceComponentOptions.choiceType
                            : "multipleChoice",
                        "instant-selection": false,
                        randomize: true,
                        selectable: true,
                        "player-type": "student",
                        type: "vertical",
                        choices: _vm.choices,
                        value: _vm.activeChoiceIndexes,
                      },
                      on: {
                        value: function ($event) {
                          _vm.activeChoiceIndexes = $event
                        },
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "bottom-bar-send-answer" },
                      [
                        _c("NotchHeader", { attrs: { color: "blue-solid" } }),
                        _c(
                          "app-button",
                          {
                            attrs: { enabled: _vm.canSendVote },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.onClickAnswer.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("game.sendAnswer")) + "\n\t\t\t\t\t"
                            ),
                          ]
                        ),
                        _vm.timerTimeTotal
                          ? _c(
                              "div",
                              { staticClass: "timer-container" },
                              [
                                _c("timer-solid", {
                                  attrs: {
                                    "time-remaining": _vm.timerTimeRemaining,
                                    "time-total": _vm.timerTimeTotal,
                                    scale: 0.5,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      : _vm.showWeightMap
      ? _c(
          "div",
          { staticClass: "container weight-map-interactive-container" },
          [
            _c("interactive-weight-map", {
              attrs: {
                interactive: true,
                isFirst: _vm.showWeightMapInput1,
                momentType: _vm.momentType,
                momentsHiddenInitially: _vm.momentsHiddenInitially,
              },
              on: { submit: _vm.onSubmit },
            }),
          ],
          1
        )
      : _c("div", [
          _c(
            "div",
            { staticClass: "text-container" },
            [
              _c("NotchHeader", { attrs: { color: "blue" } }),
              _c("p", { staticClass: "case-info" }, [
                _vm._v("\n\t\t\t\t\t" + _vm._s(_vm.$t("game.stayTuned")) + " "),
                _c("br"),
                _c("br"),
                _vm._v(
                  "\n\t\t\t\t\t" +
                    _vm._s(_vm.$t("game.studentWaiting")) +
                    "\n\t\t\t\t"
                ),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "case-image-container" }, [
            _c("div", { staticClass: "case-image", style: _vm.caseImageStyle }),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }