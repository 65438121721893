var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dev-wrapper" },
    [
      _c("div", { staticClass: "ui" }, [
        _c("div", [
          _c("strong", [_vm._v("Current point:")]),
          _c("span", {
            domProps: { textContent: _vm._s(_vm.point.x + ", " + _vm.point.y) },
          }),
        ]),
        _c("div", [
          !_vm.interactive
            ? _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-primary",
                  on: {
                    click: function ($event) {
                      _vm.interactive = true
                    },
                  },
                },
                [_vm._v("Make\n\t\t\t\t\tinteractive\n\t\t\t\t")]
              )
            : _vm._e(),
          _vm.interactive
            ? _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-primary",
                  on: {
                    click: function ($event) {
                      _vm.interactive = false
                    },
                  },
                },
                [_vm._v("Make non\n\t\t\t\t\tinteractive\n\t\t\t\t")]
              )
            : _vm._e(),
        ]),
      ]),
      _c("interactive-weight-map", {
        attrs: {
          interactive: _vm.interactive,
          isFirst: true,
          point: _vm.point,
          momentType: _vm.moment,
          momentsHiddenInitially: false,
          tokenType: "bothSameTime",
        },
        on: {
          interactiveChanged: _vm.onInteractiveChanged,
          pointChanged: _vm.onPointChanged,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }