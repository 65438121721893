<template>
	<div :style="{ width: codeContainerWidth }" class="emoji-code-container">
		<div class="emoji-code-picker-container">
			<div v-for="(row, rowIndex) in rowCount" class="emoji-code-picker-row row-fluid">
				<single-emoji-code
					v-for="(column, columnIndex) in columnCount"
					:key="((rowIndex * columnCount) + column)"
					:active="codeParts[activeEmojiIndex] === ((rowIndex * columnCount) + column)"
					:code="((rowIndex * columnCount) + column)"
					v-on:click.native="selectEmojiCode((rowIndex * columnCount) + column)"/>
			</div>
		</div>
	</div>
</template>

<script>
import SingleEmojiCode from './single-emoji-code'
import emojis from './emojis.js'
import {mapGetters} from 'vuex'
import EmojiCodeCurrent from '@/components/emoji-code/current-code'

export default {
	name: 'emoji-code',
	components: {
		EmojiCodeCurrent,
		SingleEmojiCode,
	},
	props: {
		type: {
			type: String,
			required: false,
			default: 'default',
		},
		value: {
			type: String,
			required: true,
		},
		activeEmojiIndex: {
			type: Number,
			default: 0,
		},
		emojiLength: {
			type: Number,
			default: 4,
		},
	},
	computed: {
		...mapGetters({
			features: 'getFeatures',
		}),
		codeContainerWidth() {
			let codeContainerWidth = 15 + (60 * this.emojiLength)

			return codeContainerWidth + 'px'
		},
		triangleLeft() {
			let totalWidth = this.codeContainerWidth.replace(/px$/, '')
			let triangleWidth2 = 15
			let percent = this.activeEmojiIndex / (this.emojiLength - 1)

			let padding = 40
			let startX = padding
			let endX = totalWidth - padding

			let triangleLeft = startX + ((endX - startX) * percent) - triangleWidth2

			return triangleLeft + 'px'
		},
	},
	data() {
		// Cast all values to numbers
		return {
			rowCount: 3,
			columnCount: 3,
			emojis: emojis,
			codeParts: [],
		}
	},
	watch: {
		value(newValue) {
			this.codeParts = this.getCodePartsFromValue(newValue)
		},
	},
	mounted() {
		this.codeParts = this.getCodePartsFromValue(this.value)
	},
	methods: {
		getCodePartsFromValue(value) {
			while (value.length < this.emojiLength) {
				value += '0'
			}

			let codeParts = value.split(/(?=.)/g)

			// Cast all values to numbers
			codeParts = codeParts.map(Number)

			return codeParts
		},
		openEmojiSelector(index) {
			this.$emit('activeEmojiIndexChange', index)
		},
		selectEmojiCode(emojiCode) {
			let emojiCodeEmptyCountPre = this.codeParts.filter(code => code === 0).length

			this.$set(this.codeParts, this.activeEmojiIndex, emojiCode)

			console.log('selectEmojiCode', emojiCode, this.codeParts)
			this.$emit('input', this.codeParts.join(''))

			// Iterate through each codePart and count how many "0"'s are found and check if the total count is 0
			let emojiCodeEmptyCountPost = this.codeParts.filter(code => code === 0).length

			if (emojiCodeEmptyCountPre !== emojiCodeEmptyCountPost) {
				this.$emit('fillOutStatusChanged', emojiCodeEmptyCountPost === 0)
			}

			if (this.activeEmojiIndex < this.codeParts.length) {
				// If the next one is empty
				let nextEmojiIndex = this.activeEmojiIndex + 1

				if (this.codeParts[nextEmojiIndex] || this.codeParts[nextEmojiIndex] === 0) {
					this.$emit('activeEmojiIndexChange', nextEmojiIndex)
				}
			}
		},
	},
}
</script>
<style lang="less" scoped>
@import "~@less/mixins/less-triangle.less";
@import "~@less/variables.less";

.emoji-code-container {
	width: 100%;
	text-align: center;
	margin: 0 auto;

	.emoji-code {
		cursor: pointer;
		transition: all 0.2s ease-in-out;

		&:hover, &.emoji-code-active {
			transform: scale(1.1);
		}
	}
}

.emoji-code-picker-container {
	margin: 20px auto 20px auto;
}

.emoji-code-picker-container,
.emoji-code-picker {
	width: 100%;
}

.emoji-code-picker-row {
	margin-bottom: 8px;
}

.emoji-code-picker {
	.emoji-code-picker-emojis-inside {
		width: 225px;
	}

	.emoji-code-picker-header {
		position: relative;
		top: -15px;

		.emoji-code-picker-header-triangle {
			.triangle(up, 15px, @color-black);
			position: absolute;
			transition: all 0.2s ease-in-out;

			&.emoji-code-picker-header-triangle-0 {
				left: 10px;
			}

			&.emoji-code-picker-header-triangle-1 {
				left: 70px;
			}

			&.emoji-code-picker-header-triangle-2 {
				left: 130px;
			}

			&.emoji-code-picker-header-triangle-3 {
				left: 190px;
			}
		}
	}

	.emoji-code-picker-emojis {
		border-radius: 10px;
		padding: 10px 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		width: 100%;

		.emoji-code-picker-emoji {
			position: relative;
			display: inline-block;
			width: 50px;
			height: 50px;
			margin: 10px;
			transition: all 0.2s ease-in-out;
			cursor: pointer;
			border: 2px solid transparent;

			&:hover, &.emoji-code-active {
				border: 2px solid @main-color !important;
			}
		}
	}
}
</style>
