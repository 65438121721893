<template>
	<div :class="[selectable ? 'choices-selectable' : '', 'choices-player-type-' + this.playerType]" class="choices">
		<div v-if="choiceType === 'multipleChoice' || choiceType === 'multipleAnswers'" :class="innerContainerClass"
			 class="choice-wrapper">
			<div
				v-for="(choice, i) in choicesOrdered"
				v-bind:key="i"
				:class="getChoiceClass(i)"
				class="choice-container choice-container-multiple-choice"
			>
				<choice
					:class="'choice-' + i"
					:data="{ globalScore: choice.choice.globalScore }"
					:format="choice.format || 'text'"
					:index="choice.index"
					:message="choice.choice.message || choice.choice.text"
					:showResults="showResults"
					:toggled="activeChoiceIndexes && activeChoiceIndexes.includes(choice.index)"
					:voteCount="showResults ? choice.value : -1"
					class="choice"
					v-on:click.native="onChoiceClick(choice.index)"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Choice from './choice'
import {mapGetters} from 'vuex'

export default {
	props: {
		playerType: {
			type: String,
			default: 'teacher',
		},
		selectable: {
			type: Boolean,
			required: false,
			default: false,
		},
		choices: {
			type: [Object, Array],
			required: true,
		},
		value: {},

		instantSelection: {
			type: Boolean,
			required: false,
			default: false,
		},

		randomize: {
			type: Boolean,
			required: false,
			default: false,
		},

		type: {
			type: String,
			default: 'vertical',
		},
		specificChoice: {
			type: Boolean,
			default: false,
		},
		specificId: {
			type: Number,
			required: false,
		},
		specificVoteCount: {
			type: Number,
			required: false,
		},
		choiceType: {
			type: String,
			default: 'multipleChoice',
		},
		voteCounts: {
			type: Object,
			default: null,
			required: false,
		},
		showResults: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		Choice,
	},
	data() {
		return {
			activeChoiceIndexes: [],
		}
	},
	watch: {
		value() {
			this.recomputeFromValue()
		},
		choices: {
			deep: true,
			handler() {
				this.resetChoice()
			},
		},
		currentPageIndex() {
			this.resetChoice()
		},
		currentPagePhase() {
			this.resetChoice()
		},
	},
	mounted() {
		this.recomputeFromValue()
		this.resetChoice()
	},
	methods: {
		recomputeFromValue() {
			if (this.value instanceof Array) {
				this.activeChoiceIndexes = this.value
			} else {
				this.activeChoiceIndexes = [this.value]
			}

			console.log('recomputeFromValue', this.value, this.activeChoiceIndexes)
		},
		onChoiceClick(clickedChoiceIndex) {
			if (!this.selectable) {
				return
			}

			if (this.choiceType === 'likertScale' || this.choiceType === 'multipleChoice') {
				// Only one result!
				this.activeChoiceIndexes = [clickedChoiceIndex]
			} else if (this.choiceType === 'multipleAnswers') {
				// Toggle it
				if (this.activeChoiceIndexes.includes(clickedChoiceIndex)) {
					// Toggle it off
					this.activeChoiceIndexes = this.activeChoiceIndexes.filter(item => item !== clickedChoiceIndex)
				} else {
					// Toggle it on
					this.activeChoiceIndexes.push(clickedChoiceIndex)
				}
			}

			if (this.showResults) {
				this.$emit('pickChoice', clickedChoiceIndex)
			}

			this.$emit('value', this.activeChoiceIndexes)

			if (this.instantSelection) {
				this.$emit('submit', this.activeChoiceIndexes)
			}
		},
		getChoiceClass(i) {
			let classes = []

			if (this.activeChoiceIndexes.includes(i)) {
				classes.push('choice-container-active')
			}

			if (this.type === 'horizontal') {
				classes.push('col-3')
			} else {
				classes.push('row')
			}

			return classes
		},
		resetChoice() {
			this.activeChoiceIndexes = this.choiceType === 'likertScale' ? [0] : []
			this.$emit('value', this.activeChoiceIndexes)

			console.log('resetChoice', this.activeChoiceIndexes)
		},
	},
	computed: {
		...mapGetters({
			currentPageIndex: 'getCurrentPageIndex',
			currentPagePhase: 'getCurrentPagePhase',
		}),
		containerClass() {
			let classes = []

			if (this.selectable) {
				classes.push('choices-selectable')
			}

			if (this.type === 'horizontal') {
				classes.push('row')
				classes.push('justify-content-left')
			} else {
				classes.push('container')
			}
			return classes
		},
		innerContainerClass() {
			let classes = []

			if (this.selectable) {
				classes.push('choices-selectable')
			}

			if (this.type === 'horizontal') {
				classes.push('row')
				classes.push('justify-content-left')
			}

			classes.push('choice-wrapper-' + this.choiceType)

			return classes
		},
		choicesOrdered() {

			// orders choices but keeps index
			let choiceObject = []

			if (this.specificChoice && this.specificId) {
				console.log(this.specificVoteCount)
				choiceObject.push({choice: this.choices[0], index: this.specificId, value: this.specificVoteCount})
				return choiceObject
			}

			for (let i = 0; i < this.choices.length; i++) {
				if (this.voteCounts === null) {
					choiceObject.push({choice: this.choices[i], index: i})
				} else {
					choiceObject.push({choice: this.choices[i], index: i, value: this.voteCounts[i]})
				}
			}

			// randomize list of choices
			if (this.randomize) {

				let shuffledChoiceObject = choiceObject.map((value) => ({value, sort: Math.random()})).sort(
					(a, b) => a.sort - b.sort).map(({value}) => value)

				return shuffledChoiceObject

			}

			// if no votecounts exists, return base list
			if (!this.showResults && !this.voteCounts) {
				return choiceObject
			}

			// orders choices based on highest votes
			choiceObject.sort(function (a, b) {
				return b.value - a.value
			})

			return choiceObject
		},
	},
}
</script>

<style lang="less">
@import "~@less/variables.less";

.teacher-container {
	.choices {
		margin: -14px auto 0;
	}
}

.choices {
	padding-left: 140px;
	padding-right: 140px;
	margin-top: 20px;

	&.choices-player-type-student {
		padding-left: 20px;
		padding-right: 20px;
	}

	&.choices-selectable {
		.choice {
			cursor: pointer;

			&:hover {
				// background: darken(@whiteish, 5%);
			}
		}
	}

	.choice-container {
		position: relative;
		margin-bottom: 10px;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: flex-start;
		align-content: stretch;

		&.choice-container-active {
			.choice {
				outline-offset: -4px;
			}
		}
	}

	.choice {
		display: flex;
		width: 100%;
		background-color: @color-white;
		z-index: 10;

		.bookmark {
			background-color: @grey-light;
			margin: 0;
			padding: 0;
			width: 50px;
		}

		.content-container {
			.content {
				margin: 0 auto;
			}
		}

		// choice-INDEX (0 indexed)
		&.choice-0 .bookmark {
			background: @choice-color-1;
			color: white;
		}

		&.choice-1 .bookmark {
			background: @choice-color-2;
			color: white;
		}

		&.choice-2 .bookmark {
			background: @choice-color-3;
			color: white;
		}

		&.choice-3 .bookmark {
			background: @choice-color-4;
			color: white;
		}
	}
}
</style>
