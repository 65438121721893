<template>
	<div class="container student-container">
		<header class="top-bar d-flex justify-content-between align-items-center">
			<!--<div class="d-flex">
			  <breadcrumb :to="breadcrumbTo" :useOffset="false" class="mr-5"
					id="header-breadcrumb" v-if="breadcrumbTo && currentPage !== 'pages'"/>-
			  <div class="d-flex align-items-center" v-if="currentPage == 'pages'">
				<emoji-code class="room-code" type="tiny-transparent"/>
			  </div>
			</div>-->
			<!--<div class="theme-name">{{ currentCaseTitle }}</div>
			<img class="logo" src="~@img/misc/logo-mini.png"/>-->
			<!--<fullscreen-toggle-button id="fullscreen-toggle-button"/>-->
		</header>
		<transition :name="componentTransition">
			<component :is="currentPage" class="student-page"></component>
		</transition>
        <button @click="disconnect" id="btn-disconnect" class="btn btn-success btn-sm" v-if="showDisconnectButton">Disconnect</button>
	</div>
</template>

<script>
import * as extend from 'extend'

import ConnectPage from './flow/0-connect'
import LobbyPage from './flow/1-lobby'
import GamePage from './flow/2-game'

import {ConsoleLogger} from '@/console-logger'
import {$eBus} from '@/event-bus'

import * as MutationTypes from '@/store/mutation-types'

import PlayerType from '@/enum/PlayerType'
import GameClientEvent from '@/enum/GameClientEvent'
import GamePhase from '@/enum/GamePhase'
import GameType from '@/enum/GameType'
import GameClientType from '@/enum/GameClientType'
import AppConfig from '@/app-config'
import {getStudentUrl} from '@/utils/UrlUtil'
import {mapGetters} from 'vuex'

const pageComponents = {
	'connect': ConnectPage,
	'lobby': LobbyPage,
	'game': GamePage,
}

const components = {}

export default {
	components: extend(components, pageComponents),
	data() {
		return {
			componentTransition: AppConfig.componentTransition,
			currentPage: 'connect',
			logger: new ConsoleLogger('StudentController', 'background: #00aaaa; color: white', null,
				!AppConfig.debug.studentController),
		}
	},
	computed: {
		...mapGetters({
			currentTheme: 'getCurrentTheme',
			currentCase: 'getCurrentCase',
		}),
        showDisconnectButton() {
		    return false;
        },
		currentCaseTitle() {
			if (!this.currentCase) {
				return
			}

			return this.currentCase.title
		},
	},
	watch: {
		'$route'(to, from) {
			this.reloadCurrentPage(to)
		},
	},
	methods: {
	    disconnect() {
	        console.log('Disconnecting..');

            this.$gameClient.disconnect();
        },
		onRoomStateChanged(room) {
			let roomState = room && room.state ? room.state : {}

			if (roomState.hasOwnProperty('currentPageIndex') && roomState.currentPageIndex >= 0) {
				this.$store.commit(MutationTypes.SET_CURRENT_PAGE_INDEX, roomState.currentPageIndex)
			}

			if (roomState.hasOwnProperty('pagePhase')) {
				this.$store.commit(MutationTypes.SET_CURRENT_PAGE_PHASE, roomState.pagePhase)
			}

			if (roomState.hasOwnProperty('phase')) {
				if (roomState.phase === GamePhase.Lobby) {
					if (roomState.pageType !== 'roleplay') {
						// Roleplay always stays in lobby, so let's not redirect if you're here

						if (this.$route.path !== getStudentUrl('lobby')) {
							this.$router.push(getStudentUrl('lobby'))

						}
					}
				}
			}
		},
		onRoomLeft(room) {
			if (this.$route.path !== getStudentUrl('connect')) {
				this.$router.push(getStudentUrl('connect'))
			}
		},
		reloadCurrentPage(route) {
			let currentPage = route.params.page

			if (!currentPage || !components.hasOwnProperty(currentPage)) {
				this.logger.logMessage(`Unknown StudentPage "${currentPage}"`)

				this.$router.push(getStudentUrl('connect'))

				return
			}

			this.logger.logMessage(`StudentPage changed to "${currentPage}"`)

			this.currentPage = currentPage
		},
	},
	mounted() {
		this.$store.commit(MutationTypes.SET_PLAYER_TYPE, PlayerType.Student)
		this.$store.commit(MutationTypes.SET_GAME_CLIENT_TYPE, GameClientType.Online)
		this.$store.commit(MutationTypes.SET_GAME_TYPE, GameType.ClassRoom)

		$eBus.$on(GameClientEvent.RoomStateChange, this.onRoomStateChanged)
		$eBus.$on(GameClientEvent.RoomLeave, this.onRoomLeft)

		this.reloadCurrentPage(this.$router.currentRoute)
	},
	beforeDestroy() {
		$eBus.$off(GameClientEvent.RoomStateChange, this.onRoomStateChanged)
		$eBus.$off(GameClientEvent.RoomLeave, this.onRoomLeft)
	},
}
</script>
<style lang="less" scoped>
@import "~@less/variables.less";

.student-container {
	position: relative;
	height: 100%;
	width: 100%;
	padding-left: 0;
	padding-right: 0;
}

.top-bar {
	// padding: 10px 15px;
	background: @color-background-light-grey;
}

.theme-name {
	font-weight: 800;
}

.content-container {
	padding: 0 15px;
}

.student-page {
	padding: 0;
}

.grey-background {
	background: @color-background-light-grey;
}

#btn-disconnect {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
}
</style>
