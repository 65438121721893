var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-info card text-center" }, [
    _c("div", { staticClass: "card-header" }, [
      _c("ul", { staticClass: "nav nav-pills card-header-pills nav-fill" }, [
        _c(
          "button",
          {
            staticClass: "nav-item btn nav-link",
            class: _vm.activeSection === "room" ? "active" : "",
            on: {
              click: function ($event) {
                _vm.activeSection = "room"
              },
            },
          },
          [
            _vm._v("Room\n\t\t\t\t"),
            _vm.roomId
              ? _c("div", { staticClass: "room-id badge badge-dark" }, [
                  _vm._v(_vm._s(_vm.roomId)),
                ])
              : _vm._e(),
          ]
        ),
        _c(
          "button",
          {
            staticClass: "nav-item btn nav-link",
            class: _vm.activeSection === "players" ? "active" : "",
            on: {
              click: function ($event) {
                _vm.activeSection = "players"
              },
            },
          },
          [
            _vm._v("\n\t\t\t\tPlayers\n\t\t\t\t"),
            _c("div", { staticClass: "player-id badge badge-dark" }, [
              _c("i", { staticClass: "fas fa-user" }),
              _vm._v(" " + _vm._s(_vm.playerCount)),
            ]),
          ]
        ),
        _c(
          "button",
          {
            staticClass: "nav-item btn nav-link",
            class: _vm.activeSection === "log" ? "active" : "",
            on: {
              click: function ($event) {
                _vm.activeSection = "log"
              },
            },
          },
          [_vm._v("\n\t\t\t\tLog\n\t\t\t")]
        ),
      ]),
    ]),
    _vm.activeSection === "room"
      ? _c(
          "div",
          [
            _c("ThingToHtml", {
              staticClass: "room-state-thing",
              attrs: { button: false, thing: _vm.roomState, theme: "dark" },
            }),
          ],
          1
        )
      : _vm.activeSection === "players"
      ? _c(
          "div",
          [_c("player-slots", { on: { editPlayer: _vm.editPlayer } })],
          1
        )
      : _vm.activeSection === "log"
      ? _c("div", [
          _c("h2", [
            _vm._v("Log\n\t\t\t"),
            _c(
              "button",
              {
                staticClass: "btn btn-danger",
                attrs: { type: "button" },
                on: { click: _vm.clearLog },
              },
              [_vm._v("Clear log")]
            ),
          ]),
          _c(
            "div",
            { staticClass: "container" },
            _vm._l(_vm.logMessages, function (logMessage) {
              return _c(
                "div",
                { staticClass: "log-item row" },
                [_c("log-item", { attrs: { message: logMessage } })],
                1
              )
            }),
            0
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }