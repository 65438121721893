<template>
	<div class="form-group row align-items-center my-2">
		<label class="col-3 form-label">{{ label }}</label>
		<div class="col-9">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'form-row',
	props: {
		label: {
			type: String,
		},
	},
}
</script>

<style lang="less" scoped>
.form-label {
	font-size: 18px;
	margin: 0;
}

.form-control, .form-control-file {
	height: 34px;
	font-size: 14px;
}
</style>
