var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-button-container" },
    [
      _vm.to && _vm.enabled
        ? _c(
            "router-link",
            {
              staticClass: "app-button",
              class: _vm.buttonClasses,
              attrs: {
                "aria-label": _vm.label,
                tabindex: 0,
                to: _vm.to,
                role: "button",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("onClick")
                },
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "space", 32, $event.key, [
                      " ",
                      "Spacebar",
                    ])
                  ) {
                    return null
                  }
                  return _vm.$emit("onClick")
                },
              },
            },
            [
              _c(
                "span",
                { staticClass: "app-button-text" },
                [_vm._t("default")],
                2
              ),
            ]
          )
        : _c(
            "button",
            {
              staticClass: "app-button",
              class: _vm.buttonClasses,
              attrs: { "aria-label": _vm.label, tabindex: 0, role: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("onClick")
                },
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "space", 32, $event.key, [
                      " ",
                      "Spacebar",
                    ])
                  ) {
                    return null
                  }
                  return _vm.$emit("onClick")
                },
              },
            },
            [
              _c(
                "span",
                { staticClass: "app-button-text" },
                [_vm._t("default")],
                2
              ),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }