<template>
	<div class="container">
		<transition mode="out-in" name="fade">
			<div v-if="loading" key="loadingCase" class="transition-root">
				<div class="row">
					<loading-indicator>{{ $t('room.loading') }}..</loading-indicator>
				</div>
			</div>
			<div v-if="starting" key="startingCase" class="transition-root">
				<div class="row">
					<loading-indicator>{{ $t('room.connecting') }}..</loading-indicator>
				</div>
			</div>
			<div v-if="!loading && !starting" key="notLoading" class="transition-root">
				<div v-if="caseImageUrl" class="case-image-container">
					<div :style="caseImageStyle" class="case-image"/>
				</div>
				<div class="instruction-wrapper">
					<NotchHeader :color="'blue'"/>
					<vue-markdown v-if="caseDescription">{{ caseDescription }}</vue-markdown>
				</div>
				<div class="bottom-bar bottom-bar-lobby">
					<counter :current="studentCount" class="student-counter" slot-location="affix">
						{{ $t('room.areReady') }}
					</counter>
					<div class="bottom-bar-text text-center">


						<app-button v-on:onClick="onClickStart">
							{{ $t('misc.start') }}
						</app-button>
					</div>

					<div class="login-info">
						<div class="small-qr-code" @click="openBigQrCode">
							<qr-code v-if="studentUrlSimple" :scale="5" :value="studentUrlSimple"/>
						</div>
						<div class="room-info-url-emoji">
							<emoji-code :active-emoji-index="-1" :auto-width="true" :show-triangle="false" type="tiny"/>
							<div class="student-url">{{ studentUrlSimple }}</div>
						</div>

					</div>
					<!--<qr-code v-if="studentUrl" :value="studentUrl" :scale="4" />-->
				</div>
			</div>
		</transition>
	</div>
</template>

<script>


import GamePhase from '@/enum/GamePhase'
import LoadingIndicator from '@/components/loading-indicator'
import EmojiCode from '@/components/emoji-code'
import Counter from '@/components/counter'
import * as MutationTypes from '@/store/mutation-types'
import AppButton from '@/components/app-button/index'
import Media from '@/components/media'
import {mapGetters} from 'vuex'
import {getTeacherUrl} from '@/utils/UrlUtil'
import VueMarkdown from 'vue-markdown'
import PagePhase from '@/enum/PagePhase'
import MediaManager from '@/media-manager'
import AppConfig from '@/app-config'
import QrCode from '@/components/qr-code/index'
import NotchHeader from '@/components/notch-header'

export default {
	components: {
		QrCode,
		AppButton,
		EmojiCode,
		LoadingIndicator,
		Counter,
		Media,
		VueMarkdown,
		NotchHeader,
	},
	data() {
		return {
			loading: true,
			starting: false,
			roomId: null,
			case: null,
			pages: {
				dilemma: [],
			},
		}
	},
	mounted() {
		if (!this.currentCase) {
			console.log('Current case missing')
			if (this.$gameClient) {
				this.closeRoom()
			}
			this.$router.push(getTeacherUrl('select-case'))
			return
		}

		this.$gameClient.send({
			_phase: GamePhase.Lobby,
			_pagePhase: PagePhase.Normal,
			_currentPageIndex: -1,
			_pageType: 'dilemma',
		})

		this.roomId = this.$gameClient.room.id
		let caseId = this.currentCase.id

		this.$apiClient.getCase(caseId, (_case) => {
			this.case = _case

			this.$apiClient.getCasePages(caseId, 'dilemma', (pages) => {
				this.pages.dilemma = pages
				this.loading = false
				this.selectPageType('dilemma')
			})
		})

		this.$store.commit(MutationTypes.SET_BREADCRUMB_TO, () => {
			//When teacher leaves, all students are kicked by the server
			if (this.$gameClient) {
				this.closeRoom()
			}

			this.$router.push(getTeacherUrl('intro'))
		})
	},
	computed: {
		...mapGetters({
			currentCase: 'getCurrentCase',
		}),
		caseTitle() {
			return this.currentCase ? this.currentCase.title : ''
		},
		caseDescription() {
			return this.currentCase ? this.currentCase.description : ''
		},
		caseImageUrl() {
			return this.currentCase ? MediaManager.getMediaUrl(this.currentCase.image) : null
		},
		caseImageStyle() {
			if (!this.caseImageUrl) {
				return
			}

			return {
				'background-image': `url("${this.caseImageUrl}")`,
			}
		},
		studentCount() {
			return this.$gameClient.room.state.studentCount || 0
		},
		studentUrl() {
			return AppConfig.studentUrl
		},
		studentUrlSimple() {
			return AppConfig.studentUrlSimple
		},
	},
	methods: {
		selectPageType(type) {
			this.selectedType = type
			this.case.pages = this.pages[type]
			this.$store.commit(MutationTypes.SET_CURRENT_CASE, this.case)
			this.$gameClient.send({
				_pageType: type,
			})
		},
		closeRoom() {
			//When teacher leaves, all students are kicked by the server
			this.$gameClient.leaveRoom()
			this.$store.dispatch({type: 'fullReset'})
		},
		onClickStart() {
			if (this.starting) {
				return
			}

			this.starting = true

			console.log('Changing game phase to GamePhase.Pages')

			this.$gameClient.send({
				_phase: GamePhase.Pages,
				_currentPageIndex: 0,
			})
		},
		openBigQrCode() {
			this.$store.commit(MutationTypes.SET_SHOW_BIG_QR_CODE, true)
		},
        openHelp() {
            this.$store.commit(MutationTypes.SET_SHOW_HELP, true)
        },
	},
}
</script>

<style lang="less" scoped>
@import "~@less/variables.less";
@import "~@less/font-sizes.less";

@bottomBarHeight: 188px;

.student-url-container {
	position: fixed;
	bottom: 50px;
	left: 50px;
}

.student-url {
	font-size: @font-size-sm;
	font-weight: 0;
	color: @color-white;
	display: block;
}

.bottom-bar.bottom-bar-lobby {
	background: @color-purple;
	color: @white;
	height: @bottomBarHeight;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding-top: 80px;
	opacity: 0.8;

	.bottom-bar-text {
		position: absolute;
		left: 50%;
		bottom: 30px;
		transform: translate(-50%, 0);
	}

	.student-counter {
		position: absolute;
		left: 50%;
		top: 0;
	}

	/*.qr-code {
	  position: absolute;
	  right: 30px;
	  bottom: 30px;
	}*/

	.login-info {
		position: absolute;
		bottom: 25px;
		left: 80px;

		.room-info-url-emoji {
			margin-left: 10px;

			.student-url {
				color: white;
				font-size: 14px;
			}
		}

		.small-qr-code {
			cursor: pointer;
			z-index: 1000;

			position: relative;
			left: 10px;
			margin-bottom: 10px;

			width: 190px;
			height: 190px;
		}

		/*.student-url {
		  color: white;
		  width: 250px;
		  font-size: 14px;
		  text-align: center;
		}*/
	}
}

.case-image-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	padding-bottom: @bottomBarHeight;
}

.case-image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center center;
}

.instruction-wrapper {
	background: rgba(0, 18, 51, 0.8);
	//margin-top: -30px;
	margin-top: 20px;
	color: @white;
	width: calc(32vw);
	position: relative;
	// left: 3rem;
	//left: 50%;
	margin-left: auto;
	margin-right: auto;
	padding: 15px;
}

</style>
