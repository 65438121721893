var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canFullscreen
    ? _c(
        "div",
        {
          staticClass: "fullscreen-toggle-button",
          attrs: { "aria-label": _vm.ariaLabel, role: "button" },
          on: {
            click: function ($event) {
              return _vm.toggleFullscreen()
            },
          },
        },
        [
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.fullscreenEnabled
              ? _c(
                  "button",
                  {
                    key: "contract",
                    attrs: { "aria-label": _vm.ariaLabel, role: "button" },
                  },
                  [
                    _c("img", {
                      attrs: {
                        alt: "",
                        src: require("@img/misc/fullscreen-contract.png"),
                      },
                    }),
                  ]
                )
              : _c(
                  "button",
                  {
                    key: "expand",
                    attrs: { "aria-label": _vm.ariaLabel, role: "button" },
                  },
                  [
                    _c("img", {
                      attrs: {
                        alt: "",
                        src: require("@img/misc/fullscreen-expand.png"),
                      },
                    }),
                  ]
                ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }