<template>
	<div :class="calculatedClass"/>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
	computed: {
		...mapGetters({
			accent: 'getBackgroundAccent',
			playerType: 'getPlayerType',
		}),
		calculatedClass() {
			return [
				'fixed-screen',
				'background-accent-' + this.accent,
				'background-player-type-' + this.playerType,
			]
		},
	},
}
</script>

<style lang="less" scoped>
@import "~@less/variables.less";

.gradient-bg(@col) {
	background: linear-gradient(225deg, tint(@col, 20%) 0%, shade(@col, 10%) 100%);
}

.fixed-screen {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.background {
	&-accent {
		&-primary {
			background: @background-color;
			//.gradient-bg(@teal)
		}

		&-secondary {
			background: @background-color;
			//.gradient-bg(@tomato)
		}

		&-white {
			background: white !important;
			//.gradient-bg(@whiteish)
		}

		&-grey {
			background: @weight-map-background;
		}
	}

	&-player-type {
		&-1 {
			// Teacher
		}

		&-2 {
			// Student
			// background-color: @color-black;
			// opacity: 0.1;
		}
	}
}
</style>
