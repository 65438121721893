var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("transition", { attrs: { mode: "out-in", name: "fade" } }, [
        _vm.loading
          ? _c("div", { key: "loadingCase", staticClass: "transition-root" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("loading-indicator", [
                    _vm._v(_vm._s(_vm.$t("room.loading")) + ".."),
                  ]),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.starting
          ? _c("div", { key: "startingCase", staticClass: "transition-root" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("loading-indicator", [
                    _vm._v(_vm._s(_vm.$t("room.connecting")) + ".."),
                  ]),
                ],
                1
              ),
            ])
          : _vm._e(),
        !_vm.loading && !_vm.starting
          ? _c("div", { key: "notLoading", staticClass: "transition-root" }, [
              _vm.caseImageUrl
                ? _c("div", { staticClass: "case-image-container" }, [
                    _c("div", {
                      staticClass: "case-image",
                      style: _vm.caseImageStyle,
                    }),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "instruction-wrapper" },
                [
                  _c("NotchHeader", { attrs: { color: "blue" } }),
                  _vm.caseDescription
                    ? _c("vue-markdown", [_vm._v(_vm._s(_vm.caseDescription))])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "bottom-bar bottom-bar-lobby" },
                [
                  _c(
                    "counter",
                    {
                      staticClass: "student-counter",
                      attrs: {
                        current: _vm.studentCount,
                        "slot-location": "affix",
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.$t("room.areReady")) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "bottom-bar-text text-center" },
                    [
                      _c("app-button", { on: { onClick: _vm.onClickStart } }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.$t("misc.start")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "login-info" }, [
                    _c(
                      "div",
                      {
                        staticClass: "small-qr-code",
                        on: { click: _vm.openBigQrCode },
                      },
                      [
                        _vm.studentUrlSimple
                          ? _c("qr-code", {
                              attrs: { scale: 5, value: _vm.studentUrlSimple },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "room-info-url-emoji" },
                      [
                        _c("emoji-code", {
                          attrs: {
                            "active-emoji-index": -1,
                            "auto-width": true,
                            "show-triangle": false,
                            type: "tiny",
                          },
                        }),
                        _c("div", { staticClass: "student-url" }, [
                          _vm._v(_vm._s(_vm.studentUrlSimple)),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }