var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    !_vm.loaded
      ? _c("div", [
          _c("div", [
            _c("strong", [_vm._v("API base URL:")]),
            _vm._v(" " + _vm._s(_vm.apiBaseUrl)),
          ]),
          _c("div", [_vm._v(_vm._s(_vm.status))]),
        ])
      : _c("div", [
          _c("div", { staticClass: "row current-page-row" }, [
            _c("div", { staticClass: "input-group mb-2" }, [
              _vm._m(0),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.currentPageIndex,
                      expression: "currentPageIndex",
                    },
                  ],
                  staticClass: "form-control",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.currentPageIndex = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.currentCasePages, function (pageInfo, pageIndex) {
                  return _c("option", { domProps: { value: pageIndex } }, [
                    _vm._v(
                      "Page #" +
                        _vm._s(pageIndex) +
                        " - " +
                        _vm._s(_vm.getPageDescription(pageInfo)) +
                        "\n\t\t\t\t\t"
                    ),
                  ])
                }),
                0
              ),
              _c("div", { staticClass: "input-group-append" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-info",
                    class: _vm.canGoBack
                      ? "btn-info"
                      : "btn-info-outline disabled",
                    on: { click: _vm.pageGoBack },
                  },
                  [_vm._v("Previous page\n\t\t\t\t\t")]
                ),
              ]),
              _c("div", { staticClass: "input-group-append" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-info",
                    class: _vm.canGoForward
                      ? "btn-info"
                      : "btn-info-outline disabled",
                    on: { click: _vm.pageGoForward },
                  },
                  [_vm._v("Next page\n\t\t\t\t\t")]
                ),
              ]),
            ]),
          ]),
          _vm.currentPageIndex >= 0
            ? _c(
                "div",
                [
                  _c("page-controller", {
                    attrs: { redirectToLobby: false, useTransitions: false },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
    _c(
      "div",
      { staticClass: "page-debugger" },
      [
        _vm.currentCasePage
          ? _c("thing-to-html", {
              attrs: { button: false, thing: _vm.currentCasePage },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("div", { staticClass: "input-group-text" }, [_vm._v("Current Page")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }