<template>
    <div class="d-flex align-items-center">
        <i class="fas fa-language language-icon mr-2"></i>
        <select class="language-selector" v-bind:value="language" v-on:change="setLanguage">
            <option v-for="(key, value) in languages" v-bind:key="key" :value="key">
                {{ value }}
            </option>
        </select>
    </div>
</template>

<script>
import Language from '@/enum/Language'
import { mapGetters } from 'vuex'
import { SET_LANGUAGE } from '@/store/mutation-types'
import { i18n } from '@/localization'

export default {
    computed: {
        ...mapGetters({
            language: 'getLanguage',
        }),
        languages () {
            return Language
        },
    },
    methods: {
        setLanguage (event) {
            this.$store.commit(SET_LANGUAGE, event.target.value)
            i18n.locale = event.target.value
        },
    },
}
</script>

<style lang="less" scoped>
@import "~@less/font-sizes.less";

.language-title {
    text-align: left;
}

.language-icon {
    font-size: @font-size-xl;
}

.language-selector {
    width: 100%;
    padding: 5px 10px;
    font-size: @font-size-xs;
    font-weight: 500;
    outline: none;
    border: none;
    color: #333;

    &:focus {
        outline: 2px solid #333;
    }
}
</style>