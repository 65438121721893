export default [
	require('@img/emoji-codes/01.png'),
	require('@img/emoji-codes/02.png'),
	require('@img/emoji-codes/03.png'),
	require('@img/emoji-codes/04.png'),
	require('@img/emoji-codes/05.png'),
	require('@img/emoji-codes/06.png'),
	require('@img/emoji-codes/07.png'),
	require('@img/emoji-codes/08.png'),
	require('@img/emoji-codes/09.png'),
]
