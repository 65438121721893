<template>
	<div class="container" style="background: #001233; height: 100%;">
		<div>
			<input v-model.number="timeTotal" type="text"/>
		</div>
		<div>
			<input v-model.number="timeRemaining" :max="timeTotal" min="0" type="range"/>
		</div>
		<div>
			<input v-model.number="scale" type="text"/>
		</div>
		<!--<div>
		  Timer total: {{ timeTotal }}<br />
		  Timer remaining: {{ timeRemaining }}<br />
		</div>
		<<timer :time-remaining="timeRemaining" :time-total="timeTotal" :scale="scale" />-->
		<TextWireBorder :text="'ejrfererf er fjeij rfe er k'" :title="'Heyo!'"></TextWireBorder>
		<div>
			<TimerSolid></TimerSolid>
		</div>

		<choice :choiceType="'multipleAnswers'" :choices="choices" :selectable="true"/>
	</div>
</template>
<script>
import Timer from '@/components/timer'
import TimerSolid from '@/components/timer-solid'
import Choice from '@/components/choices'
import TextWireBorder from '@/components/page-components/text-wire-border'

export default {
	name: 'component-tester',
	components: {
		Timer,
		TimerSolid,
		Choice,
		TextWireBorder,
	},
	data() {
		return {
			timeTotal: 60,
			timeRemaining: 60,
			scale: 1,
			choices: [
				{
					text: 'I was late to an important meeting. On my way I passed a group of workers, and noticed a fault in a work routine.',
				},
				{
					text: 'I was early but I wanted food and so I was going to sierfjhseoirfghsi soeijrfise rgiserg seir gsie rgsheirgu aowe fapwe.',
				},
			],
		}
	},
}
</script>

<style lang="less">
</style>
