export default [
	require('@img/emoji-codes-highres/01.png'),
	require('@img/emoji-codes-highres/02.png'),
	require('@img/emoji-codes-highres/03.png'),
	require('@img/emoji-codes-highres/04.png'),
	require('@img/emoji-codes-highres/05.png'),
	require('@img/emoji-codes-highres/06.png'),
	require('@img/emoji-codes-highres/07.png'),
	require('@img/emoji-codes-highres/08.png'),
	require('@img/emoji-codes-highres/09.png'),
]
