var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "choices",
      class: [
        _vm.selectable ? "choices-selectable" : "",
        "choices-player-type-" + this.playerType,
      ],
    },
    [
      _vm.choiceType === "multipleChoice" ||
      _vm.choiceType === "multipleAnswers"
        ? _c(
            "div",
            { staticClass: "choice-wrapper", class: _vm.innerContainerClass },
            _vm._l(_vm.choicesOrdered, function (choice, i) {
              return _c(
                "div",
                {
                  key: i,
                  staticClass:
                    "choice-container choice-container-multiple-choice",
                  class: _vm.getChoiceClass(i),
                },
                [
                  _c("choice", {
                    staticClass: "choice",
                    class: "choice-" + i,
                    attrs: {
                      data: { globalScore: choice.choice.globalScore },
                      format: choice.format || "text",
                      index: choice.index,
                      message: choice.choice.message || choice.choice.text,
                      showResults: _vm.showResults,
                      toggled:
                        _vm.activeChoiceIndexes &&
                        _vm.activeChoiceIndexes.includes(choice.index),
                      voteCount: _vm.showResults ? choice.value : -1,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onChoiceClick(choice.index)
                      },
                    },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }