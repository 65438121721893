<template>
	<button class="breadcrumb-btn" @click="onClick">
		<img class="breadcrumb-icon" src="~@img/misc/back.png"/>
	</button>
</template>

<script>
import * as MutationTypes from '@/store/mutation-types'

export default {
	name: 'breadcrumb',
	props: {
		to: {
			required: true,
		},
	},
	methods: {
		onClick() {
			this.$emit('onClick')

			if (typeof (this.to) === 'function') {
				this.to.apply()
			} else {
				this.$store.commit(MutationTypes.SET_NAVIGATION_GUARD_ENABLED, false)
				this.$router.push(this.to)
			}
		},
	},
}
</script>
<style lang="less" scoped>
@import "~@less/variables.less";

.breadcrumb-btn {
	background-color: transparent;
	border: none;
}

.breadcrumb-icon {
	max-height: 100%;
}
</style>
