<template>
	<div class="login-page">
		<div class="login-container">
			<login @cancel="goBack"/>
		</div>
	</div>
</template>

<script>
import Login from '@/components/login'

export default {
	name: 'admin-login',
	components: {
		Login,
	},
	methods: {
		goBack() {
			this.$router.push('/')
		},
	},
}
</script>

<style lang="less" scoped>
.login-container {
	position: absolute;
	max-width: 600px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
</style>
