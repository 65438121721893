<template>
	<div class="container">
		<h1>Media tester</h1>

		<div class="form-group">
			<select v-model="test" class="form-control">
				<option value="">Select a test</option>
				<option value="youtube">youtube</option>
				<option value="vimeo">vimeo</option>
				<option value="image">image</option>
				<option value="emojiImageFull">emojiImageFull</option>
				<option value="emojiImage">emojiImage</option>
				<option value="emojiSingle">emojiSingle</option>
				<option value="emojiSingleNoExist">emojiSingleNoExist</option>
				<option value="emojiInText">emojiInText</option>
				<option value="emojiInTextNoExist">emojiInTextNoExist</option>
			</select>
		</div>

		<div class="well">
			<div v-if="test === 'youtube'">
				<h2>Youtube</h2>
				<media source="https://www.youtube.com/watch?v=fEvM-OUbaKs"/>
			</div>

			<div v-if="test === 'vimeo'">
				<h2>Vimeo</h2>
				<media source="https://vimeo.com/22439234"/>
			</div>

			<div v-if="test === 'image'">
				<h2>Image</h2>
				<media source="theme2/case3/mobilen-bipper-1.png"/>
			</div>

			<div v-if="test === 'emojiImageFull'">
				<h2>Emoji image (full)</h2>
				<media source="emojis/emoji-confused-240x240.png"/>
			</div>

			<div v-if="test === 'emojiImage'">
				<h2>Emoji image (Relative)</h2>
				<media source="emoji-poop-240x240.png"/>
			</div>

			<div v-if="test === 'emojiSingle'">
				<h2>Emoji single (exists)</h2>
				<media source="😍"/>
			</div>

			<div v-if="test === 'emojiSingleNoExist'">
				<h2>Emoji single (does not exist)</h2>
				<media source="🙌"/>
			</div>

			<div v-if="test === 'emojiInText'">
				<h2>Emoji in text (exists)</h2>
				<media source="This is some text with an emoji 😝"/>
			</div>

			<div v-if="test === 'emojiInTextNoExist'">
				<h2>Emoji in text (does not exist)</h2>
				<media source="This is some text with an emoji 🙌"/>
			</div>
		</div>
	</div>
</template>
<script>
import Media from '../media/index'

export default {
	name: 'media-tester',
	components: {
		Media,
	},
	data() {
		return {
			test: '',
		}
	},
}
</script>
