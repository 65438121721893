var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          on: {
            click: function () {
              _vm.$router.go(-1)
            },
          },
        },
        [_vm._v("Back")]
      ),
      _c("h1", [_vm._v("Theme View")]),
      _c("h3", { staticClass: "mb-2" }, [_vm._v("Theme")]),
      _c("form-row", { attrs: { label: "Title" } }, [
        _c("input", {
          staticClass: "form-control",
          attrs: { type: "text" },
          domProps: { value: _vm.title },
          on: { blur: _vm.onTitleFieldBlur },
        }),
      ]),
      _c("form-row", { attrs: { label: "Description" } }, [
        _c("input", {
          staticClass: "form-control",
          attrs: { type: "text" },
          domProps: { value: _vm.description },
          on: { blur: _vm.onDescriptionFieldBlur },
        }),
      ]),
      _c("image-picker", {
        model: {
          value: _vm.image,
          callback: function ($$v) {
            _vm.image = $$v
          },
          expression: "image",
        },
      }),
      _c("br"),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("h3", { staticClass: "mb-2" }, [_vm._v("Cases")]),
          _c("add-button", {
            staticClass: "ml-3",
            on: {
              click: function ($event) {
                return _vm.addCase()
              },
            },
          }),
        ],
        1
      ),
      _c("data-table", {
        scopedSlots: _vm._u([
          {
            key: "head",
            fn: function () {
              return [
                _c("tr", [
                  _c("th", [_vm._v("Title")]),
                  _c("th", [_vm._v("State")]),
                  _c("th", { staticClass: "w-25" }, [_vm._v("Actions")]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return _vm._l(_vm.cases, function (themeCase) {
                return _c("tr", { key: themeCase.id }, [
                  _c("td", [_vm._v(_vm._s(themeCase.title))]),
                  _c(
                    "td",
                    [
                      _c("case-state-selector", {
                        attrs: { state: themeCase.state },
                        on: {
                          change: function ($event) {
                            return _vm.onCaseStateChanged(themeCase.id, $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-info",
                          attrs: {
                            to: "/admin/case/" + themeCase.id + "/edit",
                          },
                        },
                        [_vm._v("Edit")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger",
                          on: {
                            click: function ($event) {
                              return _vm.deleteCase(themeCase.id)
                            },
                          },
                        },
                        [_vm._v("Delete")]
                      ),
                    ],
                    1
                  ),
                ])
              })
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }