<template>
    <div class="case-progress col mb-3">
        <div class="case-progress-point-line"></div>
        <div class="case-progress-point-wrapper row">
            <div v-for="n in bubbleCount" v-if="choiceCount" class="case-progress-point-container col">
                <div :class="n <= highlightIndex ? 'case-progress-point-active' : ''" class="case-progress-point">
                    <span v-if="n > highlightIndex">{{ n }}</span>
                    <span v-else>XXX</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'case-progress',
    computed: {
        ...mapGetters({
            pageIndex: 'getCurrentPageIndex',
            currentCase: 'getCurrentCase',
        }),
        bubbleCount () {
            if (this.pageIndex >= this.resultIndexes.min) {
                return this.resultCount
            }
            else {
                return this.choiceCount
            }
        },
        highlightIndex () {
            if (this.pageIndex >= this.choiceIndexes.min) {
                // Started choices

                if (this.pageIndex <= this.choiceIndexes.max) {
                    // Not finished with choices yet

                    return this.choiceIndex
                }
                else {
                    // Finished with choices, check if we're reached results yet

                    if (this.pageIndex >= this.resultIndexes.min) {
                        // Started results

                        if (this.pageIndex <= this.resultIndexes.max) {
                            // Not finished with results yet

                            return this.resultIndex
                        }
                        else {
                            // Finished with results

                            return 50
                        }
                    }
                    else {
                        // Not started with results yet

                        return 50
                    }
                }
            }

            return -1
        },
    },
    mounted () {
        this.recalculate()
    },
    data () {
        return {
            resultIndex: -1,
            resultCount: 0,
            resultIndexes: { min: null, max: null },

            choiceIndex: -1,
            choiceCount: 0,

            choiceIndexes: { min: null, max: null },
        }
    },
    watch: {
        currentCase: {
            deep: true,
            handler () {
                this.recalculate()
            },
        },
        pageIndex: {
            deep: true,
            handler () {
                this.recalculate()
            },
        },
    },
    methods: {
        recalculate () {
            let choiceIndex = -1
            let choiceCount = 0

            let resultIndex = -1
            let resultCount = 0

            let choiceIndexes = { min: null, max: null }
            let resultIndexes = { min: null, max: null }

            for (let i = 0, len = this.currentCase.pages.length; i < len; i++) {
                let page = this.currentCase.pages[i]

                if (!page.components) {
                    continue
                }

                let components = page.components

                let hasChoiceComponent = (components.left && components.left.component === 'Choices') ||
                    (components.right && components.right.component === 'Choices')
                let hasResultComponent = (components.left && components.left.component === 'Result') ||
                    (components.right && components.right.component === 'Result')

                if (hasChoiceComponent) {
                    choiceCount++

                    if (this.pageIndex >= i) {
                        choiceIndex++
                    }

                    if (choiceIndexes.min === null) {
                        choiceIndexes.min = i
                    }
                    else {
                        choiceIndexes.min = Math.min(choiceIndexes.min, i)
                    }

                    if (choiceIndexes.max === null) {
                        choiceIndexes.max = i
                    }
                    else {
                        choiceIndexes.max = Math.max(choiceIndexes.max, i)
                    }
                }
                else if (hasResultComponent) {
                    resultCount++

                    if (this.pageIndex >= i) {
                        resultIndex++
                    }

                    if (resultIndexes.min === null) {
                        resultIndexes.min = i
                    }
                    else {
                        resultIndexes.min = Math.min(resultIndexes.min, i)
                    }

                    if (resultIndexes.max === null) {
                        resultIndexes.max = i
                    }
                    else {
                        resultIndexes.max = Math.max(resultIndexes.max, i)
                    }
                }
            }

            this.choiceCount = choiceCount
            this.choiceIndex = choiceIndex

            this.resultCount = resultCount
            this.resultIndex = resultIndex

            this.choiceIndexes = choiceIndexes
            this.resultIndexes = resultIndexes

            console.log({
                pageIndex: this.pageIndex,

                choiceCount: this.choiceCount,
                choiceIndex: this.choiceIndex,
                choiceIndexes: this.choiceIndexes,

                resultCount: this.resultCount,
                resultIndex: this.resultIndex,
                resultIndexes: this.resultIndexes,
            })
        },
    },
}
</script>

<style lang="less" scoped>
.case-progress {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;

    .case-progress-filled {
        background: #002D74;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}
</style>