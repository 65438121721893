var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("form-row", { attrs: { label: "Image" } }, [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _vm.showUploader
              ? _c("image-uploader", {
                  staticClass: "mr-2",
                  on: { upload: _vm.onImageUploaded },
                })
              : _vm._e(),
            _vm.showImage && _vm.selectedImageUrl
              ? _c("img", {
                  staticClass: "mr-2",
                  attrs: { src: _vm.selectedImageUrl },
                })
              : _vm._e(),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.m_selectedImagePath,
                    expression: "m_selectedImagePath",
                  },
                ],
                staticClass: "form-control",
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.m_selectedImagePath = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.handleChangeEvent($event)
                    },
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "" } }, [
                  _vm._v("Select an image.."),
                ]),
                _vm._l(_vm.images, function (image) {
                  return _c(
                    "option",
                    { key: image.id, domProps: { value: image.path } },
                    [_vm._v(_vm._s(image.name))]
                  )
                }),
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }