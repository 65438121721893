var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("add-button", {
        on: {
          click: function ($event) {
            return _vm.openFileDialog()
          },
        },
      }),
      _c("input", {
        ref: "fileInput",
        staticClass: "d-none",
        attrs: { accept: ".png, .jpg, .jpeg, .gif", type: "file" },
        on: {
          change: function ($event) {
            return _vm.onFileSelected($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }