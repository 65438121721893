var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "table table-bordered table-striped" }, [
    _c("thead", { staticClass: "thead-dark" }, [_vm._t("head")], 2),
    _c("tbody", [_vm._t("body")], 2),
    _c("tfoot", [_vm._t("foot")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }