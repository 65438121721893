/**
 * @see https://github.com/Luka967/websocket-close-codes#websocket-close-codes
 */
const errorCodes = {
    1000: 'CLOSE_NORMAL',
    1001: 'CLOSE_GOING_AWAY',
    1002: 'CLOSE_PROTOCOL_ERROR',
    1003: 'CLOSE_UNSUPPORTED',
    // 1004 not used
    1005: 'CLOSE_NO_STATUS',
    1006: 'CLOSE_ABNORMAL',
    1007: 'UNSUPPORTED_PAYLOAD',
    1008: 'POLICY_VIOLATION',
    1009: 'CLOSE_TOO_LARGE',
    1010: 'MANDATORY_EXTENSION',
    1011: 'SERVER_ERROR',
    1012: 'SERVICE_RESTARTED',
    1013: 'TRY_AGAIN_LATER',
    1014: 'BAD_GATEWAY',
    1015: 'TLS_HANDSHAKE_FAILED',
}

class WebsocketCloseCodeUtil {
    static getErrorCodeString (errorCode) {
        if (errorCodes.hasOwnProperty(errorCode)) {
            return errorCodes[errorCode]
        }

        return null
    }

    static getErrorCode (errorCodeString) {
        for (let errorCode in errorCodes) {
            let _errorCodeString = errorCodes[errorCode]

            if (_errorCodeString === errorCodeString) {
                return errorCode
            }
        }
    }
}

export { errorCodes, WebsocketCloseCodeUtil }
