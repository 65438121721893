var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.options && !_vm.gotoPageDisabled
      ? _c("div", [
          _vm.showGoToPage
            ? _c(
                "div",
                [
                  _c("form-row", { attrs: { label: "Goto Page" } }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.options.gotoPageIndex,
                            expression: "options.gotoPageIndex",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          blur: function ($event) {
                            return _vm.sendUpdate()
                          },
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.options,
                              "gotoPageIndex",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("(auto)"),
                        ]),
                        _vm._l(_vm.possibleGotoIndices, function (n) {
                          return _c(
                            "option",
                            { key: n, domProps: { value: n } },
                            [_vm._v("#" + _vm._s(n + 1))]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _c("div", { staticClass: "card text-white bg-dark my-3" }, [
      _c("div", { staticClass: "card-header row mx-0 p-2" }, [
        _c("div", { staticClass: "col-8" }, [
          _vm._v(
            "\n                    " +
              _vm._s(_vm.position) +
              " Component\n                "
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "card-body py-1" },
        [
          _c("form-row", { attrs: { label: "Type" } }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.componentIndex,
                    expression: "componentIndex",
                  },
                ],
                staticClass: "form-control",
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.componentIndex = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              _vm._l(_vm.componentTypes, function (type, n) {
                return _c("option", { key: type, domProps: { value: n } }, [
                  _vm._v(_vm._s(type)),
                ])
              }),
              0
            ),
          ]),
          _c("div", {
            staticClass: "my-3",
            staticStyle: { "border-bottom": "2px dashed white" },
          }),
          _vm.options
            ? _c(
                "div",
                [
                  _vm.component !== "BackgroundImage"
                    ? _c(
                        "form-row",
                        { attrs: { label: "Text" } },
                        [
                          _c("markdown-editor", {
                            key: _vm.markdownKey,
                            attrs: {
                              toolbar: _vm.getMarkdownToolbarForComponent(
                                _vm.component
                              ),
                            },
                            on: {
                              input: function ($event) {
                                return _vm.sendUpdate()
                              },
                            },
                            model: {
                              value: _vm.options.text,
                              callback: function ($$v) {
                                _vm.$set(_vm.options, "text", $$v)
                              },
                              expression: "options.text",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.component === "Media"
                    ? _c(
                        "div",
                        [
                          _c("form-row", { attrs: { label: "Media" } }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedMediaType,
                                    expression: "selectedMediaType",
                                  },
                                ],
                                staticClass: "form-control",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.selectedMediaType = $event.target
                                      .multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "video" } }, [
                                  _vm._v("Video Link"),
                                ]),
                                _c("option", { attrs: { value: "image" } }, [
                                  _vm._v("Image"),
                                ]),
                              ]
                            ),
                          ]),
                          _vm.selectedMediaType === "video"
                            ? _c(
                                "div",
                                [
                                  _c("form-row", { attrs: { label: "Link" } }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.options.url,
                                          expression: "options.url",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: {
                                        "is-invalid": !_vm.isMediaLinkValid,
                                      },
                                      attrs: { type: "text" },
                                      domProps: { value: _vm.options.url },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.sendUpdate()
                                        },
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.options,
                                            "url",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  _c("image-picker", {
                                    attrs: {
                                      showImage: true,
                                      showUploader: false,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.sendUpdate()
                                      },
                                    },
                                    model: {
                                      value: _vm.options.url,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.options, "url", $$v)
                                      },
                                      expression: "options.url",
                                    },
                                  }),
                                ],
                                1
                              ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.component === "BackgroundImage"
                    ? _c(
                        "div",
                        [
                          _c("image-picker", {
                            attrs: { showImage: true, showUploader: false },
                            on: {
                              change: function ($event) {
                                return _vm.sendUpdate()
                              },
                            },
                            model: {
                              value: _vm.options.url,
                              callback: function ($$v) {
                                _vm.$set(_vm.options, "url", $$v)
                              },
                              expression: "options.url",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm.component === "Choices"
                    ? _c(
                        "div",
                        [
                          _c(
                            "form-row",
                            {
                              attrs: {
                                label:
                                  "Time for users to put input moments/dilemmas",
                              },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.options.momentDilemmaTime,
                                    expression: "options.momentDilemmaTime",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: {
                                  value: _vm.options.momentDilemmaTime,
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.sendUpdate()
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.options,
                                      "momentDilemmaTime",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                          _c(
                            "form-row",
                            {
                              attrs: {
                                label:
                                  "Time for users to vote on the moments/dilemmas",
                              },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.options.momentDilemmaVoteTime,
                                    expression: "options.momentDilemmaVoteTime",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: {
                                  value: _vm.options.momentDilemmaVoteTime,
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.sendUpdate()
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.options,
                                      "momentDilemmaVoteTime",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                          _c(
                            "form-row",
                            {
                              attrs: {
                                label:
                                  "Time for users to put their tokens on the weight map",
                              },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.options.weightMapTokenTime,
                                    expression: "options.weightMapTokenTime",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: {
                                  value: _vm.options.weightMapTokenTime,
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.sendUpdate()
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.options,
                                      "weightMapTokenTime",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                          _c("form-row", { attrs: { label: "Result Type" } }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.options.resultType,
                                    expression: "options.resultType",
                                  },
                                ],
                                staticClass: "form-control",
                                on: {
                                  blur: function ($event) {
                                    return _vm.sendUpdate()
                                  },
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.options,
                                      "resultType",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(_vm.resultTypes, function (type) {
                                return _c(
                                  "option",
                                  { key: type, domProps: { value: type } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getResultTypeDescription(type)
                                      ) + "\n                                "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                          _c("form-row", { attrs: { label: "Choice Type" } }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.options.choiceType,
                                    expression: "options.choiceType",
                                  },
                                ],
                                staticClass: "form-control",
                                on: {
                                  blur: function ($event) {
                                    return _vm.sendUpdate()
                                  },
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.options,
                                      "choiceType",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(_vm.choiceTypes, function (type) {
                                return _c(
                                  "option",
                                  { key: type, domProps: { value: type } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getChoiceTypeDescription(type)
                                      ) + "\n                                "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                          _c("form-row", { attrs: { label: "List Type" } }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.options.listType,
                                    expression: "options.listType",
                                  },
                                ],
                                staticClass: "form-control",
                                on: {
                                  blur: function ($event) {
                                    return _vm.sendUpdate()
                                  },
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.options,
                                      "listType",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(_vm.listTypes, function (type) {
                                return _c(
                                  "option",
                                  { key: type, domProps: { value: type } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.getListTypeDescription(type)) +
                                        "\n                                "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                          _c("form-row", { attrs: { label: "Moment type" } }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.options.momentType,
                                    expression: "options.momentType",
                                  },
                                ],
                                staticClass: "form-control",
                                on: {
                                  blur: function ($event) {
                                    return _vm.sendUpdate()
                                  },
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.options,
                                      "momentType",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(_vm.momentTypes, function (type) {
                                return _c(
                                  "option",
                                  { key: type, domProps: { value: type } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getMomentTypeDescription(type)
                                      ) + "\n                                "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                          _c(
                            "form-row",
                            { attrs: { label: "Moments hidden initially" } },
                            [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.options.momentsHiddenInitially,
                                      expression:
                                        "options.momentsHiddenInitially",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  on: {
                                    blur: function ($event) {
                                      return _vm.sendUpdate()
                                    },
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.options,
                                        "momentsHiddenInitially",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                _vm._l(_vm.yesNo, function (value) {
                                  return _c(
                                    "option",
                                    { key: value, domProps: { value: value } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getYesNoTypeDescription(value)
                                        ) + "\n                                "
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          ),
                          _c("form-row", { attrs: { label: "Token type" } }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.options.tokenType,
                                    expression: "options.tokenType",
                                  },
                                ],
                                staticClass: "form-control",
                                on: {
                                  blur: function ($event) {
                                    return _vm.sendUpdate()
                                  },
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.options,
                                      "tokenType",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(_vm.tokenTypes, function (value) {
                                return _c(
                                  "option",
                                  { key: value, domProps: { value: value } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getTokenTypeDescription(value)
                                      ) + "\n                                "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                          _vm.options.resultType === "correctAnswer"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "form-row",
                                    { attrs: { label: "Correct Index" } },
                                    [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.options.correctIndex,
                                              expression:
                                                "options.correctIndex",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          on: {
                                            blur: function ($event) {
                                              return _vm.sendUpdate()
                                            },
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.options,
                                                "correctIndex",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        _vm._l(
                                          _vm.options.choices.length,
                                          function (n) {
                                            return _c(
                                              "option",
                                              {
                                                key: n,
                                                domProps: { value: n - 1 },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(n) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._l(_vm.options.choices, function (choice, i) {
                            return _c(
                              "div",
                              {
                                key: i,
                                staticClass: "card text-white bg-dark my-3",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "card-header row mx-0 p-2" },
                                  [
                                    _c("div", { staticClass: "col-8" }, [
                                      _vm._v(
                                        "\n                                    Choice " +
                                          _vm._s(i + 1) +
                                          "\n                                "
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col d-flex justify-content-end",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-sm btn-danger",
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteChoice(i)
                                              },
                                            },
                                          },
                                          [_vm._v("Delete")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                !_vm.gotoPageDisabled
                                  ? _c(
                                      "div",
                                      { staticClass: "card-body py-1" },
                                      [
                                        _vm.options.resultType ===
                                          "chooseAnswer" ||
                                        (_vm.options.resultType ===
                                          "correctAnswer" &&
                                          _vm.options.correctIndex === i)
                                          ? _c(
                                              "form-row",
                                              { attrs: { label: "Goto Page" } },
                                              [
                                                _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          choice.gotoPageIndex,
                                                        expression:
                                                          "choice.gotoPageIndex",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    on: {
                                                      blur: function ($event) {
                                                        return _vm.sendUpdate()
                                                      },
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$selectedVal =
                                                          Array.prototype.filter
                                                            .call(
                                                              $event.target
                                                                .options,
                                                              function (o) {
                                                                return o.selected
                                                              }
                                                            )
                                                            .map(function (o) {
                                                              var val =
                                                                "_value" in o
                                                                  ? o._value
                                                                  : o.value
                                                              return val
                                                            })
                                                        _vm.$set(
                                                          choice,
                                                          "gotoPageIndex",
                                                          $event.target.multiple
                                                            ? $$selectedVal
                                                            : $$selectedVal[0]
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        domProps: {
                                                          value: null,
                                                        },
                                                      },
                                                      [_vm._v("(auto)")]
                                                    ),
                                                    _vm._l(
                                                      _vm.possibleGotoIndices,
                                                      function (n) {
                                                        return _c(
                                                          "option",
                                                          {
                                                            key: n,
                                                            domProps: {
                                                              value: n,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            #" +
                                                                _vm._s(n + 1) +
                                                                "\n                                        "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "form-row",
                                          { attrs: { label: "Format" } },
                                          [
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: choice.format,
                                                    expression: "choice.format",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.sendUpdate()
                                                  },
                                                  change: function ($event) {
                                                    var $$selectedVal =
                                                      Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function (o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function (o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                    _vm.$set(
                                                      choice,
                                                      "format",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  },
                                                },
                                              },
                                              _vm._l(
                                                _vm.formats,
                                                function (type) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      key: type,
                                                      domProps: { value: type },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getChoiceFormatDescription(
                                                            type
                                                          )
                                                        ) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "form-row",
                                          { attrs: { label: "Global Score" } },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: choice.globalScore,
                                                  expression:
                                                    "choice.globalScore",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: { type: "number" },
                                              domProps: {
                                                value: choice.globalScore,
                                              },
                                              on: {
                                                blur: function ($event) {
                                                  return _vm.sendUpdate()
                                                },
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    choice,
                                                    "globalScore",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "form-row",
                                          { attrs: { label: "Text" } },
                                          [
                                            choice.format === "markdown"
                                              ? _c("markdown-editor", {
                                                  key: _vm.markdownKey,
                                                  attrs: {
                                                    toolbar:
                                                      _vm.getMarkdownToolbarForComponent(
                                                        _vm.component
                                                      ),
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.sendUpdate()
                                                    },
                                                  },
                                                  model: {
                                                    value: choice.text,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        choice,
                                                        "text",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "choice.text",
                                                  },
                                                })
                                              : _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: choice.text,
                                                      expression: "choice.text",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  domProps: {
                                                    value: choice.text,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        choice,
                                                        "text",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "d-flex justify-content-center my-3",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-success btn-sm",
                                  attrs: { disabled: !_vm.canAddNewChoices },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addChoice()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "Add New Choice\n                            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._l(_vm.choiceStages, function (stage, j) {
                            return _c(
                              "div",
                              [
                                _vm._l(
                                  _vm.options.instructions[stage].instructions,
                                  function (instruction, i) {
                                    return _c(
                                      "div",
                                      {
                                        key: "" + i + "," + stage,
                                        staticClass: "card text-white my-3",
                                        class:
                                          _vm.getInstructionTypeClass(stage),
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "card-header row mx-0 p-2",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-8" },
                                              [
                                                _vm._v(
                                                  "\n                                        Instruction " +
                                                    _vm._s(
                                                      _vm.getInstructionTypeDescription(
                                                        stage
                                                      )
                                                    ) +
                                                    " " +
                                                    _vm._s(i + 1) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col d-flex justify-content-end",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-sm btn-danger",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteInstruction(
                                                          i,
                                                          stage
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                            Delete\n                                        "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "card-body py-1" },
                                          [
                                            _c(
                                              "form-row",
                                              { attrs: { label: "Text" } },
                                              [
                                                _c("markdown-editor", {
                                                  key:
                                                    _vm.markdownKey +
                                                    "instruction" +
                                                    i,
                                                  attrs: { value: instruction },
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.sendInstructionUpdate(
                                                        i,
                                                        stage,
                                                        $event
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-center my-3",
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-success btn-sm",
                                        on: {
                                          click: function ($event) {
                                            return _vm.addInstruction(stage)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "Add New Instruction " +
                                            _vm._s(
                                              _vm.getInstructionTypeDescription(
                                                stage
                                              )
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              2
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }