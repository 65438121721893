<template>
    <div class="loading-indicator">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: 'loading-indicator',
}
</script>
<style lang="less" scoped>
@import "~@less/font-sizes.less";

.loading-indicator {
    font-weight: 900;
    font-size: @app-font-size-loading-indicator;
    text-align: center;

    width: 100%;

    .spinner-container {
        margin: 50px auto 30px auto;
    }
}
</style>
