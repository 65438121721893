<template>
	<div class="game-info card text-center">
		<div class="card-header">
			<ul class="nav nav-pills card-header-pills nav-fill">
				<button
					:class="(activeSection === 'room' ? 'active': '')"
					class="nav-item btn nav-link"
					v-on:click="activeSection = 'room'"
				>Room
					<div v-if="roomId" class="room-id badge badge-dark">{{ roomId }}</div>
				</button>
				<button
					:class="(activeSection === 'players' ? 'active': '')"
					class="nav-item btn nav-link"
					v-on:click="activeSection = 'players'"
				>
					Players
					<div class="player-id badge badge-dark"><i class="fas fa-user"></i> {{ playerCount }}</div>
				</button>
				<button
					:class="(activeSection === 'log' ? 'active': '')"
					class="nav-item btn nav-link"
					v-on:click="activeSection = 'log'"
				>
					Log
				</button>
			</ul>
		</div>
		<div v-if="activeSection === 'room'">
			<ThingToHtml
				:button="false"
				:thing="roomState"
				class="room-state-thing"
				theme="dark"
			></ThingToHtml>
		</div>
		<div v-else-if="activeSection === 'players'">
			<player-slots v-on:editPlayer="editPlayer"></player-slots>
		</div>
		<div v-else-if="activeSection === 'log'">
			<h2>Log
				<button class="btn btn-danger" type="button" v-on:click="clearLog">Clear log</button>
			</h2>
			<div class="container">
				<div v-for="logMessage in logMessages" class="log-item row">
					<log-item
						v-bind:message="logMessage"
					></log-item>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ThingToHtml from '@/lib/ThingToHtmlVue/index'
import PlayerSlots from './player-slots'
import clone from 'clone'
import LogItem from './log-item'

export default {
	components: {
		ThingToHtml,
		PlayerSlots,
		'log-item': LogItem,
	},
	data() {
		return {
			activeSection: 'room',
		}
	},
	computed: {
		playerCount() {
			try {
				return Object.keys(this.$gameClient.room.state.players).length
			} catch (error) {
				return 0
			}
		},
		logMessages() {
			try {
				return this.$gameClient.logMessages
			} catch (error) {
				return {}
			}
		},
		roomState() {
			try {
				let roomState = clone(this.$gameClient.room.state)

				delete roomState.players

				return roomState
			} catch (error) {
				return []
			}
		},
		roomId() {
			try {
				return this.$gameClient.room.state.customId
			} catch (error) {
				return null
			}
		},
	},
	methods: {
		editPlayer(player) {
			this.$emit('editPlayer', player)
		},
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.player-slots {
	.nav-pills {
		.nav-item {
			a.disabled {
				color: #888888 !important;
			}
		}
	}
}
</style>
